<template>
  <main class="page__content">
    <TimelinePageRenderer
      :data="data"
      :timeline="timeline"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import TimelinePageRenderer from '../components/blocks/TimelinePageRenderer';

export default {
  components: {
    TimelinePageRenderer,
  },
  head: {
    // documentation: https://www.npmjs.com/package/vue-head
    title: {
      inner: 'History'
    },
    meta: function() {
      return [
        { name: 'title', content: this.metaTitle, id: 'title' },
        { name: 'description', content: this.metaDescription, id: 'description' },
        { name: 'keywords', content: this.metaKeywords, id: 'keywords' },

        { property: 'og:description', content: this.metaDescription, id: 'description' },
        { property: 'og:image', content: this.metaShareImage, id: 'og:image' },
        { property: 'og:title', content: this.metaTitle, id: 'og:title' },
        { property: 'twitter:card', content: 'summary_large_image', id: 'twitter:card' },
      ];
    },
    link: function() {
      return [{
        rel: 'canonical',
        href: this.caronical,
        id: 'canonical'
      }];
    }
  },
  computed: {
    ...mapGetters({
      data: 'getData',
      timeline: 'getTimeline',
    }),
    metaShareImage () {
      return this.data.image && this.data.image.url ? this.data.image.url : '';
    },
    metaTitle() {
      if (this.data) {
        return this.data.meta_title;
      }
      return '';
    },
    metaDescription() {
      if (this.data) {
        return this.data.meta_description;
      }
      return '';
    },
    metaKeywords() {
      if (this.data) {
        return this.data.meta_keywords || '';
      }
      return '';
    },
    caronical() {
      return '';
    }
  },
  created() {
    this.loadPage();
  },
  beforeRouteUpdate (to, from, next) {
    this.loadPage();
    next();
  },
  methods: {
    async loadPage() {
      await this.$store.dispatch('queryTimeline', { vm: this });
      this.$emit('updateHead');
    }
  }
};
</script>

<style lang="scss" scoped>
.page__content {
  .timeline-main {
  }
}
</style>
