<template>
  <div
    class="story-banner"
    :class="{'story-banner--large': largeTitle}"
    v-observe-visibility="{
         callback: visibilityChanged,
         once: true,
       }"
  >
    <div class="story-banner__image-wrap" ref="imageWrap">
      <div class="story-banner__image" ref="image" :style="imageBackground" v-parallax="0.1"></div>
    </div>
    <div class="story-banner__overlay" v-if="overlay"></div>
    <div class="story-banner__inner">
      <div class="story-banner__wrap-right">
        <aside :class="getCategoryClassName">{{ category }}</aside>
        <h2 
          class="story-banner__title u-f-montserrat-black" 
          :class="{'story-banner__title--large': largeTitle}"
          ref="title"
        >{{ title }}</h2>
        <p class="story-banner__text u-f-opensans-regular" ref="text">{{ description }}</p>
        <TheButton 
          :to="link" 
          :target="target"
          label="read more" 
          class="story-banner__link" 
          :animationDelay="1000" 
          :color="accent_color"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '../../elements/buttons/TheButton';

export default {
  props: ['title', 'title_type', 'description', 'cta_link', 'image', 'category', 'accent_color', 'overlay'],
  components: {
    TheButton,
  },
  computed: {
    imageBackground() {
      return `background-image:url('${this.image}');`;
    },
    link() {
      if (this.cta_link && this.cta_link.uid) {
        if (this.cta_link.type && this.cta_link.type === 'news_article') {
          return `/news/${this.cta_link.uid}`;
        } else {
          return `/${this.cta_link.uid}`;
        }
      }
      if (this.cta_link && this.cta_link.link_type === 'Web') {
        return this.cta_link.url
      }
      return '';
    },
    target() {
      if (this.cta_link.target) return this.cta_link.target
      return ''
    },
    getCategoryClassName() {
      let className = `story-banner__category u-f-opensans-regular ${ this.accent_color ? `story-banner__category--${this.accent_color}` : '' }`;
      return className;
    },
    largeTitle() {
      return this.title_type === 'heading1'
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.imageWrap,
        opacity: [0, 1],
        scale: [1, 1.05],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });

      // {"translateY":["-20px",0],"opacity":1}
      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss">
.story-banner {
  $root: &;
  position: relative;
  background: darken($c_regent_gray, 50%);
  overflow: hidden;

  &--large {
    #{$root}__inner {
      padding-top: 204px;
      padding-bottom: 146px;
      
      @include createQuery($sr_w_sm) {
        padding-top: 215px;
        padding-bottom: 46px;
      }
    }

    #{$root}__wrap-right {
      @include createQuery($sr_w_sm) {
        padding-left: 30px;
      }
    }
  }

  &__image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0; // set in js
  }

  &__image {
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: 0;
    width: 100%;
    /*height: 100%;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__overlay {
    @include overlay;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    padding: 145px 0 114px;

    @include createQuery($sr_w_md) {
      @include inner;
      max-width: 584px !important;
      margin: 0 20px;
      margin-left: max(calc((100vw - 900px)/2), 20px);
      padding-top: 73px;
      padding-bottom: 67px;
    }

    @include createQuery($sr_w_xs) {
      padding-top: 168px;
      padding-bottom: 40px;
    }
  }

  &__wrap-right {
    position: relative;
    padding-left: 64px;
    max-width: 41.66%;

    @include createQuery($sr_w_md) {
      max-width: 85.38%;
      max-width: 584px;
      padding-left: 16.66%;
      padding-right: 42px;
    }

    @include createQuery($sr_w_xs) {
      max-width: 100%;
      padding-right: 56px;
      padding-left: 36px;
    }
  }

  &__category {
    position: absolute;
    left: 0;
    top: 6px;
    height: 200px;
    font-size: 12px;
    letter-spacing: 3.75px;
    text-align: right;
    text-transform: uppercase;
    text-orientation: mixed;
    writing-mode: tb;
    transform: rotate(-180deg);
    color: $c_sail;

    &--green {
      color: $c_accent_green;
    }
    &--red {
      color: $c_remax_red;
    }
    &--blue {
      color: $c_remax_blue;
    }
    &--navy {
      color: $c_navy;
    }

    @include createQuery($sr_w_md) {
      left: 7.21%;
    }

    @include createQuery($sr_w_xs) {
      height: 150px;
      left: 0;
      font-size: 8px;
      letter-spacing: 2.5px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 26px;
    font-size: 25px;
    font-weight: 900;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_white;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      font-size: 18px;
    }

    &--large {
      font-size: 42px;

      @include createQuery($sr_w_sm) {
        font-size: 18px;
      }
    }
  }

  &__text {
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 1.5;

    opacity: 0; // set in js
    @include defaultCopyStyles;
    color: $c_white;
  }
}
</style>
