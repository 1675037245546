<template>
  <div
    class="carousel"
  >
    <div class="carousel__content">
      <div
        v-for="(card, index) in cards"
        :key="index"
        class="carousel__item"
        :class="{
          'first-child': index === activeItem - 1,
          'second-child': index === activeItem % total,
          'third-child': index === (activeItem + 1) % total
        }"
      >
        <div class="carousel__meta u-f-montserrat-thin">
          <span class="current">{{ pad(activeItem, 2) }}</span>/{{ pad(total, 2) }}
        </div>
        <img :src="card.image" class="carousel__image"/>
        <h3 class="carousel__title u-f-montserrat-black">{{ card.title }}</h3>
        <div class="carousel__text u-f-opensans-regular">
          <prismic-rich-text
            :field="card.text"
          />
        </div>
      </div>
      <!-- <carouselArrow class="carousel__next" @click="onNextCard"/> -->
      <CarouselArrow class="carousel__next" @click="onNextCard" />
      <div class="carousel__dots">
        <span
          v-for="(card, index) in cards"
          :key="index"
          class="carousel__dots-item"
          :class="{active: activeItem === index + 1}"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselArrow from '@/assets/svg/carousel-arrow.svg';

export default {
  props: ['cards'],
  components: {
    CarouselArrow
  },
  data () {
    return {
      current: 1,
      modifiedCards: [...this.cards]
    };
  },
  computed: {
    total () {
      return this.cards.length;
    },
    activeItem () {
      return this.current;
    },
    visibleCards () {
      return this.modifiedCards;
    }
  },
  methods: {
    visibilityChanged (isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.image,
        opacity: [0, 1],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });
    },
    onNextCard () {
      const _cards = [...this.modifiedCards];
      const first = _cards.shift();
      _cards.push(first);
      this.modifiedCards = _cards;

      if (this.current == this.total) {
        this.current = 1;
      } else {
        this.current++;
      }
    },
    pad (num, size) {
      let s = num + '';
      while (s.length < size) s = '0' + s;
      return s;
    },
    recalcHeights() {
      let items = document.querySelectorAll('.carousel__item');
      let heights = [...items].map(el => el.getBoundingClientRect().height);
      let max = Math.max(...heights);
      items.forEach(el => el.style.minHeight = max + 'px');
    }
  },

  mounted() {
    this.recalcHeights();
    window.addEventListener('resize', this.recalcHeights);
  }
};
</script>

<style lang="scss">
.carousel {
  $root: &;
  position: relative;
  margin-top: 0;
  margin-bottom: 54px;
  padding-right: 48px;
  background: $c_white;
  // overflow: hidden;
  width: 100%;

  @include createQuery($sr_w_xs) {
    margin-bottom: 0;
  }

  &__content {
    position: relative;
    text-align: center;
    z-index: $z_index_min;
  }

  &__meta {
    position: absolute;
    font-size: 40px;
    top: 29px;
    right: 32px;

    @include createQuery($sr_w_xs) {
      font-size: 28px;
      top: 17px;
      right: 15px;
    }

    .current {
      font-weight: 400;
    }
  }

  &__next {
    position: absolute;
    z-index: 104;
    top: calc(50% - 48px);
    right: -40px;
    cursor: pointer;

    circle {
      filter: drop-shadow( 0 2px 1px rgba(0, 0, 0, 0.2) );
    }

    @include createQuery($sr_w_xs) {
      width: 50px;
      height: 50px;
      top: 45%;
      right: -27px;
      transform: translateY(-50%);
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;

    @include createQuery($sr_w_xs) {
      margin-top: 45px;
    }

    #{$root}__dots-item {
      display: block;
      margin: 4px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      border: 1px solid $c_cobalt;
      background-color: $c_white;

      &.active {
        background-color: $c_cobalt;
      }
    }
  }

  &__item {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 63px 100px;
    border: 1px solid $c_silver_sand;
    background-color: $c_white;
    transition: all .5s;
    z-index: 101;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include createQuery($sr_w_sm) {
      min-height: 370px;
    }

    opacity: 0;

    @include createQuery($sr_w_xs) {
      padding: 63px 18px 23px;
    }

    &.first-child {
      position: relative;
      margin-right: 48px;
      z-index: 103;
      opacity: 1;

      @include createQuery($sr_w_xs) {
        margin-right: 30px;
      }
    }

    &.second-child {
      margin-left: 24px;
      margin-right: 24px;
      top: 24px;
      z-index: 102;
      opacity: 1;

      @include createQuery($sr_w_xs) {
        margin-left: 15px;
        margin-right: 15px;
        top: 15px;
      }
    }

    &.third-child {
      margin-left: 48px;
      margin-right: 0;
      top: 48px;
      z-index: 101;
      opacity: 1;

      @include createQuery($sr_w_xs) {
        margin-left: 30px;
        top: 30px;
      }
    }

    #{$root}__image {
      object-fit: contain;
      width: 90px;
      margin-bottom: 18px;

      @include createQuery($sr_w_xs) {
        width: 70px;
      }
    }

    #{$root}__title {
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: 0.75px;

      @include createQuery($sr_w_xs) {
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.58px;
      }
    }

    #{$root}__text {
      font-size: 16px;
      line-height: 2.06;
      margin-top: 36px;

      a {
        color: inherit;
      }

      p {
        margin: 0;
      }

      ul {
        list-style: none;
      }

      li {
        text-align: left;

        &::before {
          content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: $c_remax_blue; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1em; /* Also needed for space (tweak if needed) */
          margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
      }

      @include createQuery($sr_w_sm) {
        font-size: 13px;
        line-height: 1.85;
        margin-top: 18px;
      }
    }
  }
}
</style>
