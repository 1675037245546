<template>
  <div
    class="load-more"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="load-more__inner">
      <div class="load-more__lines"></div>
      <div class="load-more__actions">
        <TheButton
          label="load more"
          :disabled="!hasMore"
          @click="onLoadMore"
          color="blue"
          className="load-more__btn"
          :animationDelay="300"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '../../elements/buttons/TheButton';
export default {
  props: ['hasMore'],
  components: {
    TheButton
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.category,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 900,
        duration: 600,
      });
    },
    onLoadMore() {
      this.$emit('loadmore');
    }
  },
};
</script>

<style lang="scss" scoped>
.load-more {
  margin-top: 37px;
  margin-bottom: 109px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-bottom: 20px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;
    flex-direction: column;
  }

  &__lines {
    // margin-left: -100px;
    // margin-right: -100px;
    width: 100%;
    border-bottom: solid 1.5px $c_light_blue;
    border-left: solid 1.5px $c_light_blue;
    height: 37px;
    margin-top: 14px;
    margin-bottom: 44px;

    @include createQuery($sr_w_md) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    @include createQuery($sr_w_xs) {
      margin-bottom: 20px;
    }
  }

  &__actions {
    width: 100%;
    text-align: center;
    margin-bottom: 18px;

    @include createQuery($sr_w_xs) {
      margin-bottom: 70px;
    }
  }
}
</style>
