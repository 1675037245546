import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PrismicVue from '@prismicio/vue';
import linkResolver from './prismic/link-resolver';
import htmlSerializer from './prismic/html-serializer';
import VueHead from 'vue-head';
import VueObserveVisibility from 'vue-observe-visibility'
import SmoothScrollbar from 'vue-smooth-scrollbar';
import VueAnime from 'vue-animejs';
import vueVimeoPlayer from 'vue-vimeo-player'
import VueCarousel from 'vue-carousel';
import PortalVue from 'portal-vue';
import './directives/parallax';
import './directives/mask';
import VueDisqus from 'vue-disqus';

Vue.config.productionTip = false;

Vue.use(PrismicVue, {
    endpoint: 'https://remaxholdings.cdn.prismic.io/api/v2',
    linkResolver,
    htmlSerializer
});

Vue.use(VueHead, {
    separator: '|',
    complement: 'RE/MAX HOLDINGS'
});

Vue.use(SmoothScrollbar);

Vue.use(PortalVue);

Vue.use(VueAnime);

Vue.use(VueDisqus);

Vue.use(VueCarousel);

Vue.use(VueObserveVisibility);

Vue.use(vueVimeoPlayer);

// load generic scss
import './scss/init.scss';
import './scss/utilities.scss';

var vm = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

window.RMXContext = vm;

export default vm;