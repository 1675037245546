<template>
  <main class="page__content">
    <NewsPageRenderer
      :data="data"
      :page="page"
      :slices="slices"
      @loadmore="onLoadMore"
      @categoryChanged="onCategoryChange"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import NewsPageRenderer from '@/components/blocks/NewsPageRenderer';

export default {
  components: {
    NewsPageRenderer,
  },
  head: {
    // documentation: https://www.npmjs.com/package/vue-head
    title: {
      inner: 'News'
    },
    meta: function() {
      return [
        { name: 'title', content: this.metaTitle, id: 'title' },
        { name: 'description', content: this.metaDescription, id: 'description' },
        { name: 'keywords', content: this.metaKeywords, id: 'keywords' },

        { property: 'og:description', content: this.metaDescription, id: 'description' },
        { property: 'og:title', content: this.metaTitle, id: 'og:title' },
      ];
    },
    link: function() {
      return [{
        rel: 'canonical',
        href: this.caronical,
        id: 'canonical'
      }];
    }
  },
  data () {
    return {
      id: null,
      page: 1,
      totalPages: null,
    }
  },
  computed: {
    ...mapGetters({
      data: 'getPageData',
      slices: 'getSlices',
    }),
    metaTitle() {
      if (this.data) {
        return this.data.meta_title;
      }
      return '';
    },
    metaDescription() {
      if (this.data) {
        return this.data.meta_description;
      }
      return '';
    },
    metaKeywords() {
      if (this.data) {
        return this.data.meta_keywords || '';
      }
      return '';
    },
    caronical() {
      return '';
    },
  },
  created() {
    this.loadPage();
  },
  methods: {
    async loadArticles() {
      await this.$store.dispatch('queryNewsArticles', { vm: this, pageSize: 500, page: 1, excludeDefaults: true });
    },

    async loadPage() {
      await this.$store.dispatch('queryNewsPage', { vm: this });
      await this.$store.dispatch('queryNewsCategories', { vm: this });
      await this.$store.dispatch('queryPromotedNewsArticles', { vm: this });
      this.loadArticles();
      this.$emit('updateHead');
    },

    async onLoadMore () {
      this.page++;
    },

    onCategoryChange() {
      this.page = 1;
    }
  }
};
</script>
