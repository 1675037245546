<template>
  <div
    class="the-header"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="the-header__inner">
      <span class="the-header__sub-title u-f-opensans-bold" ref="sub_title">
        {{ category }}
      </span>
      <h1 class="the-header__title u-f-montserrat-black" ref="title">
        {{ heading }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: ['category', 'heading'],
  
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.sub_title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 900,
        duration: 600,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.the-header {
  position: relative;
  margin-top: 80px;
  margin-bottom: 30px;

  @include createQuery($sr_w_sm) {
    margin-top: 52px;
    margin-bottom: 20px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    flex-direction: column;
    @include inner;
  }

  &__sub-title {
    margin: 0;
    font-size: 16px;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: $c_remax_blue;

    @include createQuery($sr_w_xs) {
      font-size: 12px;
      letter-spacing: 3.75px;
    }
  }

  &__title {
    margin: 0;
    font-size: 42px;
    line-height: 42px;
    text-transform: uppercase;
    color: $c_ship_gray;

    @include createQuery($sr_w_sm) {
      font-size: 31px;
      line-height: normal;
    }
  }

}
</style>
