<template>
    <div class="container">
        <div class="inner">
            <div class="cards">
                <div class="card" v-for="(card, index) in items" :key="index">
                    <div class="card__header u-f-montserrat-black">
                        <h4 class="card__headline" v-if="card.headline">{{ card.headline }}</h4>
                        <h4 class="card__subhead" v-if="card.subhead">{{ card.subhead }}</h4>
                    </div>
                    <div class="card__body u-f-montserrat-semibold">
                        <div v-if="card.copy && card.copy.length" v-html="$prismic.asHtml(card.copy)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['module'],

    computed: {
        items() {
            if (this.module.items) {
                return this.module.items
            }
            return []
        },


    }
}
</script>

<style lang="scss" scoped>
.container {
    margin: 50px 0;
}

.inner {
    @include maxWidth;
    padding-left: 200px;
    padding-right: 200px;
}

.cards {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include createQuery($sr_w_sm) {
        flex-direction: column;
        align-items: center;
    }
}

.card {
    min-width: 200px;
    max-width: 250px;
    border: 2px solid $c_light_blue;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;

    &:not(:nth-child(3n)) {
        margin-right: 24px;

        @include createQuery($sr_w_sm) {
            margin-right: 0;
            margin-bottom: 24px;
        }
    }

    &__header {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        color: $c_navy;
        
    }

    &__headline {
        font-size: 32px;
        font-weight: 900;
        line-height: 32px;
    }
    &__subhead {
        font-weight: 900;
    }

    &__body {
        flex: 1;
        background: rgba($c_light_blue, 0.5);
        padding: 24px 24px 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>