<template>
  <div
    class="article-slide"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-slide__image-wrap" ref="imageWrap">
      <div class="article-slide__image" ref="image" :style="imageBackground"></div>
    </div>
    <div class="article-slide__overlay"></div>
    <div class="article-slide__wrap">
      <h2 class="article-slide__title u-f-montserrat-black" ref="title">{{ title }}</h2>
      <hr class="article-slide__divider" ref="divider" />
      <div class="article-slide__action" ref="action">
        <span class="article-slide__photos"><slideshow class="slide_icon" />{{ count }} photos</span>
        <span class="article-slide__separator">|</span>
        <span class="article-slide__viewslide" @click="onClickView">view slideshow <i>></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import slideshow from '@/assets/svg/icon-slideshow.svg';
export default {
  props: ['title', 'count', 'image'],
  components: {
    slideshow
  },
  computed: {
    imageBackground() {
      return `background-image:url('${this.image}');`;
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.imageWrap,
        opacity: [0, 1],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.action,
        opacity: [0, 1],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.divider,
        opacity: [0, 1],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });
    },
    onClickView() {
      this.$emit('view');
    }
  }
};
</script>

<style lang="scss" scoped>
.article-slide {
  $root: &;
  position: relative;
  width: 100%;
  height: 450px;
  display: flex;
  align-items: flex-end;
  background: darken($c_regent_gray, 50%);
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    height: 62.81vw;
  }

  &__image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__overlay {
    @include overlay;
    background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0.9) 100%);
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    padding: 104px 200px 83px;

    @include createQuery($sr_w_md) {
      padding: 73px 100px 67px;
    }

    @include createQuery($sr_w_xs) {
      padding: 168px 32px 40px 36px;
    }
  }

  &__wrap {
    position: relative;
    width: 100%;
    padding: 0 42px;

    @include createQuery($sr_w_xs) {
      padding: 0 17px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.33;
    letter-spacing: 0.75px;
    text-transform: capitalize;
    color: $c_white;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.58px;
    }
  }

  &__divider {
    border-width: 1px 0 0 0;
    border-color: $c_white;

    opacity: 0; // set in js
  }

  &__action {
    margin-top: 18px;
    margin-bottom: 29px;
    display: flex;
    font-size: 8px;
    text-transform: uppercase;
    line-height: 2.06;
    color: $c_white;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      margin-top: 13px;
      margin-bottom: 18px;
    }

    #{$root}__photos {
      display: flex;
      align-items: center;
      padding-right: 7px;
      cursor: pointer;
      letter-spacing: 1.7px;

      @include createQuery($sr_w_xs) {
        font-size: 8px;
        /*letter-spacing: 1.7px;*/
      }

      .slide_icon {
        margin-right: 6px;
      }
    }

    #{$root}__viewslide {
      padding-left: 7px;
      cursor: pointer;
      letter-spacing: 1.7px;

      i {
        color: $c_sail;
        font-style: normal;
      }

      @include createQuery($sr_w_xs) {
        font-size: 8px;
        /*letter-spacing: 1.7px;*/
      }
    }
  }
}
</style>
