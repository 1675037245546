<template>
  <article class="story story--split-cta" :class="className" ref="story">
    <div class="story__inner">
      <div class="story__figures">
        <StoryImage
          key="img1"
          :image="image"
          className="story__figure--primary"
          :intersection="getImageIntersection"
          :animateShadow="true"
        />
      </div>
      <div class="story__header">
        <h2 class="story__title u-f-montserrat-black">{{ title }}</h2>
        <TheButton 
          className="btn--navy" 
          :label="ctaText" 
          :to="ctaLink"  
        />
        <div class="split-cta__line"></div>
      </div>
    </div>
  </article>
</template>
 
<script>
import TheButton from '../../elements/buttons/TheButton.vue';
import StoryImage from '../../elements/story/StoryImage.vue';
export default {
  name: 'Story',
  props: [
    'className',
    'image',
    'title',
    'ctaText',
    'ctaLink',
  ],
  computed:{
    getImageIntersection () {
      return {
        root: this.$refs.story,
        rootMargin: '300px',
        threshold: 0.3
      }
    },
  },
  components: {
    StoryImage,
    TheButton,
  },
};
</script>

<style lang="scss">
.story {
  $root: &;

  &--split-cta {
    padding: 85px 0 120px;

    @include createQuery($sr_w_md) {
      padding-bottom: 64px;
    }

    #{$root}__inner {
      @include maxWidth;
      @include createQuery($sr_w_not_sm) {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;

        & > :first-child {
          flex: 2;
        }
        & > :last-child {
          flex: 1;
        }
      }
      @include createQuery($sr_w_not_md) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    #{$root}__figure {
      &--primary {
        width: 100%;
        // max-width: 684px;
        margin-bottom: 32px;

        #{$root}__figure-content {
          padding-bottom: 56.25%;
        }
      }

      #{$root}__image-shadow {
        background-color: $c_navy !important;
      }
    }

    #{$root}__title {
      max-width: 250px;
    }
  }

  .story__header {
    position: relative;
  }
  .split-cta__line {
    position: absolute;
    width: 2px;
    height: 400px;
    background: $c_light_blue;
    top: 100%;
    left: 95px;
    z-index: -1;

    @include createQuery($sr_w_sm) {
      height: 100px;
    }

    @include createQuery($sr_w_xs) {
      left: 76px;
    }
  }
}
</style>

<style lang="scss" scoped>
</style>