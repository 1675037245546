import Vue from 'vue'

const parallaxListener = {

  id: 0,
  els: [],
  isBound: false,

  bindEvents () {
    if (this.isBound) return;
    window.addEventListener('remax_scroll', this.handleMovement);
    window.addEventListener('remax_loading_done', this.handleMovement);
  },

  unbindEvents () {
    window.removeEventListener('remax_scroll', this.handleMovement);
    window.removeEventListener('remax_loading_done', this.handleMovement);
    parallaxListener.isBound = false;
  },

  handleMovement () {
    if (parallaxListener.els.length === 0) return;

    const windowY = window.innerHeight;
    parallaxListener.els.map(thisEl => {

      const bindingVal = thisEl.binding.value.value ? thisEl.binding.value.value : thisEl.binding.value;
      const parallaxWatchParent = thisEl.el.closest('.js-parallax-watch-parent');

      if (thisEl.binding.modifiers.watchParent && !parallaxWatchParent && !thisEl.el.parentNode) return;

      const targetEl = thisEl.binding.modifiers.watchParent
      && (thisEl.el.parentNode || parallaxWatchParent) ?
        (parallaxWatchParent ? parallaxWatchParent : thisEl.el.parentNode)
        : thisEl.el; // rect calculated based on other el
      const rect = targetEl.getBoundingClientRect();
      let ratioFactor = 2;
      // let ratioFactor = windowY / rect.height;
      // let ratioFactor = thisEl.binding.modifiers.onTop ? 1 : windowY / rect.height;
      // if (ratioFactor > 2) ratioFactor = 2;
      const visibilityFactor = ((rect.y + (rect.height / ratioFactor)) / windowY) * 100;

      let movementFactor = 0;
      if (visibilityFactor <= 100 && visibilityFactor > 0) {
        movementFactor = (visibilityFactor - 50) * 2;
      } else if (visibilityFactor > 100) {
        movementFactor = 100;
      } else if (visibilityFactor < 0) {
        movementFactor = -100;
      }

      if (thisEl.binding.modifiers.onTop) movementFactor = (visibilityFactor - 50) * 2;

      let movement = (bindingVal * movementFactor * -5);
      if (thisEl.binding.modifiers && thisEl.binding.modifiers.lim) {
        movement = ((bindingVal[1] - bindingVal[0]) * (visibilityFactor / 100)) + bindingVal[0];
      }

      if (thisEl.binding.modifiers.debug) console.warn(thisEl.binding, movementFactor, visibilityFactor, movement, ratioFactor);

      // maintain scale if exists in transform values
      let addToTransform = '';
      const scaleMatches = thisEl.el.style.transform.match(/(scale\(.*?\))/);
      if (scaleMatches && scaleMatches[0]) addToTransform += scaleMatches[0];

      if (thisEl.binding.value.xy) {
        thisEl.el.style.transform = `translate3d(${(movement).toFixed(3)}px,${(movement).toFixed(3)}px,0px) ${addToTransform}`;
      } else {
        thisEl.el.style.transform = `translate3d(0px,${(movement).toFixed(3)}px,0px) ${addToTransform}`;
      }

    });
  },

  bindEl (el, binding) {
    parallaxListener.bindEvents();
    parallaxListener.els.push({
      el,
      id: parallaxListener.id++,
      binding
    });
    parallaxListener.handleMovement();
  },

  updatedEl (el, binding) {
    parallaxListener.els.find(thisEl => thisEl.el === el).binding = binding;
    parallaxListener.handleMovement();
  },

  unbindEl (el) {
    parallaxListener.els.filter(thisEl => thisEl.el !== el);
    if (parallaxListener.els.length === 0) this.unbindEvents();
  },

};

Vue.directive('parallax', {
  // inserted
  bind: parallaxListener.bindEl,
  unbind: parallaxListener.unbindEl,
  // update
  componentUpdated: parallaxListener.updatedEl,
});
