<template>
  <component :is="getType"
             :target="target"
             :class="classNames"
             @click="onClick"
             v-bind="getLinkParams">
    <template v-if="label">{{ label }}<template v-if="external"><Icon class="icon" /></template></template>
    <slot></slot>
  </component>
</template>

<script>
import Icon from '@/assets/svg/icon-ext-link.svg';
export default {
  props: ['label', 'to', 'target', 'className', 'unstyled', 'type', 'disabled', 'external'],
  components: {
    Icon,
  },
  computed: {
    classNames () {
      return `${this.unstyled ? '' : 'link u-f-montserrat-bold '}${this.className}`;
    },
    getLinkParams () {
      if (this.isPrismicLink) {
        return {
          field: this.to
        }
      }
      if (this.type && ['submit', 'button'].includes(this.type)) {
        return {
          disabled: this.disabled,
          type: this.type,
        }
      }
      return this.getType === 'a' ? {
        href: this.to,
      } : {
        to: this.to,
      };
    },
    isPrismicLink () {
      return this.to && typeof this.to === 'object'
    },
    getType () {
      if (this.type) {
        if (['submit', 'button'].includes(this.type)) {
          return 'button';
        }
        return this.type;
      }
      if (this.to) {
        // prismic field
        if (this.isPrismicLink) return 'prismic-link';
        // if email, <a>
        if (this.to.startsWith('mailto:')) return 'a';
        // if abs URL, use <a> tag, else router-link
        const isAbsURLRegExp = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (isAbsURLRegExp.test(this.to)) return 'a';
        else return 'router-link';
      }
      return 'button';
    }
  },
  methods: {
    onClick () {
      this.$emit('click');
    },

  }
}
</script>

<style lang="scss">
.link {
  $root: &;
  font-size: 12px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: $c_ship_gray;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;

  &:hover {
    color: $c_regent_gray;
    transition: all .2s;

    svg path {
      fill: $c_regent_gray;
      transition: all .2s;
    }
  }

  &--no-text-transform {
    text-transform: none;
  }

  &--light {
    color: $c_medium_gray;
  }

  &--red {
    color: $c_alizarin_crimson;

    &:hover {
      color: $c_coral_red;
    }
  }

  &--blue {
    color: $c_cobalt;

    &:hover {
      color: $c_blue_ribbon;
    }
  }

  &--green {
    color: $c_accent_green;

    &:hover {
      color: $c_accent_green_light;
    }
  }

  &--learn-more {
    position: relative;
    text-transform: uppercase !important;

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: -5px;
      height: 2px;
      width: 100%;
      background: $c_accent_green;

      @include createQuery($sr_w_sm) {
        width: calc(100% - 6px);
      }
    }
  }

  &--loadable {
    position: relative;
    overflow: hidden;
  }

  &--topic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 8.5px;
    padding-bottom: 7.5px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 11px;
    letter-spacing: 0.5px;
    color: $c_white;
    background-color: $c_cobalt;
    text-decoration: none;

    &:hover {
      color: transparentize($c_white, .2);
    }

    &:not(#{$root}--animating)[disabled] {
      color: transparentize($c_white, .5);
    }
  }
}
</style>

<style lang="scss" scoped>
  .icon {
    height: 0.7em;
    width: 0.7em;
    margin-left: 5px;
  }
</style>
