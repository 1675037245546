import Vue from 'vue';

export function setLoading(state, val) {
    Vue.set(state, 'loading', val);
}

export function data(state, data) {
    Vue.set(state, 'data', data);
  }

export function setPageData(state, data) {
    Vue.set(state, 'pageData', data);
}

export function setPageTitle(state, pageTitle) {
    Vue.set(state, 'pageTitle', pageTitle);
}

export function setSlices(state, data) {
    Vue.set(state, 'slices', data);
}

export function setBios(state, data) {
    Vue.set(state, 'bios', data);
}

export function setBio(state, data) {
    Vue.set(state, 'bio', data);
}

export function setArticle(state, data) {
    Vue.set(state, 'article', data);
}

export function articlesPromoted(state, results) {
    Vue.set(state, 'promoted', results);
}

export function articles(
    state,
    { results, page, total_pages, next_page }
) {
    if (page === 1) {
      Vue.set(state, 'articles', {
        results,
        total: total_pages,
        hasMore: next_page ? true : false,
      });
    } else {
      const updatedResults = [...state.articles.results, ...results];
      Vue.set(state, 'articles', {
        results: updatedResults,
        total: total_pages,
        hasMore: next_page ? true : false,
      });
    }
}

export function setCommentCount (state, num) {
    Vue.set(state, 'commentCount', num);
}

export function setCategories(state, results) {
    Vue.set(state, 'categories', results);
}

export function setSettings(state, data) {
    Vue.set(state, 'settings', data);
}

export function setHeaderMenu(state, data) {
    Vue.set(state, 'headerMenu', data);
}

export function timelines(state, results) {
    Vue.set(state, 'timeline', results);
}

export function articlesBySearch(
    state,
    { results, page, total_pages, next_page, keyword }
) {
    if (page === 1) {
        Vue.set(state, 'searchArticles', {
            results,
            total: total_pages,
            hasMore: next_page ? true : false,
        });
    } else {
        const updatedResults = [...state.searchArticles.results, ...results];
        Vue.set(state, 'searchArticles', {
            results: updatedResults,
            total: total_pages,
            hasMore: next_page ? true : false,
        });
    }
    Vue.set(state, 'keyword', keyword);
}

export function setMainContent(state, el) {
    Vue.set(state, 'mainContent', el);
}

// Window
export function onResize(state) {
    Vue.set(state, 'windowWidth', window.innerWidth);
}