
<template>
  <div
    class="profile-small"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <TheLink :unstyled="true" :to="theLink" className="profile-small__link-wrap">
      <div class="profile-small__wrapper">
        <div class="profile-small__photo">
          <ProfilePhoto :image="photoUrl" />
        </div>
        <div class="profile-small__content" ref="profile">
          <h1 class="profile-small__name u-f-montserrat-black">{{ name }}</h1>
          <p class="profile-small__position u-f-opensans-regular">{{ position }}</p>
        </div>
      </div>
      <TheLink :to="theLink" label="Read Bio" class="profile-small__read-btn" />
    </TheLink>
    <ProfileShare
      :facebook="facebook"
      :twitter="twitter"
      :instagram="instagram"
      :linkedin="linkedin"
    />
  </div>
</template>

<script>
import ProfilePhoto from './ProfilePhoto';
import ProfileShare from './ProfileShareSmall';
import TheLink from '../buttons/TheLink';
export default {
  props: ['profile'],
  components: {
    ProfilePhoto,
    ProfileShare,
    TheLink
  },
  computed: {
    photoUrl() {
      if (this.profile && this.profile.profile_image) {
        return this.profile.profile_image.url;
      }
      return null;
    },
    theLink() {
      if (this.profile) {
        return `/bio/${this.profile.uid}`;
      }
      return null;
    },
    name() {
      if (this.profile && this.profile.name) {
        return this.profile.name[0].text;
      }
      return '';
    },
    position() {
      if (this.profile) {
        return this.profile.job_title;
      }
      return '';
    },
    facebook() {
      if (this.profile && this.profile.facebook_link) {
        return this.profile.facebook_link.url;
      }
      return null;
    },
    twitter() {
      if (this.profile && this.profile.twitter_link) {
        return this.profile.twitter_link.url;
      }
      return null;
    },
    instagram() {
      if (this.profile && this.profile.instagram_link) {
        return this.profile.instagram_link.url;
      }
      return null;
    },
    linkedin() {
      if (this.profile && this.profile.linkedin_link) {
        return this.profile.linkedin_link.url;
      }
      return null;
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.profile,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 900,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-small {
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-bottom: 20px;
  }

  &__content {
    opacity: 0;

    @include createQuery($sr_w_xs) {
      padding-top: 13px;
      padding-left: 23px;
    }
  }

  &__link-wrap {
    -webkit-appearance: none;
    text-decoration: none;
    text-align: left;
    background: transparent;
    border: 0;
    outline: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include createQuery($sr_w_xs) {
      flex-direction: row;
    }
  }

  &__photo {
    max-width: 284px;
    flex: 1;
    padding-top: 8px;

    @include createQuery($sr_w_sm) {
      min-width: 121px;
      max-width: 121px;
    }
  }

  &__read-btn {
    display: block;
    position: relative;
    margin-bottom: 15px;
    padding: 0;
    text-decoration: none;
    color: $c_cobalt;

    @include createQuery($sr_w_xs) {
      margin-top: 18px;
    }
  }

  &__name {
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.75px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 14px;
      line-height: 1.29;
    }
  }

  &__position {
    margin: 0;
    min-height: 74px;
    font-size: 16px;
    line-height: 1.38;
    color: $c_regent_gray;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
    }
  }

  .profile__social {
    @include createQuery($sr_w_xs) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
