<template>
  <div class="instagram">
    <h2 class="instagram__title u-f-montserrat-black">
      #REMAX
    </h2>
    <div class="instagram__feeds">
      <Instagram v-for="(post, index) in posts" :key="index" :post="post" />
    </div>
  </div>
</template>

<script>
import Instagram from '../elements/story/Instagram';
export default {
  props: ['posts'],
  components: {
    Instagram
  }
}
</script>

<style lang="scss" scoped>
.instagram {
  margin-bottom: 14px;
  padding-top: 55px;

  @include createQuery($sr_w_xs) {
    margin-bottom: 12px;
    padding-top: 25px;
  }

  @include hideInPrint;

  &__title {
    margin-bottom: 27px;
    font-size: 25px;
    text-align: center;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      margin-bottom: 15px;
      font-size: 18px;
    }
  }

  &__feeds {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  &__feed {
    flex: 1;
    height: 288px;
  }
}
</style>
