<template>
  <portal to="modal">
    <div class="popup" ref="popup">
      <slot></slot>
      <closeIcon class="popup__close" @click="onCloseModal" />
    </div>
  </portal>
</template>

<script>
import closeIcon from '@/assets/svg/icon-x.svg';
export default {
  props: ['top'],
  components: {
    closeIcon
  },
  mounted() {
    this.$nextTick().then(() => {
      this.playAnimation();
    });
  },
  methods: {
    onCloseModal() {
      this.$emit('close');
    },
    playAnimation() {
      this
        .$anime({
          targets: this.$refs.popup,
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          delay: 0,
          duration: 450,
        });
    }
  },
};
</script>

<style lang="scss">
.popup {
  position: fixed;
  top: 0; // stripe height
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px;
  background-color: rgba(0,0,0,0.85);
  z-index: $z_index_overlay;

  opacity: 0;

  &__close {
    position: absolute;
    right: 20px;
    color: $c_white;
    cursor: pointer;
  }
}
</style>
