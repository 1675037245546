<template>
  <portal to="modal">
    <div class="slide-popup" ref="slideshow">
      <div class="slide-popup__inner">
        <div class="slide-popup__header">
          <span class="slide-popup__header-back u-f-opensans-regular" @click="onClickBack">
            <arrowLeft />
            BACK TO ARTICLE
          </span>
        </div>
        <div class="slide-popup__content">
          <div class="slide-popup__left" ref="image_container">
            <h2 class="slide-popup__title mobile u-f-montserrat-black" ref="title">
              {{ title }}
            </h2>
            <h3 class="slide-popup__page mobile u-f-montserrat-thin" ref="page">
              <span class="current">{{ pad(currentImage, 2) }}</span>/{{ pad(totalImages, 2) }}
            </h3>
            <StoryImage className="slide-popup__figure" :image="image" />
            <div class="slide-popup__control">
              <iconArrowRight @click="onClickRight" />
              <iconArrowLeft @click="onClickLeft" />
            </div>
          </div>
          <div class="slide-popup__right" ref="content">
            <h2 class="slide-popup__title u-f-montserrat-black" :key="`title_${currentImage}`" ref="title">
              {{ title }}
            </h2>
            <h3 class="slide-popup__page u-f-montserrat-thin" ref="page">
              <span class="current" :key="`page_${currentImage}`" ref="page_num">{{ pad(currentImage, 2) }}</span>/{{ pad(totalImages, 2) }}
            </h3>
            <div class="slide-popup__lines">
              <div class="slide-popup__lines-horizontal"></div>
              <div class="slide-popup__lines-vertical"></div>
            </div>
            <p class="slide-popup__description u-f-opensans-light" :key="`text_${currentImage}`" ref="description">
              {{ description }}
            </p>
            <div class="slide-popup__share">
              <TheLink
                className="slide-popup__share-btn"
                label="Share"
                @click="onClickSocialShare"
              />
            </div>
          </div>
        </div>
        <div class="slide-popup__footer">
          <div class="slide-popup__left">
            <div class="slide-popup__view-all u-f-opensans-regular" @click="onClickViewAll">
              <iconViewAll />
              <span class="slide-popup__view-all-label">View All</span>
            </div>
            <div class="slide-popup__thumbslider">
              <figure
                v-for="(thumb, index) in photos"
                :key="index"
                class="slide-popup__thumb-figure"
                :class="{ active: index === currentImage - 1 }"
                @click="onClickThumbnail(index)"
              >
                <img
                  :src="thumb.image"
                  class="slide-popup__thumb-image"
                  ref="image"
                />
                <div class="slide-popup__thumb-inactive">
                </div>
              </figure>
            </div>
          </div>
          <div class="slide-popup__right">
            <SlideShare />
          </div>
        </div>
      </div>
    </div>
    <ThumbnailOverlay
      v-if="isShowThumbOverlay"
      :photos="photos"
      :title="title"
      @close="onCloseThumbnail"
    />
    <SocialPopup
      v-if="isShowSocialPopup"
      :title="title"
      @close="onCloseSocialPopup"
    />
  </portal>
</template>

<script>
import arrowLeft from '@/assets/svg/arrow-left-small.svg';
import iconArrowLeft from '@/assets/svg/icon-arrow-left.svg';
import iconArrowRight from '@/assets/svg/icon-arrow-right.svg';
import iconViewAll from '@/assets/svg/icon-view-all.svg';
import SlideShare from './SlideShare';
import StoryImage from '../story/StoryImage';
import ThumbnailOverlay from './ThumbnailOverlay';
import SocialPopup from './SocialPopup';
import TheLink from '../buttons/TheLink';

export default {
  props: ['photos', 'title', 'top'],
  components: {
    arrowLeft,
    iconArrowLeft,
    iconArrowRight,
    iconViewAll,
    SlideShare,
    StoryImage,
    ThumbnailOverlay,
    SocialPopup,
    TheLink
  },
  data() {
    return {
      current: 1,
      showThumbOverlay: false,
      showSocialPopup: false
    };
  },
  computed: {
    currentImage() {
      return this.current;
    },
    totalImages() {
      return this.photos.length;
    },
    image() {
      const currentPhoto = this.photos[this.current - 1];
      return currentPhoto.image;
    },
    description() {
      const currentPhoto = this.photos[this.current - 1];
      return currentPhoto.caption;
    },
    isShowThumbOverlay() {
      return this.showThumbOverlay;
    },
    isShowSocialPopup() {
      return this.showSocialPopup;
    }
  },
  watch: {
    current: function () {
      this.$nextTick().then(() => {
        this.animateSlideChange();
      });
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.playAnimation();
    });
  },
  methods: {
    onClickBack() {
      // document.getElementById('header').classList.remove('modal-open');
      this.$emit('close');
    },
    onClickViewAll() {
      this.showThumbOverlay = true;
    },
    onClickRight() {
      this.current = this.current % this.totalImages + 1;
    },
    onClickLeft() {
      if (this.current - 1 === 0) {
        this.current = this.totalImages;
      } else {
        this.current = this.current - 1;
      }
    },
    onClickThumbnail(index) {
      this.current = index + 1;
    },
    onCloseThumbnail() {
      this.showThumbOverlay = false;
    },
    onClickSocialShare() {
      this.showSocialPopup = true;
    },
    onCloseSocialPopup() {
      this.showSocialPopup = false;
    },
    playAnimation() {
      this
        .$anime({
          targets: this.$refs.slideshow,
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          delay: 0,
          duration: 450,
        });

      this
        .$anime({
          targets: this.$refs.image_container,
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          delay: 200,
          duration: 600,
        });

      this
        .$anime({
          targets: this.$refs.page,
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          delay: 200,
          duration: 450,
        });

      this.animateSlideChange();
    },
    animateSlideChange() {
      this
        .$anime({
          targets: this.$refs.title,
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          delay: 0,
          duration: 450,
        });

      this
        .$anime({
          targets: this.$refs.page_num,
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          delay: 0,
          duration: 100,
        });

      this
        .$anime({
          targets: this.$refs.description,
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          delay: 0,
          duration: 450,
        });
    },
    pad(num, size) {
      let s = num + '';
      while (s.length < size) s = '0' + s;
      return s;
    }
  },
};
</script>

<style lang="scss">
.slide-popup {
  $root: &;
  position: fixed;
  top: 0; // stripe height
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 65px 0 90px;
  background-color: $c_ship_gray;
  z-index: $z_index_overlay;

  opacity: 0;

  @include createQuery($sr_w_xs) {
    padding: 0;
  }

  &__inner {
    @include maxWidth;
    flex-direction: column;
    width: 100%;
  }

  &__header {
    margin-bottom: 19px;
  }

  &__header-back {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 2.13px;
    color: $c_white;
    cursor: pointer;
  }

  &__left {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    max-width: 900px;

    @include createQuery($sr_w_xs) {
      flex-direction: column;
    }

    #{$root}__control {
      position: absolute;
      right: -24px;
      top: 50%;
      display: flex;
      flex-direction: column;
      z-index: $z_index_top;

      @include createQuery($sr_w_xs) {
        right: 0;
        top: initial;
        bottom: -117px;
      }

      svg {
        display: block;
        margin-bottom: 8px;
        cursor: pointer;

        @include createQuery($sr_w_xs) {
          margin-bottom: 0px;
        }
      }
    }

    #{$root}__view-all {
      display: flex;
      align-items: center;
      width: 100px;
      color: $c_white;
      cursor: pointer;

      #{$root}__view-all-label {
        margin-left: 8px;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 2.13px;
        color: $c_white;
      }
    }

    #{$root}__thumbslider {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      overflow-x: auto;

      @include createQuery($sr_w_xs) {
        justify-content: flex-start;
        flex-wrap: nowrap;
      }

      #{$root}__thumb-figure {
        position: relative;
        margin: 0 2px 0 0;
        width: 67px;
        height: 39px;
        cursor: pointer;

        @include createQuery($sr_w_xs) {
          flex: 0 0 auto;
        }

        &.active {
          #{$root}__thumb-inactive {
           background-color: transparent;
          }
        }

        &:hover {
          #{$root}__thumb-inactive {
           background-color: transparent;
          }
        }

        #{$root}__thumb-image {
          display: block;
          @include overlay;
          object-fit: cover;
        }

        #{$root}__thumb-inactive {
          @include overlay;
          background-color: rgba(0,0,0,0.3);
        }
      }
    }
  }

  &__right {
    flex: 1;
    max-width: 300px;
    padding-left: 58px;

    @include createQuery($sr_w_xs) {
      padding: 0;
    }
  }

  &__figure {
    position: relative;
    margin: 0;
    width: 100%;

    .story__figure-content {
      padding-bottom: 57.47%;

      .story__image-shadow {
        top: 0 !important;
        left: 0 !important;
      }
    }
  }

  &__title {
    margin-top: 36px;
    margin-bottom: 39px;
    font-size: 25px;
    line-height: 1.24;
    letter-spacing: 0.78px;
    text-transform: capitalize;
    color: $c_white;

    opacity: 0;

    &.mobile {
      display: none;

      @include createQuery($sr_w_xs) {
        display: block;
        margin-top: 25px;
        margin-bottom: 1px;
        font-size: 18px;
        line-height: 1.33;
        letter-spacing: 0.75px;
        opacity: 1;
      }
    }

    @include createQuery($sr_w_xs) {
      display: none;
    }
  }

  &__page {
    margin-bottom: 39px;
    font-size: 40px;
    line-height: 0.78;
    letter-spacing: 1.25px;
    font-weight: 200;
    color: $c_white;

    opacity: 0;

    &.mobile {
      display: none;

      @include createQuery($sr_w_xs) {
        display: block;
        margin: 0 0 18px;
        font-size: 28px;
        line-height: 1.11;
        letter-spacing: 0.88px;
        opacity: 1;
      }
    }

    @include createQuery($sr_w_xs) {
      display: none;
    }

    .current {
      font-weight: normal;

      opacity: 0;

      @include createQuery($sr_w_xs) {
        opacity: 1;
      }
    }
  }

  &__description {
    margin-top: 9px;
    font-size: 16px;
    color: $c_white;

    opacity: 0;

    @include createQuery($sr_w_xs) {
      max-height: 85px;
      height: 85px;
      margin-top: 22px;
      font-size: 13px;
      line-height: 1.38;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  &__image {
    display: block;
    @include overlay;
    object-fit: cover;
  }

  &__content {
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    @include createQuery($sr_w_xs) {
      flex-direction: column;
      border-bottom: 1px solid $c_white;
    }

    #{$root}__left {
      opacity: 0;
    }

    #{$root}__right {
      z-index: $z_index_min;

      #{$root}__lines {
        display: block;
        width: 200px;
        height: 43px;
        margin-left: -143px;
        border-width: 2px 2px 0 0;
        border-style: solid;
        border-color: $c_alizarin_crimson;

        @include createQuery($sr_w_xs) {
          display: none;
        }
      }

      #{$root}__share {
        display: none;

        @include createQuery($sr_w_xs) {
          display: block;
          margin-bottom: 24px;

          #{$root}__share-btn {
            font-size: 10px;
            font-weight: 500;
            padding: 0;
            color: $c_sail;
          }
        }
      }
        
      @include createQuery($sr_w_xs) {
        max-width: 74.82%;
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;

    #{$root}__left {
      @include createQuery($sr_w_xs) {
        width: 100%;
        flex-direction: row;
      }
    }

    #{$root}__right {
      @include createQuery($sr_w_xs) {
        display: none;
      }
    }
  }
}
</style>
