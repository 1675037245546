<template>
  <div>
    <template v-if="!isLoading">
      <template v-if="data">
        <TheHeader />
      </template>
      <template v-if="data">
        <TheFilter :current="current" :filters="filters" @filter="onChangeFilter" />
      </template>
      <template v-if="profiles">
        <ProfileList :profiles="profiles" :filter="current" />
      </template> 
    </template>
  </div>
</template>

<script> 
// import _ from 'lodash';
import { mapGetters } from 'vuex';
import ProfileList from './Bio/ProfileList';
import TheHeader from './Bio/TheHeader';
import TheFilter from './common/TheFilter';

export default {
  props: ['data', 'slices', 'activeTab'],
  data() {
    return {
      current: null,
    };
  },
  components: {
    ProfileList,
    TheHeader,
    TheFilter,
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      getBioById: 'getBioById',
    }),
    profiles() {
      if (this.slices && this.data && this.current) {
        const activeProfiles = this.slices.filter(
          slice => this.$prismic.richTextAsPlain(slice.primary.title_of_section) === this.current
        )[0];

        if (!activeProfiles) return []
        
        return activeProfiles.items.map(profile => {
          const article = this.getBioById(profile.articles_to_link.uid);
          if (article) {
            let data = {};
            data = { ...article.data || {}, uid: article.uid }
            return data;
          }
        });
      }
      return [];
    },
    filters() {
      if (this.slices) {
        return this.slices.map(slice => this.$prismic.richTextAsPlain(slice.primary.title_of_section));
      }
      return [];
    },
  },
  methods: {
    onChangeFilter(filter) {
      this.current = filter;
    },
  },
  watch: {
    slices() {
      if (!this.current) {
        this.current = this.$prismic.richTextAsPlain(this.slices[0].primary.title_of_section);
      }
    }
  },
  mounted() {
    if (this.activeTab) {
      this.current = this.activeTab;
    } else if (this.slices[0]) {
      this.current = this.$prismic.richTextAsPlain(this.slices[0].primary.title_of_section);
    }
  }
};
</script>

<style lang="scss">
</style>
