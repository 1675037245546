<template>
  <main class="page__content">
    <LandingPageRenderer
      :data="data"
      :slices="slices"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import LandingPageRenderer from '../components/blocks/LandingPageRenderer';

export default {
  components: {
    LandingPageRenderer,
  },
  head: {
    // documentation: https://www.npmjs.com/package/vue-head
    title: {
      inner: 'Home'
    },
    meta: function() {
      return [
        { name: 'title', content: this.metaTitle, id: 'title' },
        { name: 'description', content: this.metaDescription, id: 'description' },
        { name: 'keywords', content: this.metaKeywords, id: 'keywords' },

        { property: 'og:description', content: this.metaDescription, id: 'description' },
        { property: 'og:title', content: this.metaTitle, id: 'og:title' },
      ];
    },
    link: function() {
      return [{
        rel: 'canonical',
        href: this.caronical,
        id: 'canonical'
      }];
    }
  },
  computed: {
    ...mapGetters({
      data: 'getPageData',
      slices: 'getSlices',
    }),
    metaTitle() {
      if (this.data) {
        return this.data.meta_title || '';
      }
      return '';
    },
    metaDescription() {
      if (this.data) {
        return this.data.meta_description || '';
      }
      return '';
    },
    metaKeywords() {
      if (this.data) {
        return this.data.meta_keywords || '';
      }
      return '';
    },
    caronical() {
      return '';
    }
  },
  created() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      await this.$store.dispatch('queryLandingPage', { vm: this });
      // await this.$store.dispatch('page/queryByTag', { tag:'Editor\'s Picks' });
    //   await this.$store.dispatch('page/queryByType', 'all-stars');
    //   await this.$store.dispatch('page/queryPressReleasesList', {pageSize: 3, page: 1, append: false});
    //   await this.$store.dispatch('page/querySettings');
    //   await this.$store.dispatch('page/queryInstagram');
    //   await this.$store.dispatch('page/getLocation');
      this.$emit('updateHead');
    }
  }
};
</script>

<style lang="scss">
/*@import "../../assets/scss/_blocks/page";*/
</style>
