<template>
  <div class="search-box">
    <form @submit.prevent="onSubmit" ref="form">
      <TextInput className="input--thin" class="search-input" @blur="onBlur" v-model="keyword" placeholder="Search" />
      <button type="submit" class="icon" >
        <Icon />
      </button>
    </form>
  </div>
</template>

<script>
import TextInput from '@/components/elements/inputs/TextInput';
import Icon from '@/assets/svg/search.svg';

export default {
  components: {
    TextInput,
    Icon,
  },

  data() {
    return {
      keyword: ''
    }
  },

  methods: {
    onSubmit() {
      if (this.keyword != '') {
        this.$router.push({
          name: 'search',
          params: {
            keyword: this.keyword
          }
        }).catch(()=>{});
        this.$emit('search', this.keyword);
      }
      console.log('IN', this.$refs.form)
      this.$refs.form.reset();
      this.keyword = '';
    },

    onBlur(e) {
      this.$emit('blur', e);
    },
  }

}
</script>

<style lang="scss" scoped>
.search-box {
  position: relative;
}
.icon {
  position: absolute;
  right: 12px;
  top: 8px;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.search-input {
  width: 100%;
}
</style>