<template>
  <div :class="`dropdown dropdown--${itemTitle.toLowerCase()}`" v-if="isDropdownRendered">
    <div class="backdrop" @click="requestClose"></div>
    <div class="inner" ref="inner">
      <div class="container" ref="container">
        <div class="col">
          <h3 class="title">{{ itemTitle }}</h3>
          <TheLink
            className="link--learn-more"
            :to="itemUrl"
            :label="'Learn More'"
          />
        </div>
        <div class="col">
          <div class="menu__line" ref="menuLine"></div>
          <ul class="menu">
            <li v-for="(item, index) in items" :key="index">
              <TheLink
                v-if="isSubmenu(item)"
                :label="item.text"
                type="button"
                @click="loadSubmenu(item, index)"
                :className="`menu__link ${activeSubmenu === index ? 'menu__link--active' : ''}`"
              />
              <TheLink
                v-else
                :to="item.link"
                :label="item.text"
                :external="externalLink(item)"
                className="menu__link"
              />
            </li>
          </ul>
        </div>
        <div class="col">
          <div class="menu__line" ref="submenuLine"></div>
          <ul class="submenu" :ref="'submenu'">
            <li v-for="(subitem, index) in subitems" :key="index">
              <TheLink
                v-if="isSubmenu(subitem)"
                :label="subitem.text"
                type="button"
                @click="loadSidemenu(`sidemenu${index}`, index)"
                :className="`submenu__link ${activeSidemenuIndex === index ? 'submenu__link--active' : ''}`"
              />
              <TheLink
                v-else
                :to="subitem.link"
                :label="subitem.text"
                :external="externalLink(subitem)"
                className="submenu__link"
              />
              <ul class="sidemenu" v-if="isSubmenu(subitem)" :ref="'sidemenu' + index">
                <li v-for="(item, i) in subitem.sublinks" :key="i">
                  <TheLink
                    :to="item.sublink"
                    :label="item.sublink_text"
                    :external="externalLink(item.sublink)"
                    className="sidemenu__link"
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLink from '@/components/elements/buttons/TheLink';

export default {
  components: {
    TheLink,
  },

  data() {
    return {
      mainItem: null,
      isDropdownRendered: false,
      
      activeSubmenu: null,
      subitems: [],

      activeSidemenu: null,
      activeSidemenuIndex: null,
      sidemenu: [],
    }
  },

  props: ['open', 'item'],

  watch: {
    // HIDDEN -> SHOWING
    open(val) {
      if (val) {
        // play open
        this.isDropdownRendered = true;
        this.mainItem = this.item;
        this.$nextTick(() => {
          this.playOpenAnimation();
          this.playShowContent();
        });
      } else {
        // play close
        this.playCloseAnimation();
      }
    },

    // IF ANOTHER DROPDOWN IS OPENED
    item(val) {
      if (!this.open) return
      this.playSwapContent(val);
    },

    $route() {
      this.requestClose();
    }
  },

  computed: {
    itemTitle() {
      if (this.mainItem && this.mainItem.text) return this.mainItem.text
      return ''
    },

    itemUrl() {
      if (this.mainItem && this.mainItem.link) return this.mainItem.link
      return ''
    },

    items() {
      if (this.mainItem && this.mainItem.sublinks) return this.mainItem.sublinks
      return []
    }
  },

  methods: {
    playOpenAnimation() {
      this.$anime({
        targets: this.$refs.inner,
        scaleX: [0, 1],
        duration: [400],
        easing: 'easeOutSine',
      })
    },

    externalLink(item) {
      if (item.link && item.link.link_type) {
        return item.link.link_type === 'Web'
      }
      if (item.link_type) {
        return item.link_type === 'Web'
      }
      return false
    },

    playCloseAnimation() {
      let self = this;

      this.$anime({
        targets: this.$refs.inner,
        opacity: [1, 0],
        duration: 200,
        easing: 'easeOutSine',

        complete() {
          self.activeSubmenu = null;
          self.subitems = [];
          self.$refs.submenuLine.style.height = 0;
          self.activeSidemenu = null;
          self.activeSidemenuIndex = null;
          self.sidemenu = [];
          self.isDropdownRendered = false;
          self.requestClose();
        }
      })
    },

    playSwapContent(newItem) {
      let self = this;

      this.$anime({
        targets: this.$refs.container,
        opacity: [1, 0],
        duration: 200,
        easing: 'easeOutSine',

        complete() {
          self.mainItem = newItem;
          self.activeSubmenu = null;
          self.subitems = [];
          self.$refs.submenuLine.style.height = 0;
          self.activeSidemenu = null;
          self.activeSidemenuIndex = null;
          self.sidemenu = [];
          self.playShowContent();
        }
      })
    },
    playShowContent() {
      this.$anime({
        targets: this.$refs.container,
        opacity: [0, 1],
        duration: 600,
        delay: 200,
        easing: 'easeOutSine',
      })

      this.$anime({
        targets: this.$refs.menuLine,
        height: [0, '100%'],
        duration: 600,
        delay: 600,
        easing: 'easeOutSine',
      })
    },

    playFadeOut(ref, completedCallback) {
      let el = ref; 

      this.$anime({
        targets: el,
        opacity: [1, 0],
        duration: 200,
        easing: 'easeOutSine',

        complete() {
          el.style.display = 'none';
          if (completedCallback && typeof completedCallback === 'function') {
            completedCallback()
          }
        }
      })
    },

    playFadeIn(ref, completedCallback) {
      let el = ref;
      el.style.display = 'block';

      this.$anime({
        targets: el,
        opacity: [0, 1],
        duration: 400,
        easing: 'easeOutSine',

        complete() {
          if (completedCallback && typeof completedCallback === 'function') {
            completedCallback()
          }
        }
      })
    },

    requestClose() {
      this.$emit('close');
    },

    isSubmenu(subitem) {
      if (subitem.sublinks && subitem.sublinks.length > 0) return true;
      return false;
    },

    loadSubmenu(item, index) {
      this.playFadeOut(this.$refs.submenu, () => {
        this.sidemenu = [];
        this.activeSubmenu = index;
        this.subitems = item.sublinks;
        this.activeSidemenu = null;
        this.activeSidemenuIndex = null;
        this.playFadeIn(this.$refs.submenu);
        this.$anime({
          targets: this.$refs.submenuLine,
          height: [0, '100%'],
          duration: 600,
          easing: 'easeOutSine',
        })
      })
    },

    loadSidemenu(refName, index) {
      let ref = this.$refs[refName][0];
      let self = this;
      if (this.activeSidemenu) {
        this.playFadeOut(this.activeSidemenu, function () {
          self.activeSidemenu = ref;
          self.activeSidemenuIndex = index;
          self.playFadeIn(ref);
        })
      } else {
        self.activeSidemenu = ref;
        self.activeSidemenuIndex = index;
        self.playFadeIn(ref);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  $root: &;
  position: fixed;
  top: 0; 
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .inner {
    background: $c_off_white;
    padding-top: 117px; // header height
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    // opacity: 0;
    transform-origin: center left;
  }

  .container {
    padding: 64px 20px;
    max-width: 1000px;
    margin: auto;
    display: flex;

    .col {
      flex: 1;
      padding: 0 48px;
      position: relative;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        flex: 1.3;
        padding-right: 0;
      }
    }
  }

  &--about {
    .container {
      .col:nth-child(2) {
        flex: 2
      }
    }
  }

  &--brands {
    .col:first-of-type {
      max-width: 244px;
    }
    .col:nth-child(2) {
      max-width: 300px;
    }

    @media (max-width: 1100px) {
      .container {
        .col:nth-child(1) {
          padding-left: 0;
          flex: 0;
          min-width: 200px;
        }
        .col:nth-child(2) {
          padding-right: 0;
          flex: 0;
          min-width: 230px;
        }
      }
    }

    @media (max-width: 910px) {
      .sidemenu {
        max-width: 150px;

        & > li {
          margin-bottom: 12px;
        }
      }
    }
  }

  .title {
    @include montserratSemi32;
  }

  .submenu {
    list-style-type: none;
    margin-top: 10px;
    // max-width: 190px;

    li {
      padding-bottom: 4px;
      position: relative;
    }
    &__link {
      line-height: 1.2;
      text-align: left;
      display: inline-block;
      text-transform: none;
      letter-spacing: 0.5px;
      font-size: 12px;
      font-weight: 400;

      &::after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border: 1px solid $c_remax_blue;
        border-left-color: transparent;
        border-bottom-color: transparent;
        transform: rotate(45deg);
        position: relative;
        top: -1px;
        left: -8px;
        opacity: 0;
        transition: all $t_fast;
      }

      &--active {
        color: $c_remax_blue;

        &::after {
          opacity: 1;
          left: 2px;
        }
      }
    }
  }

  .sidemenu {
    list-style-type: none;
    position: absolute;
    width: 250px;
    left: 180px;
    top: 0;
    opacity: 0;
    display: none;
    z-index: 1000;

    &__link {
      line-height: 1.2;
      text-align: left;
      display: inline-block;
      text-transform: none;
      letter-spacing: 0.5px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .menu {
    list-style-type: none;
    margin-top: 10px;

    li {
      &:not(:last-of-type) {
        padding-bottom: 24px;
      }
    }

    &__link {
      line-height: 1.2;
      text-align: left;
      display: inline-block;
      text-transform: none;
      letter-spacing: 0.5px;
      font-size: 14px;

      &::after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border: 1px solid $c_remax_blue;
        border-left-color: transparent;
        border-bottom-color: transparent;
        transform: rotate(45deg);
        position: relative;
        top: -1px;
        left: -8px;
        opacity: 0;
        transition: all $t_fast;
      }

      &--active {
        color: $c_remax_blue;

        &::after {
          opacity: 1;
          left: 2px;
        }
      }
    }

    &__line {
      position: absolute;
      height: 0;
      width: 1px;
      background: $c_text_gray;
      top: 0;
      left: 0;
    }
  }
}
</style>