<template>
  <div>
    <template v-if="!isLoading">
      <template v-if="stories">
        <TopSection
          :headline="headline"
          :category="category"
          :subhead="subhead"
          :stories="stories"
          :smallImage="rightStorySmallImage"
        />
      </template>
      <template v-for="(module, index) in slices">
        <template v-if="module.slice_type === 'resources'">
          <Resources :key="index" />
        </template>
        <template v-if="module.slice_type === 'story_banner' && getBanner(module)">
          <StoryBanner
            :title="getBanner(module).title"
            :description="getBanner(module).description"
            :category="getBanner(module).categoryTag"
            :cta_link="getBanner(module).cta_link" 
            :image="getBanner(module).image"
            :overlay="getBanner(module).overlay"
            :key="index"
          />
        </template>
      </template>
      <CategoryFilter 
        :categories="categories"
        @change="onCategoryChange"
      />
      <template v-if="allStoriesPaginated">
        <div ref="storiesWrapper">
          <StoryBottom
            :stories="allStoriesPaginated"
            :hasMore="hasMore"
          />
        </div>
      </template>
      <LoadMore v-if="hasMore" @loadmore="onLoadMore" :hasMore="hasMore" />
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { fadeOut, fadeIn } from '../../directives/anime';
import TopSection from './News/TopSection';
import StoryBanner from './common/StoryBanner';
import CategoryFilter from './News/CategoryFilter';
import StoryBottom from './News/StoryBottom';
import Resources from './News/Resources';
import LoadMore from './common/LoadMore';

export default {
  props: ['data', 'slices', 'page'],
  data() {
    return {
      selectedCategory: '',
      allStories: [],
    }
  },
  components: {
    TopSection,
    StoryBanner,
    CategoryFilter,
    StoryBottom,
    Resources, 
    LoadMore,
  },
  computed: {
    ...mapGetters({
      articles: 'articles',
      promoted: 'promotedArticles',
      hasMore: 'articlesHasMore',
      isLoading: 'isLoading',
      categories: 'categories',
      getCategoryName: 'getCategoryName',
    }),
    hasMore() {
      return this.page * 5 < this.allStories.length
    },
    category() {
      return this.data.tag || '';
    },
    headline() {
      if (this.data) {
        return this.$prismic.richTextAsPlain(this.data.title);
      }
      return '';
    },
    subhead() {
      if (this.data) {
        return this.data.description;
      }
      return '';
    },
    stories() {
      if (this.promoted) {
        return this.promoted.map(article => {
          let categoryTag = this.category;
          if (article.data.categories && article.data.categories[0] && article.data.categories[0].category && article.data.categories[0].category.uid) {
            categoryTag = this.getCategoryName(article.data.categories[0].category.uid)
          }
          return {...article.data, uid: article.uid, category: categoryTag}
        });
      }
      return [];
    },
    rightStorySmallImage() {
      if (this.data.right_story_small_image) {
        return this.data.right_story_small_image.url
      }
      return ''
    },
    allStoriesPaginated () {
      if (!this.allStories || this.allStories.length === 0) return [];
      return this.allStories.slice(0, this.page * 5);
    },
    pressContacts() {
      if (this.data && this.data.pressContacts) {
        const pressContacts = this.data.pressContacts;
        return pressContacts.filter(item => item.slice_type === 'press_contacts').map(item => item.primary);
      }
      return null;
    },
    instagram() {
      if (this.data && this.data.instagram) {
        const { instagram } = this.data;
        return instagram;
      }
      return null;
    }
  },
  methods: {
    getBanner(module) {
      if (module && module.primary) {
        return {
          title: this.$prismic.richTextAsPlain(module.primary.headline),
          description: module.primary.subhead,
          cta_link: module.primary.link,
          categoryTag: module.primary.category_tag,
          image: module.primary.background_image.url,
          overlay: module.primary.overlay,
        };
      }
      return null;
    },
    getStories() {
      if (this.articles) {
        let stories = this.articles
          .map(article => {
          // initially set sort date to first_publication_date
          let sortDate = '1950-01-01'; // unlikely old date
          // let sortDate = article.first_publication_date;
          if (article.data && article.data.body && Array.isArray(article.data.body)) {
            article.data.body.map(slice => {
              if (slice.slice_type === 'hero' && slice.primary.date) {
                // found hero slice, overwrite sortDate
                sortDate = slice.primary.date;
              }
            });
          }
          let categoryTag = this.category;
          if (article.data.categories && article.data.categories[0] && article.data.categories[0].category && article.data.categories[0].category.uid) {
            categoryTag = this.getCategoryName(article.data.categories[0].category.uid)
          }
          
          return {...article.data, uid: article.uid, category: categoryTag, sortDate: moment(sortDate).toDate()};
        }).sort((a, b) => {
          // sort by sortDate desc
          if (a.sortDate > b.sortDate) return -1;
          if (a.sortDate < b.sortDate) return 1;
          return 0;
        });

        if (this.selectedCategory != '') {
          return stories.filter(article => {
            return article.categories.find(cat => cat.category.uid === this.selectedCategory)
          })
        }

        return stories;
      }
      return [];
    },
    onCategoryChange(val) {
      fadeOut(this.$refs.storiesWrapper, () => {
        this.selectedCategory = val;
        this.allStories = this.getStories();
        this.$emit('categoryChanged');
        fadeIn(this.$refs.storiesWrapper);
      });
    },
    onLoadMore() {
      this.$emit('loadmore');
    }
  },

  mounted() {
    this.allStories = this.getStories();
  },

  watch: {
    articles() {
      this.allStories = this.getStories();
    }
  }
};
</script>

<style lang="scss">
</style>
