<template>
  <div>
    <template v-if="!isLoading">
      <template v-if="hero">
        <HeroBanner
          :title="hero.title"
          :description="hero.description"
          :category="hero.categoryTag"
          :image="hero.image"
          :mobileImage="hero.mobileImage"
          :date="hero.date"
          :useSmall="hero.useSmall"
        />
      </template>
      <div class="article-content js-article-content" ref="article" :class="{'no-hero': !hasHero}">
        <div class="article-content__inner" v-if="modules && modules.length > 0 && !isBasicPage">
          <MediaCartSticky :progress="theProgress" ref="mediaCart" />
        </div>
        <template v-for="(module, index) in modules">
          <template v-if="module.slice_type === 'simple_title' && getTitle(module)">
            <TheHeader
              :heading="getTitle(module).heading"
              :category="getTitle(module).category"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'blurb'">
            <TheBlurb
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'icon_list'">
            <IconList
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'brand_banner'">
            <BrandBanner
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'related' && hasRelatedModule && relatedArticles.length">
            <Popular 
              :articles="relatedArticles" 
              :showCategories="!isBasicPage" 
              :data="relatedModuleData"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'tabs'">
            <TheTabs
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'join_cta'">
            <JoinCTA
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'page_section'">
            <PageSection
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'brands_top_section'">
            <BrandsTopSection
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'multiple_cta_story'">
            <MultipleCTAStory
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'number_cards'">
            <NumberCards
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'bottom_stories'">
            <BottomStories
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'image_grid'">
            <ImageGrid
              :module="module"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'copy' && getCopy(module)">
            <Copy
              :paragraph="getCopy(module).paragraph"
              :headline="getCopy(module).headline"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'numbered_list'">
            <NumberedList
              :items="numberedList(module).items"
              :title="numberedList(module).title"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'numbered_list_type_2'">
            <NumberedListType2
              :items="numberedList(module).items"
              :title="numberedList(module).title"
              :subTitle="numberedList(module).subTitle"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'recommendation'">
            <Recommendation :content="recommendation(module)" :key="index" />
          </template>
          <template v-if="module.slice_type === 'image'">
            <TheImage :width="theImage(module).width" :image="theImage(module).image" :key="index" />
          </template>
          <template v-if="module.slice_type === 'quote'">
            <TheQuote
              :description="theQuote(module).description"
              :byline="theQuote(module).byline"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'embed'">
            <TheEmbed v-bind="theEmbed(module)" :key="index" />
          </template>
          <template v-if="module.slice_type === 'iframe'">
            <TheIframe v-bind="theIframe(module)" :key="index" />
          </template>
          <template v-if="module.slice_type === 'carousel'">
            <Slideshow
              :photos="slideshow(module).photos"
              :title="slideshow(module).title"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'table'">
            <TheTable
              :hasHeader="theTable(module).hasHeader"
              :hasPagination="theTable(module).hasPagination"
              :url="theTable(module).url"
              :title="theTable(module).title"
              :rowsPerPage="theTable(module).rowsPerPage"
              :defaultState="theTable(module).defaultState"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'list_table'">
            <ListTable
              :hasPagination="listTable(module).hasPagination"
              :title="listTable(module).title"
              :list="listTable(module).list"
              :defaultState="listTable(module).defaultState"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'cards'">
            <TheCarousel :cards="cards(module)" :key="index" />
          </template>
          <template v-if="module.slice_type === 'q_a'">
            <QuestionAnswer :items="theQA(module)" :key="index" />
          </template>
          <template v-if="module.slice_type === 'video'">
            <TheVideo
              :title="theVideo(module).title"
              :poster="theVideo(module).image"
              :video="theVideo(module).video"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'audio'">
            <TheAudio
              :title="theAudio(module).title"
              :text="theAudio(module).text"
              :src="theAudio(module).src"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'callout'">
            <Callout
              :description="callout(module).description"
              :title="callout(module).title"
              :image="callout(module).image"
              :items="callout(module).items"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'files'">
            <FileReport
              :description="fileReport(module).title"
              :files="fileReport(module).files"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'comments'">
            <Comments :key="index" />
          </template>
        </template>
      </div>
      <template v-if="author">
        <PostMeta :author="author" :categories="categories" />
      </template>
      <!-- <template v-if="hasRelatedModule && relatedArticles.length">
        <Popular 
          :articles="relatedArticles" 
          :showCategories="!isBasicPage" 
          :data="relatedModuleData"
        />
      </template> -->
      <template v-if="instagram">
        <Instagram :posts="instagram" />
      </template>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import HeroBanner from './Article/HeroBanner';
import TheHeader from './common/TheHeader';
import TheBlurb from './common/TheBlurb';
import IconList from './common/IconList';
import BrandBanner from './common/BrandBanner';
import JoinCTA from './common/JoinCTA';
import NumberCards from './common/NumberCards';
import BottomStories from './common/BottomStories';
import BrandsTopSection from './common/BrandsTopSection';
import MultipleCTAStory from './common/MultipleCTAStory';
import TheTabs from './common/TheTabs';
import ImageGrid from './common/ImageGrid';
import PageSection from './common/PageSection';
import Callout from './Article/Callout';
import Copy from './Article/Copy';
import NumberedList from './Article/NumberedList';
import NumberedListType2 from './Article/NumberedListType2'; 
import QuestionAnswer from './Article/QuestionAnswer';
import Recommendation from './Article/Recommendation';
import Slideshow from './Article/Slideshow';
import TheAudio from './Article/TheAudio';
import TheImage from './Article/TheImage';
import TheQuote from './Article/TheQuote';
import TheEmbed from './Article/TheEmbed';
import TheVideo from './Article/TheVideo';
import TheIframe from './Article/TheIframe';
import PostMeta from './Article/PostMeta';
import Popular from './Article/Popular';
import TheTable from './Article/TheTable';
import TheCarousel from './Article/TheCarousel';
import ListTable from './Article/ListTable';
import FileReport from './Article/FileReport';
import Instagram from './Instagram';
import MediaCartSticky from './Article/MediaCartSticky';
import Comments from './common/Comments';

export default {
  props: {
    uid: String,
    data: Object,
    article: Array,
    isBasicPage: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    HeroBanner,
    TheHeader,
    TheBlurb,
    IconList,
    BrandBanner,
    JoinCTA,
    NumberCards,
    BottomStories,
    BrandsTopSection,
    MultipleCTAStory,
    TheTabs,
    PageSection,
    ImageGrid,
    Callout,
    Copy,
    NumberedList,
    NumberedListType2,
    QuestionAnswer,
    Recommendation,
    Slideshow,
    TheAudio,
    TheImage,
    TheQuote,
    TheEmbed,
    TheVideo,
    TheIframe,
    PostMeta,
    Popular,
    TheTable,
    TheCarousel,
    ListTable,
    FileReport,
    Instagram,
    MediaCartSticky,
    Comments,
  },
  data() {
    return {
      progress: 0,
      lastScrollY: 0,
      relatedArticles : [],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      getIsMobile: 'getIsMobile',
    }),
    isStickyMediaCartSmall() {
      return this.getIsMobile;
    },
    hero() {
      const hero = _.find(this.article, { slice_type: 'hero' });
      if (hero && hero.primary) {
        return {
          title: this.$prismic.richTextAsPlain(hero.primary.headline),
          description: hero.primary.subhead,
          categoryTag: hero.primary.category_tag1,
          image: hero.primary.image1.url,
          mobileImage: hero.primary.mobile_image ? hero.primary.mobile_image.url : null,
          date: hero.primary.date
            ? moment(hero.primary.date).format('MM/DD/YYYY')
            : '',
          useSmall: hero.primary.use_smaller,
        };
      }
      return null;
    },
    hasHero() {
      const hero = _.find(this.article, { slice_type: 'hero' });
      if (hero && hero.primary) {
        return true;
      }
      return false;
    },
    modules() {
      return this.article.filter(item => item.slice_type !== 'hero');
    },
    hasRelatedModule() {
      const related = _.find(this.article, { slice_type: 'related' });
      if (related && related.primary) {
        return related;
      }
      return false;
    },
    relatedModuleData() {
      if (!this.hasRelatedModule) return null
      const module = this.hasRelatedModule;
      return {
        title: module.primary.headline,
        subhead: this.$prismic.richTextAsPlain(module.primary.subhead),
        background: module.primary.background.url ? module.primary.background.url : false,
        whiteText: module.primary.text_color,
      }
    },
    instagram() {
      if (this.data && this.data.instagram) {
        const { instagram } = this.data;
        return instagram;
      }
      return null;
    },
    author() {
      if (this.data && this.data.author) {
        return {
          name: this.data.author,
          email: this.data.email,
        };
      }
      return null;
    },
    categories() {
      if (this.data && this.data.related_tags) {
        return this.data.related_tags
          .filter(item => (item.tag.data ? true : false))
          .map(item => {
            return {
              title: this.$prismic.richTextAsPlain(item.tag.data.title),
              uid: item.tag.uid,
            };
          });
      }
      return null;
    },
    theProgress() {
      return this.progress;
    },
  },
  methods: {
    recalculateStickyPosition(scroll) {
      if (this.isBasicPage) return
      if (!scroll) scroll = this.lastScrollY;

      const scrollOffset = 550;
      let heroOffset = 0;
      if (!this.hasHero) {
        heroOffset = 800;
      }

      if (!this.$refs.article) return false;

      const oneStep = this.$refs.article.scrollHeight / 7;

      if (this.isStickyMediaCartSmall) {
        // media cart sticky is in mobile mode
        if (!this.hasHero) {
          heroOffset = 500;
        }

        if (
          scroll > this.$refs.article.scrollHeight - heroOffset + 100 ||
          scroll < scrollOffset - heroOffset
        ) {
          // if article bottom reached, hide
          this.$refs.mediaCart.$el.classList.add('is-mobile-hidden');
        } else {
          let mobileOffset = 0;
          if (!this.hasHero) {
            mobileOffset = 100;
          }
          // if scrolling within article
          this.$refs.mediaCart.$el.classList.remove('is-mobile-hidden');
          this.$refs.mediaCart.$el.style.top =
            scroll -
            this.$refs.article.offsetTop +
            window.innerHeight -
            mobileOffset -
            this.$refs.mediaCart.$el.clientHeight +
            'px';
        }

        this.progress = parseInt((scroll - scrollOffset) / oneStep);
      } else {
        // desktop mode
        if (
          scroll > scrollOffset - heroOffset &&
          scroll <= this.$refs.article.scrollHeight - heroOffset
        ) {
          this.$refs.mediaCart.$el.style.top = scroll - scrollOffset + heroOffset + 'px';
          this.progress = parseInt((scroll - scrollOffset) / oneStep);
        } else if (scroll > this.$refs.article.scrollHeight - heroOffset) {
          this.$refs.mediaCart.$el.style.top =
            this.$refs.article.scrollHeight - scrollOffset + 'px';
          this.progress = 7;
        }
      }
    },
    onScroll(e) {
      const scroll = e.detail.offset.y;
      this.recalculateStickyPosition(scroll);
      this.lastScrollY = scroll;
    },
    onResize() {
      this.recalculateStickyPosition();
    },
    getTitle(module) {
      if (module && module.primary) {
        return {
          heading: module.primary.heading,
          category: module.primary.category_tag,
        };
      }
      return null;
    },
    getCopy(module) {
      if (module && module.primary) {
        return {
          paragraph: module.primary.paragraph,
          headline: this.$prismic.richTextAsPlain(module.primary.headline),
        };
      }
      return null;
    },
    numberedList(module) {
      if (module && module.primary) {
        return {
          items: module.items.map(item => item.item),
          title: this.$prismic.richTextAsPlain(module.primary.title1),
          subTitle: this.$prismic.richTextAsPlain(module.primary.sub_title),
        };
      }
      return null;
    },
    recommendation(module) {
      if (module && module.primary) {
        return {
          category_tag: module.primary.category_tag1,
          headline: this.$prismic.richTextAsPlain(module.primary.headline),
          title: module.primary.title1,
          link: module.primary.link,
        };
      }
      return null;
    },
    callout(module) {
      if (module && module.primary) {
        return {
          description: module.primary.paragraph,
          title: this.$prismic.richTextAsPlain(module.primary.headline),
          image: module.primary.image1.url,
          items: module.items.map(item => ({
            image: item.icon.url,
            text: item.paragraph,
          })),
        };
      }
      return null;
    },
    slideshow(module) {
      if (module && module.primary) {
        return {
          photos: module.items.map(item => ({
            caption: item.caption,
            image: item.image1.url,
          })),
          title: this.$prismic.richTextAsPlain(module.primary.title1),
        };
      }
      return null;
    },
    theVideo(module) {
      if (module && module.primary) {
        return {
          title: this.$prismic.richTextAsPlain(module.primary.title1),
          image: module.primary.cover_image.url,
          video: module.primary.video_file.url,
        };
      }
      return null;
    },
    theImage(module) {
      if (module && module.primary) {
        return {
          width: module.primary.width,
          image: module.primary.image1.url,
        };
      }
      return null;
    },
    theQuote(module) {
      if (module && module.primary) {
        return {
          description: module.primary.text || '',
          byline: module.primary.byline,
        };
      }
      return null;
    },
    theEmbed(module) {
      if (
        module &&
        module.primary &&
        module.primary.embed &&
        module.primary.embed.html
      ) {
        return {
          html: module.primary.embed.html,
          width: module.primary.embed.width,
          height: module.primary.embed.height,
        };
      }
      return null;
    },
    theIframe(module) {
      if (
        module &&
        module.primary &&
        module.primary.iframe_tag
      ) {
        return {
          html: this.$prismic.richTextAsPlain(module.primary.iframe_tag)
        };
      }
      return null;
    },
    theAudio(module) {
      if (module && module.primary) {
        return {
          title: this.$prismic.richTextAsPlain(module.primary.headline),
          text: module.primary.subhead,
          src: module.primary.audio_file.url,
        };
      }
      return null;
    },
    theTable(module) {
      if (module && module.primary) {
        return {
          url: module.primary.csv_file.url,
          title: this.$prismic.richTextAsPlain(module.primary.table_title),
          hasHeader: module.primary.headers === 'Parse first CSV row as header',
          hasPagination: module.primary.pagination === 'Items are paginated',
          rowsPerPage: module.primary.rows_per_page
            ? module.primary.rows_per_page
            : 5,
          defaultState: module.primary.default_state,
        };
      }
      return null;
    },
    listTable(module) {
      if (module && module.primary) {
        return {
          title: this.$prismic.richTextAsPlain(module.primary.list_title),
          hasPagination: module.primary.pagination === 'Items are paginated',
          defaultState: module.primary.default_state,
          list: module.items.map(item => ({
            image: item.item_image.url,
            name: item.item_title[0] ? item.item_title[0].text : '',
            location: item.location1,
            position: item.position,
          })),
        };
      }
    },
    cards(module) {
      if (module && module.items) {
        return module.items.map(item => ({
          image: item.icon.url,
          title: this.$prismic.richTextAsPlain(item.headline),
          text: item.paragraph,
        }));
      }
    },
    theQA(module) {
      if (module && module.items) {
        return module.items.map(item => ({
          question: this.$prismic.richTextAsPlain(item.question),
          answer: this.$prismic.richTextAsPlain(item.answer),
        }));
      }
    },
    fileReport(module) {
      if (module && module.primary && module.items) {
        return {
          title: this.$prismic.richTextAsPlain(module.primary.headline),
          files: module.items.map(item => ({
            label: item.link_title,
            link: item.link,
          })),
        };
      }
    },
  },

  watch: {
    async hasRelatedModule(val) {
      if (!val) {
        this.relatedArticles = [];
      } 
      const related = _.find(this.article, { slice_type: 'related' });

      let manual = [];
      let auto = [];

      // get manualy selected articles
      let ids = [];
      if (related.items) {
        related.items.forEach(item => {
          if (item.article && item.article.id) {
            ids.push(item.article.id)
          }
        });
      }
      if (ids.length) {
        const response = await this.$prismic.client.getByIDs(ids);
        if (response.results) {
          manual = response.results;
        }
      }

      // if AUTO FILL fetch 4-x most recent from same category
      if (related.primary.auto_fill && !this.isBasicPage && this.data.categories && this.data.categories.length) {
        const freeSlots = 4 - ids.length;
        let cat = this.data.categories[0].category.id;
        let preds = [];
        preds.push(this.$prismic.Predicates.not('my.news_article.uid', this.uid));
        ids.forEach(id => {
          preds.push(this.$prismic.Predicates.not('document.id', id))
        });
        const response = await this.$prismic.client.query(
          [
            this.$prismic.Predicates.at('document.type', 'news_article'),
            this.$prismic.Predicates.at('my.news_article.categories.category', cat),
            ...preds,
          ],
          {
            orderings: '[document.first_publication_date desc]',
            pageSize: freeSlots,
            page: 1,
          }
        );

        if (response.results) {
          auto = response.results;
        }
      }

      this.relatedArticles = manual.concat(auto);
    },
  },

  mounted() {
    window.addEventListener('remax_scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('remax_scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss">
</style>
