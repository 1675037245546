<template>
  <figure class="story__figure"
          :class="className"
          v-observe-visibility="getVisibilityOpts">
    <div class="story__figure-content">
      <div class="story__image-shadow"
           ref="shadow"
           :class="{ 'story__image-shadow--border': border }"></div>
      <div class="story__image-wrap">
        <div class="story__image-backdrop" ref="backdrop"></div>
        <img
          :src="image"
          :alt="alt"
          class="story__image"
          ref="image"
        />
      </div>
    </div>
  </figure>
</template>

<script>
export default {
  props: ['image', 'alt', 'className', 'border', 'animateShadow', 'intersection'],
  computed: {
    getVisibilityOpts () {
      const opts = {
        callback: this.visibilityChanged,
        throttle: 400,
        once: true,
      };
      if (this.intersection) opts.intersection = this.intersection;
      return opts;
    },
  },
  methods: {

    visibilityChanged (isVisible) {

      if (!isVisible) return;

      if (this.$refs.image)
        this.$refs.image.style.opacity = 0;

      this
        .$anime
        .timeline()
        .add({
          targets: this.$refs.backdrop,
          translateY: ['100%', 0],
          easing: 'easeOutSine',
          duration: 400,
          complete: () => {

            if (this.animateShadow && this.$refs.shadow) {
              this.$refs.shadow.style.opacity = 1;

              this
                .$anime({
                  targets: this.$refs.shadow,
                  translateY: [8, 0],
                  translateX: [8, 0],
                  easing: 'easeOutQuad',
                  duration: 600,
                  delay: 400,
                });
            }

            if (this.$refs.image)
              this.$refs.image.style.opacity = 1;

          }
        })
        .add({
          targets: this.$refs.backdrop,
          translateY: [0, '-105%'],
          easing: 'easeInSine',
          duration: 400
        });

    },

  },
  mounted () {

    if (this.animateShadow && this.$refs.shadow) {
      this.$refs.shadow.style.opacity = 0;
    }

  }
}
</script>

<style lang="scss" scoped>
.story {
  $root: &;

  &__figure {
    position: relative;
    display: block;
    margin: 0;

    &--primary {
      z-index: $z_index_top - 2;
    }

    &--secondary {
      z-index: $z_index_top - 1;
    }

    #{$root}__figure-content {
      position: relative;
      display: block;
      width: 100%;

      #{$root}__image-shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        top: -8px;
        left: -8px;
        background-color: $c_white;
        z-index: $z_index_back;

        &--border {
          width: calc(100% + 16px);
          height: calc(100% + 16px);

          @include createQuery($sr_w_md) {
            width: calc(100% + 8px);
            height: calc(100% + 8px);
          }
        }

        @include createQuery($sr_w_md) {
          top: -4px;
          left: -4px;
        }
      }

      #{$root}__image-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z_index_min;
        overflow: hidden;

        #{$root}__image-backdrop {
          position: absolute;
          z-index: $z_index_min;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          background-color: $c_biscay;

          transform: translateY(100%);
        }

        #{$root}__image {
          display: block;
          position: absolute;
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;

          opacity: 0;
        }
      }
    }
  }
}
</style>
