<template>
  <div
    class="article-numbered-list"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-numbered-list__inner">
      <h3 class="article-numbered-list__title u-f-montserrat-semibold" ref="title">
        {{ title }}
      </h3>
      <h4 class="article-numbered-list__sub-title u-f-montserrat-semibold" ref="subTitle">
        {{ subTitle }}
      </h4>
      <div class="article-numbered-list__content">
        <div
          v-for="(text, index) in items"
          :key="index"
          class="article-numbered-list__item u-f-opensans-regular"
        >
          <span class="article-numbered-list__number u-f-montserrat-regular" ref="number">{{ pad(index + 1, 2) }}</span>
          <p class="article-numbered-list__text" ref="text">{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'subTitle', 'items'],
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.subTitle,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 900,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.number,
        opacity: [0, 1],
        translateX: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
    pad(num, size) {
      let s = num + '';
      while (s.length < size) s = '0' + s;
      return s;
    }
  },
};
</script>

<style lang="scss" scoped>
.article-numbered-list {
  position: relative;
  background: $c_white;
  overflow: hidden;

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;
    flex-direction: column;

    @include printMaxWith;
  }

  &__title {
    margin: 53px 0 0 0;
    font-size: 23px;
    line-height: 1.35;
    letter-spacing: 0.72px;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      margin-top: 23px;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.58px;
    }
  }

  &__sub-title {
    margin: 15px 0 0 0;
    font-size: 16px;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      font-size: 12px;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-top: 18px;
    margin-bottom: 54px;

    @include createQuery($sr_w_xs) {
      margin-bottom: 27px;
    }
  }

  &__number {
    margin-top: 8px;
    margin-right: 23px;
    width: 61px;
    font-size: 40px;
    line-height: 0.78;
    letter-spacing: 1.25px;
    color: $c_cobalt;

    opacity: 0;

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      margin-top: 3px;
      width: 31px;
      font-size: 28px;
      line-height: 1.11;
      letter-spacing: 0.88px;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 0;
    flex: 1;
    font-size: 16px;
    line-height: 2.06;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1.85;
    }
  }
}
</style>
