<template>
  <div>
    <template v-if="!isLoading">
      <template v-if="data">
        <Profile
          :profile="data"
        />
      </template>
      <template v-if="bio">
        <TheContent
          v-for="(content, index) in bio"
          :key="index"
          :content="content"
        />
      </template>
      <TheBottom />
    </template>
  </div>
</template>

<script>
// import _ from 'lodash';
import { mapGetters } from 'vuex';
import Profile from './BioDetail/Profile';
import TheContent from './BioDetail/TheContent';
import TheBottom from './BioDetail/TheBottom';

export default {
  props: ['data', 'bio'],
  components: {
    Profile,
    TheContent,
    TheBottom
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
    }),
  },
};
</script>

<style lang="scss">
</style>
