<template>
  <div class="landing__wrapper">
    <template v-if="!isLoading">
      <template v-if="hero">
        <HeroStory
          :title="hero.title"
          :description="hero.description"
          :category="hero.categoryTag"
          :cta="hero.cta"
          :cta_link="hero.cta_link"
          :vimeoUrl="hero.vimeo_id"
          @next="onNext"
        />
      </template>
      <main ref="main">

        <template v-for="(module, index) in modules">
          <template v-if="module.slice_type === 'story_2-up'">
            <MidStory
              :leftMiddle="leftMiddle(module)"
              :rightMiddle="rightMiddle(module)"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'bottom_story' && bottomStory(module)">
            <BottomStory :story="bottomStory(module)" :key="index" />
          </template>
          <template v-if="module.slice_type === 'intro_paragraph' && introParagraph(module)">
            <IntroParagraph
              :key="index"  
              :title="introParagraph(module).title"
              :paragraph="introParagraph(module).paragraph"
            />
          </template>
          <template v-if="module.slice_type === 'story_banner' && storyBanner(module)">
            <StoryBanner
              :title="storyBanner(module).title"
              :title_type="storyBanner(module).title_type"
              :description="storyBanner(module).description"
              :category="storyBanner(module).categoryTag"
              :cta_link="storyBanner(module).cta_link"
              :accent_color="storyBanner(module).accent_color"
              :image="storyBanner(module).image"
              :overlay="storyBanner(module).overlay"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'split_cta' && splitCta(module)">
            <SplitCta
              :key="index"  
              :title="splitCta(module).title"
              :ctaLink="splitCta(module).ctaLink"
              :ctaText="splitCta(module).ctaText"
              :image="splitCta(module).image"
            />
          </template>
          <template v-if="module.slice_type === 'cta_button_module'">
            <JoinRemax :key="index" />
          </template>
          <template v-if="module.slice_type === 'press_releases' && pressReleasesList && pressContacts">
            <PressBottom
              :pressReleases="pressReleasesList"
              :pressContacts="pressContacts"
              :key="index"
            />
          </template>
          <template v-if="module.slice_type === 'social_media' &&  instagram">
            <Instagram :posts="instagram" :key="index" />
          </template>
        </template>
      </main>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import HeroStory from './Landing/HeroStory';
import MidStory from './Landing/MidStory';
import BottomStory from './Landing/BottomStory';
import StoryBanner from './common/StoryBanner';
import IntroParagraph from './Landing/IntroParagraph';
import SplitCta from './Landing/SplitCta';

export default {
  props: ['data', 'slices'],
  components: {
    HeroStory, 
    MidStory,
    BottomStory,
    StoryBanner,
    IntroParagraph,
    SplitCta,
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      getIsMobile: 'getIsMobile',
      mainContent: 'getMainContent',
    }),
    hero() {
      const hero = _.find(this.slices, { slice_type: 'landing_hero' });
      if (hero && hero.primary) {
        return {
          title: hero.primary.headline[0].text,
          description: this.$prismic.richTextAsPlain(hero.primary.subhead),
          cta: hero.primary.cta_button_text ?? false,
          cta_link: hero.primary.cta_link,
          categoryTag: hero.primary.category_tag,
          vimeo_id: hero.primary.vimeo_id,
        };
      }
      return null;
    },
    modules() {
      return this.slices
        .filter(slice => slice.slice_type !== 'landing_hero');
    },
  },
  methods: {
    onNext() {
      if (this.$refs.main) {
        const pos = this.$refs.main.offsetTop - 40;
        this.mainContent.scrollTo(0, pos, 800);
      }
    },
    introParagraph(module) {
      if (module && module.primary) {
        return {
          title: module.primary.title1,
          paragraph: module.primary.paragraph,
        };
      }
      return null;
    },
    splitCta(module) {
      if (module && module.primary) {
        return {
          title: module.primary.title1,
          ctaText: module.primary.cta_text,
          ctaLink: module.primary.cta_link,
          image: module.primary.image.url,
        };
      }
      return null;
    },
    storyBanner(module) {
      if (module && module.primary) {
        let accent_color = '';
        if (module.primary.accent_color) {
          let val = module.primary.accent_color;
          if (val === 'Green') {
            accent_color = 'green'
          } else if (val === 'Blue (Remax)') {
            accent_color = 'blue'
          } else if (val === 'Red (Remax)') {
            accent_color = 'red'
          } else if (val === 'Navy') {
            accent_color = 'navy'
          } else {
            accent_color = ''
          }
        }
        let image = '';
        if (this.getIsMobile && module.primary.mobile_background && module.primary.mobile_background.url) {
          image = module.primary.mobile_background.url
        } else {
          image = module.primary.background_image.url
        }
        return {
          title: this.$prismic.richTextAsPlain(module.primary.headline),
          title_type: module.primary.headline[0].type,
          description: module.primary.subhead,
          cta_link: module.primary.link,
          accent_color: accent_color,
          categoryTag: module.primary.category_tag,
          image: image,
          overlay: module.primary.overlay,
        };
      }
      return null;
    },
    leftMiddle(module) {
      if (module && module.primary) {
        return {
          title: this.$prismic.richTextAsPlain(module.primary.left_headline),
          description: module.primary.left_subhead,
          category: module.primary.left_category_tag,
          image: module.primary.left_image.url,
          cta_link: module.primary.left_link,
          cta_text: module.primary.left_link_text,
        };
      }
      return null;
    },
    rightMiddle(module) {
      if (module && module.primary) {
        return {
          title: this.$prismic.richTextAsPlain(module.primary.right_headline),
          description: module.primary.right_subhead,
          category: module.primary.right_category_tag,
          image: module.primary.right_large_image.url,
          smallImage: module.primary.right_small_image.url,
          cta_link: module.primary.right_link,
          cta_text: module.primary.right_link_text,
        };
      }
      return null;
    },
    bottomStory(module) {
      if (module && module.primary) {
        return {
          title: this.$prismic.richTextAsPlain(module.primary.headline),
          description: module.primary.subhead,
          category: module.primary.category_tag,
          image: module.primary.image.url,
          cta_link: module.primary.link,
          cta_text: module.primary.link_text,
          buttons: module.items ? module.items : [],
          join_title: module.primary.cta_title ? module.primary.cta_title : 'Join RE/MAX',
          join_copy: module.primary.cta_copy ? module.primary.cta_copy : 'Learn more about the RE/MAX network and Motto Mortgage and how you can be empowered to run your business your way.',
        };
      }
      return null;
    },
  }
};
</script>

<style lang="scss">
.landing__wrapper {
  overflow-y: hidden;
}
</style>
