<template>
  <div class="slideshow__social">
    <ul>
      <li v-for="(link, index) in links" :key="index">
        <a @click="onShare(link)">
          <component :is="getIcon(link)" :class="link" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import facebookIcon from '@/assets/svg/facebook.svg';
import linkedinIcon from '@/assets/svg/linkedin.svg';
import pinterestIcon from '@/assets/svg/pinterest.svg';
import twitterIcon from '@/assets/svg/twitter.svg';
import printIcon from '@/assets/svg/icon-print.svg';
import emailIcon from '@/assets/svg/icon-email.svg';

export default {
  components: {
    facebookIcon,
    linkedinIcon,
    pinterestIcon,
    twitterIcon,
    printIcon,
    emailIcon
  },
  data() {
    return {
      links: ['facebook', 'twitter', 'email', 'linkedin', 'pinterest', 'print']
    }
  },
  methods: {
    getIcon(title) {
      switch (title) {
        case 'facebook':
          return facebookIcon;
        case 'pinterest':
          return pinterestIcon;
        case 'linkedin':
          return linkedinIcon;
        case 'print':
          return printIcon;
        case 'twitter':
          return twitterIcon;
        case 'email':
          return emailIcon;
      }
    },
    onShare(type) {
      this.$emit(type);
    } 
  }
};
</script>

<style lang="scss">
.slideshow {
  &__social {
    ul {
      @include resetUL;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include createQuery($sr_w_xs) {
        padding-right: 52px;
      }
    }

    li {
      display: inline-block;
      margin-right: 23px;

      @include createQuery($sr_w_md) {
        width: 30px;
        height: 30px;
        text-align: center;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      display: inline-block;
      font-size: 9px;
      line-height: normal;
      letter-spacing: 0.93px;
      color: $c_regent_gray;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;

      transition: color $t_normal;

      &:hover {
        svg {
          * {
            fill: $c_sail;
          }
        }
      }

      svg {
        * {
          fill: $c_white;
          transition: fill $t_normal;
        }

        &.facebook {
          width: 9px;
        }

        &.twitter {
          width: 22.5px;
        }

        &.email {
          width: 22.5px;
          height: 15px;
        }

        &.linkedin {
          width: 18.2px;
        }

        &.pinterest {
          width: 18px;
        }

        &.print {
          width: 20px;
        }
      }
    }
  }
}
</style>
