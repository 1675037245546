<template>
  <figure class="timeline__figure" :class="layoutClassName" v-observe-visibility="getVisibilityOpts">
    <div class="timeline__figure-content">
      <div
        class="timeline__image-shadow"
        ref="shadow"
        :class="{ 'timeline__image-shadow--border': border }"
      ></div>
      <div class="timeline__image-wrap">
        <div class="timeline__image-backdrop" ref="backdrop"></div>
        <img :src="image" :alt="alt" class="timeline__image" ref="image" />
      </div>
    </div>
  </figure>
</template>

<script>
export default {
  props: [
    'image',
    'alt',
    'className',
    'border',
    'animateShadow',
    'intersection',
    'layout',
  ],
  computed: {
    getVisibilityOpts() {
      const opts = {
        callback: this.visibilityChanged,
        throttle: 400,
        once: true,
      };
      if (this.intersection) opts.intersection = this.intersection;
      return opts;
    },
    layoutClassName() {
      if (this.layout) {
        return `timeline__figure--${this.layout.toLowerCase()}`;
      }
      return ''
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      if (this.$refs.image) this.$refs.image.style.opacity = 0;

      this.$anime
        .timeline()
        .add({
          targets: this.$refs.backdrop,
          translateY: ['100%', 0],
          easing: 'easeOutSine',
          duration: 400,
          complete: () => {
            if (this.animateShadow && this.$refs.shadow) {
              this.$refs.shadow.style.opacity = 1;

              this.$anime({
                targets: this.$refs.shadow,
                translateY: [8, 0],
                translateX: [8, 0],
                easing: 'easeOutQuad',
                duration: 600,
                delay: 400,
              });
            }

            if (this.$refs.image) this.$refs.image.style.opacity = 1;
          },
        })
        .add({
          targets: this.$refs.backdrop,
          translateY: [0, '-100%'],
          easing: 'easeInSine',
          duration: 400,
        });
    },
  },
  mounted() {
    if (this.animateShadow && this.$refs.shadow) {
      this.$refs.shadow.style.opacity = 0;
    }
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  $root: &;

  &__figure {
    position: relative;
    display: block;
    margin: 0;

    &--primary {
      width: 299px;
      z-index: $z_index_top - 2;

      &#{$root}__figure--vertical {
        width: 299px;
        margin-left: -42px;

        @include createQuery($sr_w_xs) {
          width: 135px;
          margin-left: auto;
          margin-right: auto;
        }

        #{$root}__figure-content {
          padding-bottom: 130.43%;
        }
      }

      &#{$root}__figure--square {
        width: 399px;
        margin-left: -100px;

        @include createQuery($sr_w_xs) {
          width: 168px;
          margin-left: auto;
          margin-right: auto;
        }

        #{$root}__figure-content {
          padding-bottom: 77.94%;
        }
      }

      &#{$root}__figure--horizontal {
        width: 450px;
        margin-left: -150px;

        @include createQuery($sr_w_xs) {
          width: 195px;
          margin-left: auto;
          margin-right: auto;
        }

        #{$root}__figure-content {
          padding-bottom: 53.56%;
        }
      }

      #{$root}__figure-content {
        padding-bottom: 77.94%;
      }
    }

    &--secondary {
      width: 200px;
      margin-left: -70px;
      margin-top: -30%;
      z-index: $z_index_top - 1;

      @include createQuery($sr_w_xs) {
        width: 90px;
        margin-left: 40px;
        margin-top: -10%;
      }

      #{$root}__figure-content {
        padding-bottom: 91.5%;

        #{$root}__image-shadow {
          display: none;
        }
      }
    }

    #{$root}__figure-content {
      position: relative;
      display: block;
      width: 100%;

      #{$root}__image-shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        top: -8px;
        left: -8px;
        background-color: $c_alizarin_crimson;
        z-index: $z_index_back;

        @at-root #{$root}--light-blue & {
          background-color: $c_white;
        }

        @at-root #{$root}--grey & {
          background-color: $c_blue_ribbon;
        }

        @at-root #{$root}--white & {
          background-color: $c_blue_ribbon;
        }

        &--border {
          width: calc(100% + 16px);
          height: calc(100% + 16px);

          @include createQuery($sr_w_md) {
            width: calc(100% + 8px);
            height: calc(100% + 8px);
          }
        }

        @include createQuery($sr_w_md) {
          top: -4px;
          left: -4px;
        }
      }

      #{$root}__image-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z_index_min;
        overflow: hidden;

        #{$root}__image-backdrop {
          position: absolute;
          z-index: $z_index_min;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          background-color: $c_biscay;

          transform: translateY(100%);
        }

        #{$root}__image {
          display: block;
          position: absolute;
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;

          opacity: 0;
        }
      }
    }
  }
}
</style>
