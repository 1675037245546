<template>
  <div
    class="article-video"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
    ref="article"
  >
    <div class="article-video__image-wrap" ref="imageWrap">
      <div class="article-video__image" ref="video" :style="imageBackground" v-parallax.watchParent="0.1"></div>
    </div>
    <div class="article-video__overlay"></div>
    <div class="article-video__inner">
      <div class="article-video__wrap">
        <h2 class="article-video__title u-f-montserrat-black" ref="title">{{ title }}</h2>
        <playIcon class="article-video__btn" @click="onClickPlay" ref="button" />
      </div>
    </div>
    <Modal @close="onCloseVideo" :top="getOffsetTop" v-if="isShowVideo">
      <video autoplay muted loop class="article-video__video" ref="video">
        <source :src="video" type="video/mp4">
      </video>
    </Modal>
  </div>
</template>

<script>
import playIcon from '@/assets/svg/play-btn.svg';
// import closeIcon from '@/assets/svg/icon-x.svg';
import Modal from '../../elements/modal/Modal';
export default {
  props: ['title', 'video', 'poster'],
  components: {
    Modal,
    playIcon,
    // closeIcon
  },
  data() {
    return {
      playVideo: false,
      offsetTop: 0
    };
  },
  computed: {
    imageBackground() {
      return `background-image:url('${this.poster}');`;
    },
    isShowVideo() {
      return this.playVideo;
    },
    getOffsetTop() {
      return this.offsetTop;
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.imageWrap,
        opacity: [0, 1],
        scale: [1, 1.05],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });

      // {"translateY":["-20px",0],"opacity":1}
      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.button,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

// "translateY":["10px",0],"opacity":1,"duration":600
    },
    onClickPlay() {
      const offsetTop = this.$refs.article.offsetTop;
      const rect = this.$refs.article.getBoundingClientRect();
      this.offsetTop = offsetTop - rect.top;
      this.playVideo = true;
    },
    onCloseVideo() {
      this.playVideo = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.article-video {
  position: relative;
  background: darken($c_regent_gray, 50%);
  overflow: hidden;

  &__popup {
    position: absolute;
    top: 46px; // stripe height
    left: 0;
    right: 0;
    height: calc(100vh - 46px);
    display: flex;
    justify-content: space-between;

    video {
      width: 100%;
    }

    .article-video__close {

    }
  }

  &__image-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 0; // set in js

    @include noAnimationInPrint;
  }

  &__image {
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: 0;
    width: 100%;
    /*height: 100%;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__video {
    width: 100%;
  }

  &__overlay {
    @include overlay;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    justify-content: center;
    padding: 178px 200px 175px;

    @include createQuery($sr_w_md) {
      flex-direction: row;
      padding: 73px 17px 45px;
    }

    @include createQuery($sr_w_xs) {
      flex-direction: row;
      padding: 73px 32px 45px 36px;
    }

    @include printMaxWith;
  }

  &__wrap {
    position: relative;
    text-align: center;

    @include createQuery($sr_w_md) {
      max-width: 100%;
    }

    @include createQuery($sr_w_xs) {
      max-width: 100%;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 23px;
    font-size: 25px;
    font-weight: 900;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_white;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      margin-bottom: 13px;
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: 0.75px;
    }
  }

  &__btn {
    cursor: pointer;

    @include createQuery($sr_w_xs) {
      width: 41px;
      height: 41px;
    }
  }
}
</style>
