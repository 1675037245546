<template>
  <transition-group name="fade" :tag="tag ? tag : 'div'">
    <slot></slot>
  </transition-group>
</template>

<script>
export default {
  props: ['tag']
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
