<template>
  <div
    :class="`the-filter${className ? ` ${className}` : ''}${isResponsive ? ` the-filter--responsive` : ''}${fullWidth ? ` no-margin` : ''}`"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="the-filter__inner" :class="{'full-width': fullWidth}">
      <div class="the-filter__content">
        <smooth-scrollbar ref="scrollbar" class="js-filter-scroll">
          <ul class="the-filter__list" ref="list">
            <li
              v-for="(item, index) in filters"
              :key="index"
              class="the-filter__list-item"
              :class="{active: getIdOrReturnAny(item) === current}"
              @click="onClickFilter(item)"
            >
              <a
                class="the-filter__list-filter u-f-montserrat-semibold"
              >{{ getNameOrReturnAny(item) }}</a>
            </li>
          </ul>
        </smooth-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['filters', 'current', 'className', 'isResponsive', 'fullWidth'],
  methods: {
    getIdOrReturnAny(any) {
      if (any && any.id) return any.id;
      return any;
    },

    getNameOrReturnAny(any) {
      if (any && any.name) return any.name;
      return any;
    },

    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.list,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
    },
    onClickFilter(item) {
      this.$emit('filter', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.the-filter {
  $root: &;
  position: relative;
  margin-top: 86px;
  margin-bottom: 46px;

  &.no-margin {
    margin-top: 0;
  }

  @include createQuery($sr_w_xs) {
    margin-top: 57px;
    margin-bottom: 20px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    padding-left: 100px;
    padding-right: 100px;

    &.full-width {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__content {
    width: 100%;
    border-bottom: 1px solid $c_ship_gray;
  }

  &__list {
    display: flex;
    align-items: center;
    @include resetUL;
    padding-bottom: 11px;
    /*overflow-x: auto;*/
  }

  &__list-filter {
    font-size: 14px;
    letter-spacing: 0.64px;
    line-height: normal;
    text-transform: uppercase;
    color: $c_ship_gray;
    cursor: pointer;

    @include createQuery($sr_w_xs) {
      font-size: 10px;
    }

    /*&:hover {*/
    /*  font-weight: bold;*/
    /*}*/
  }

  &__list-item {
    padding: 0 10px;
    line-height: 1;
    white-space: nowrap;
    border-right: 1px solid $c_ship_gray;

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      border: none;
    }

    &.active {
      position: relative;

      #{$root}__list-filter {
        font-weight: bold;
      }

      &:after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -11px;
        left: 50%;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $c_ship_gray;
      }
    }
  }

  &--responsive {
    @include createQuery($sr_w_xs) {

      #{$root}__list-item {
        line-height: 13px;
      }

      #{$root}__list-filter {
        font-size: 10px;
        letter-spacing: 0.45px;
      }

    }
  }
}
</style>
