<template>
  <div
    class="profile"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="profile__inner">
      <div class="profile__photo">
        <ProfilePhoto :image="photoUrl" />
        <!-- <MediaCart class="profile__cart" /> -->
        <ProfileShare
          class="profile__share-mobile"
          :facebook="facebook"
          :twitter="twitter"
          :instagram="instagram"
          :linkedin="linkedin"
        />

      </div>
      <div class="profile__left-lines">
        <div class="profile__left-lines--horizontal">
        </div>
        <div class="profile__left-lines--vertical">
        </div>
      </div>
      <div class="profile__content">
        <aside class="profile__category u-f-opensans-semibold">
          {{ category }}
        </aside>
        <h1 class="profile__name u-f-montserrat-black">{{ name }}</h1>
        <p class="profile__position u-f-opensans-regular">{{ position }}</p>
        <ProfileShare
          class="profile__share"
          :facebook="facebook"
          :twitter="twitter"
          :instagram="instagram"
          :linkedin="linkedin"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from '../../elements/bio/ProfilePhoto';
import ProfileShare from '../../elements/bio/ProfileShare';
// import MediaCart from '../../elements/bio/MediaCart';
export default {
  props: ['profile'],
  components: {
    ProfilePhoto,
    ProfileShare,
    // MediaCart
  },
  computed: {
    photoUrl() {
      if (this.profile) {
        return this.profile.profile_image.url;
      }
      return null;
    },
    category() {
      if (this.profile) {
        return this.profile.category;
      }
      return '';
    },
    name() {
      if (this.profile) {
        return this.profile.name[0].text;
      }
      return '';
    },
    position() {
      if (this.profile) {
        return this.profile.job_title;
      }
      return '';
    },
    facebook() {
      if (this.profile) {
        return this.profile.facebook_link.url;
      }
      return null;
    },
    twitter() {
      if (this.profile) {
        return this.profile.twitter_link.url;
      }
      return null;
    },
    instagram() {
      if (this.profile) {
        return this.profile.instagram_link.url;
      }
      return null;
    },
    linkedin() {
      if (this.profile) {
        return this.profile.linkedin_link.url;
      }
      return null;
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  margin-top: 232px;
  margin-bottom: 54px;
  position: relative;
  background: $c_white;
  // overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 152px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    align-items: flex-start;
    padding-left: 100px;
    padding-right: 100px;

    @include createQuery($sr_w_not_sm) {
      flex-direction: row;
    }

    @include createQuery($sr_w_xs) {
      align-items: flex-start;
    }
  }

  &__photo {
    width: 100%;
    max-width: 284px;
    flex: 1;
    padding-top: 8px;

    @include createQuery($sr_w_sm) {
      display: flex;
      flex-direction: column;
      order: 2;
    }
  }

  &__figure {
    @include createQuery($sr_w_xs) {
      max-width: 120px;
      width: 100%;
    }
  }

  &__content {
    margin-top: 46px;
    padding-left: 58px;

    @include createQuery($sr_w_sm) {
      margin-top: 0;
      padding-left: 0;
      max-width: 200px;
      order: 1;
    }
  }

  &__share {
    @include createQuery($sr_w_sm) {
      display: none;
    }
  }

  &__share-mobile {
    display: none;

    @include createQuery($sr_w_sm) {
      margin-top: 25px;
      display: block;
    }
  }

  &__category {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3.75px;
    color: $c_remax_blue;

    @include createQuery($sr_w_xs) {
      font-size: 8px;
      letter-spacing: 2.5px;
    }
  }

  &__name {
    margin-top: 0;
    margin-bottom: 6px;
    max-width: 200px;
    font-size: 42px;
    text-transform: uppercase;
    line-height: normal;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      margin-top: 6px;
      font-size: 31px;
      line-height: 1.1;
    }
  }

  &__position {
    margin-top: 6px;
    margin-bottom: 47px;
    font-size: 18px;
    line-height: 1.44;
    color: $c_regent_gray;

    @include createQuery($sr_w_xs) {
      margin-bottom: 25px;
      font-size: 14px;
      line-height: 1.64;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 2.06;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1.85;
    }
  }

  &__left-lines {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;

    &--vertical {
      position: relative;
      width: 2px;
      height: 540px;
      background-color: $c_light_blue;
    }

    &--horizontal {
      position: absolute;
      right: -100px;
      top: 387px;
      height: 2px;
      width: 1000px;
      background-color: #a4d7f4;
    }

    @include createQuery($sr_w_md) {
      left: 40px
    }

    @include createQuery($sr_w_sm) {
      display: none;
    }
  }
}
</style>
