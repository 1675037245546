<template>
  <div class="join-remax">
    <div class="join-remax__inner">
      <div class="join-remax__container">
        <div class="join-remax__shadow">
        </div>
        <div class="join-remax__wrapper">
          <div class="join-remax__left">
            <h3 class="join-remax__title u-f-montserrat-black">{{ title ? title : 'Join RE/MAX' }}</h3>
            <div class="join-remax__description" v-html="copy"></div>
          </div>
          <div class="join-remax__right">
            <div class="join-remax__cta" v-for="(cta, index) in ctas" :key="index">
              <TheButton 
                :to="cta.cta_link" 
                :label="cta.cta_text ? cta.cta_text : 'Get Started'" 
                :animationDelay="1000" 
                color="green"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '../../elements/buttons/TheButton';
export default {
  components: {
    TheButton,
  },
  props: ['module'],

  computed: {
    remax_link() {
      return 'https://www.joinremax.com';
    },
    title() {
      if (this.module.primary && this.module.primary.headline) {
        return this.module.primary.headline
      }
      return ''
    },

    copy() {
      if (this.module.primary && this.module.primary.copy) {
        return this.$prismic.asHtml(this.module.primary.copy)
      }
      return 'Learn more about the <span class="u-nowrap">RE/MAX</span> network and Motto Mortgage and how you can be empowered to run your business your way.'
    },

    ctas() {
      if (this.module.items && this.module.items.length) {
        return this.module.items
      }
      return []
    }
  }
};
</script>

<style lang="scss" scoped>
.join-remax {
  $root: &;
  display: block;
  width: 100%;
  padding: 72px 0;

  @include createQuery($sr_w_md) {
    padding: 35px 0 47px;
  }

  &__inner {
    position: relative;
    @include maxWidth;

    @include createQuery($sr_w_md) {
      max-width: 940px;
      padding-left: 36px;
      padding-right: 20px;
    }

    @include createQuery($sr_w_not_md) {
      padding-left: 100px;
      padding-right: 200px;
    }

    @include createQuery($sr_w_xs) {
      // padding: 0 16px 0 32px;
    }

    #{$root}__shadow {
      display: block;
      position: absolute;
      left: -16px;
      top: -16px;
      width: 100%;
      height: 100%;
      border: 3px solid #d7d7d7;
      z-index: 95;

      @include createQuery($sr_w_md) {
        left: -16px;
        top: -16px;
      }
    }

    #{$root}__container {
      position: relative;
      width: 100%;
    }

    #{$root}__wrapper {
      position: relative;
      display: flex;
      padding: 34px 0 38px;
      background: $c_off_white;
      z-index: 96;

      @include createQuery($sr_w_sm) {
        flex-direction: column;
      }
    }

    #{$root}__left {
      flex: 1;
      max-width: 700px;
      padding-left: 100px;
      padding-right: 5%;

      @include createQuery($sr_w_sm) {
        margin-top: 20px;
        padding-left: 38px;
        padding-right: 38px;
      }
    }

    #{$root}__right {
      margin-top: 40px;
      flex: 1;
      max-width: 300px;
      padding-left: 38px;
      padding-right: 5%;
      

      @include createQuery($sr_w_sm) {
        margin-top: 20px;
        padding-left: 100px;
      }

      @include createQuery($sr_w_sm) {
        padding-left: 38px;
      }
    }
  }

  &__title {
    display: block;
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 25px;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      margin-bottom: 8px;
      font-size: 18px;
    }
  }

  &__description {
    @include createQuery($sr_w_xs) {
      font-size: 13px;
    }
  }

  &__cta {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
</style>
