<template>
  <main class="app__wrap">
    <AnimatedLoader/>
    <TheHeader />
    <smooth-scrollbar ref="scrollbar" class="js-main-content">
      <div class="page">
        <router-view/>
      </div>
      <TheFooter />
    </smooth-scrollbar>
    <portal-target name="modal" />
  </main>
</template>

<script>
import TheHeader from './components/common/TheHeader.vue';
import TheFooter from './components/common/TheFooter.vue';
import AnimatedLoader from "./components/common/AnimatedLoader";

export default {
  components: {
    AnimatedLoader,
    TheHeader,
    TheFooter,
  },
  created() {
    this.loadSettings();
  },

  methods: {
    async loadSettings() {
      await this.$store.dispatch('querySettings', { vm: this });
    },

    onResize() {
      this.$store.commit('onResize');
    },

    scrollToTop() {
      this.$refs.scrollbar.scrollbar.scrollTo(0, 0, 100);
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize);

    this.$store.commit('setMainContent', this.$refs.scrollbar.scrollbar);

    // listen to on scroll
    this.$refs.scrollbar.scrollbar.addListener((scrollbar) => {
      const event = new CustomEvent('remax_scroll', {detail: scrollbar});
      window.dispatchEvent(event);
    });

    window.addEventListener("remax_before_route", this.scrollToTop);
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('remax_before_route', this.scrollToTop)
  },
  
};
</script>


<style lang="scss">
.app__wrap {
  > .smooth-scrollbar {
    > .scrollbar-track {
      z-index: $z_index_overlay;
    }
  }
}

@media print {
  .app__wrap {
    .js-main-content {
      overflow: visible !important;

      .scroll-content {
        transform: none !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.app {
  &__wrap {
    height: 100%;
    width: 100%;
  }
}

.page {
  overflow: hidden;
  min-height: calc(100vh - 240px);
}
</style>

<style lang="scss">
html {
  height: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  background: $c_white;

  // set a default font style
  font-family: $f_opensans;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $c_ship_gray;
}

a {
  text-decoration: none;
}
</style>