<template>
  <TheLink
    :className="'menu-item ' + className"
    :to="item.link"
    :label="itemTitle"
    type="button"
    @click="onClick"
  />
</template>

<script>
import TheLink from '@/components/elements/buttons/TheLink';

export default {
  props: ['item', 'className'],

  components: {
    TheLink,
  },

  computed: {
    itemTitle() {
      return this.item.text
    },
  },

  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>

<style lang="scss">
.menu-item {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-color: $c_remax_blue;
    position: absolute;
    top: 39px;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform $t_normal;
  }

  &--active {
    color: $c_text_gray !important;

    &::after {
      transform: scaleX(1);
    }
  }
}
</style>