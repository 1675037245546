<template>
  <div
    class="page-section"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="page-section__image js-parallax-watch-parent">
      <div class="page-section__image-wrap" ref="imageWrap">
        <div class="page-section__image-bg" :style="imageBackground" ref="image"
             v-parallax="0.15"></div>
      </div>
      <div class="page-section__overlay"></div>
    </div>
    <img :src="image" class="page-section__image-print" />
    <div class="page-section__inner">
      <div class="page-section__wrap-right">
        <h2 class="page-section__title u-f-montserrat-black" ref="title">{{ title }}</h2>
        <div class="page-section__text" ref="text" v-html="description"></div>
        <div class="page-section__cta" v-if="ctaLink">
          <TheLink 
            :to="ctaLink"
            :label="ctaText ? ctaText : 'Read More'"
          />
        </div>
        <div v-if="stories && stories.length > 0" class="page-section__stories">
          <carousel :perPage="1" :perPageCustom="[[600, 3], [1265, 4]]">
            <slide v-for="(story, index) in stories" :key="index">
              <StorySmall
                :story="story"
                :showLocation="false"
                :showCategory="false"
                :whiteText="whiteText"
              />
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheLink from '../../elements/buttons/TheLink.vue';
import StorySmall from '../../elements/story/StorySmall';

export default {
  components: { TheLink, StorySmall },
  props: ['module'],

  computed: {
    ...mapGetters({
      getResponsiveBreakpoints: 'getResponsiveBreakpoints'
    }),
    isMobile() {
      return this.getResponsiveBreakpoints.xs
    },
    title() {
      if (this.module.primary && this.module.primary.headline) {
        return this.module.primary.headline
      }
      return ''
    },
    description() {
      if (this.module.primary && this.module.primary.copy) {
        return this.$prismic.asHtml(this.module.primary.copy)
      }
      return ''
    },
    ctaLink() {
      if (this.module.primary && this.module.primary.cta_link) {
        if (this.module.primary.cta_link.link_type && this.module.primary.cta_link.link_type === 'Any') return false
        return this.module.primary.cta_link
      }
      return ''
    },
    ctaText() {
      if (this.module.primary && this.module.primary.cta_text) {
        return this.module.primary.cta_text
      }
      return ''
    },
    image() {
      if (this.module.primary && this.module.primary.background && this.module.primary.background.url) {
        return this.module.primary.background.url
      }
      return ''
    },
    mobileImage() {
      if (this.module.primary && this.module.primary.mobile_background && this.module.primary.mobile_background.url) {
        return this.module.primary.mobile_background.url
      }
      return ''
    },
    imageBackground() {
      if (this.isMobile && this.mobileImage) {
        return `background-image: url(${this.mobileImage});`;  
      }
      return `background-image: url('${this.image}');`;
    },
    stories() {
      let arr = []
      if (this.module.items && this.module.items.length > 0) {
        this.module.items.forEach(item => {
          if (item.story_image && item.story_image.url && item.story_link && item.story_title)  {
            arr.push(item)
          }
        })
      }
      return arr
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.imageWrap,
        opacity: [0, 1],
        scale: [1, 1.05],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });

      // {"translateY":["-20px",0],"opacity":1}
      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.page-section {
  position: relative;
  background: $c_white;
  // overflow: hidden;
  margin-bottom: 38px;

  &__image {
    position: relative;
    height: 50vh;
    overflow: hidden;

    /*opacity: 0; // set in js*/

    @include hideInPrint;
  }

  &__image-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 0; // set in js
  }

  &__image-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top center;
  }

  &__image-print {
    display: none;

    @media print {
      display: block;
      height: 350px;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__overlay {
    @include overlay;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);

    @include hideInPrint;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    margin-top: -132px;
    padding-left: 100px;
    padding-right: 200px;

    @include createQuery($sr_w_md) {
      padding: 0 20px;
      max-width: 940px;
    }

    @include createQuery($sr_w_sm) {
      margin-top: -94px;
    }

    @include printMaxWith;

    @media print {
      margin-top: 0;
    }
  }

  &__wrap-right {
    position: relative;
    padding: 50px 45px 0 45px;
    background-color: $c_white;
    width: 100%;

    @include createQuery($sr_w_md) {
      padding-top: 20px;
      padding-left: 14px;
      padding-right: 14px;
    }

    @include createQuery($sr_w_xs) {
      width: 100%;
    }

    @media print {
      padding-left: 50px;
      padding-right: 0;
    }
  }

  &__content {
    position: relative;
  }

  &__date {
    margin-left: 2px;
    font-size: 14px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 12px;
      letter-spacing: 0.86px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 12px;
    margin-right: 100px;
    font-size: 25px;
    font-weight: 900;
    line-height: normal;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_md) {
      margin-bottom: 13px;
      font-size: 25px;
    }

    @include createQuery($sr_w_xs) {
      margin-right: 0;
      font-size: 18px;
      line-height: 22px;
    }

    @media print {
      margin-right: 0;
    }
  }

  &__text {
    margin-bottom: 33px;
    margin-right: 100px;

    @include createQuery($sr_w_md) {
      margin-right: 0;
    }
    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include defaultCopyStyles;

    @media print {
      margin-right: 0;
    }
  }

  &__stories {
    margin: 38px auto 0;

    @include createQuery($sr_w_xs) {
      width: calc(100% - 40px);
      max-width: 300px;
    }

    ::v-deep .VueCarousel {
      width: 100%;
    }

    ::v-deep .VueCarousel-pagination {
      .VueCarousel-dot-container {
        margin-top: 8px !important;

        .VueCarousel-dot {
          margin: 0 4px !important;
          padding: 0 !important;
          width: 8px !important;
          height: 8px !important;
          border: 1px solid $c_cobalt !important;
          background-color: transparent !important;

          &--active {
            background-color: $c_cobalt !important;
          }
        }
      }
    }
  }
}
</style>
