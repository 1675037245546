import _ from 'lodash';

export function isLoading(state) {
    return state.loading;
}

export function getData(state) {
    if (!state.data) return {}

    return state.data;
}

export function getPageData(state) {
    return state.pageData;
}

export function getPageTitle(state) {
    return state.pageTitle;
}

export function getArticle(state) {
    return state.article;
}

export function promotedArticles(state) {
    return state.promoted ? state.promoted : [];
}

export function articles(state) {
    return state.articles ? state.articles.results : [];
}

export function categories(state) {
    return state.categories ? state.categories : [];
}

export function getCategoryName(state) {
    return (uid) => {
        if (state.categories && uid) {
            return state.categories.find(cat => cat.uid === uid).data.name
        }
        return null
    }
}

export function articlesHasMore(state) {
    return state.articles ? state.articles.hasMore : false;
}

export function getSlices(state) {
    return state.slices;
}

export function getSettings(state) {
    if (!state.settings) return null

    return state.settings;
}

export function getHeaderMenu(state) {
    if (!state.headerMenu) return []

    return state.headerMenu;
}

export function getBioById(state) {
    return uid => {
        if (state.bios) {
            return _.find(state.bios, { uid });
        }
        return undefined;
    };
}

export function getBio(state) {
    if (!state.bio) return {}

    return state.bio;
}

export function getNumberOfComments (state) {
    return state.commentCount;
}

export function getTimeline(state) {
    if (!state.timeline) {
        return [];
    }
    return state.timeline.sort((a, b) => {
        const year1 = parseInt(a.data.decade);
        const year2 = parseInt(b.data.decade);
        return year1 - year2;
    })
}
  
export function getTimelineYears(state) {
    if (state.timeline && state.timeline.length > 0) {
        let years = [];
        state.timeline.forEach(decade => {
            years = [
            ...years,
            ...decade.data.body.map(year => parseInt(year.primary.year)),
            ];
        });
        return years.sort();
    }
    return [];
}
  
export function getTimelineDecades(state) {
    if (state.timeline && state.timeline.length > 0) {
        return state.timeline.map(data => parseInt(data.data.decade)).sort();
    }
    return [];
}

export function getKeyword(state) {
    return state.keyword ? state.keyword : false
}

export function searchArticleHasMore(state) {
    return state.searchArticles ? state.searchArticles.hasMore : false;
}
  
  export function searchArticles(state) {
    return state.searchArticles ? state.searchArticles.results : [];
}

export function getMainContent(state) {
    return state.mainContent;
}

export function getIsMobile(state) {
    if (!state.windowWidth) return false

    if (state.windowWidth <= 768) return true

    return false
}

export function getIsTablet(state) {
    if (!state.windowWidth) return false

    if (768 < state.windowWidth && state.windowWidth <= 1264) return true

    return false
}

export function getIsDesktop(state) {
    if (!state.windowWidth) return false

    if (1264 < state.windowWidth) return true

    return false
}

export function getResponsiveBreakpoints(state) {
    return {
        xs: state.windowWidth <= 600,
        sm: state.windowWidth <= 960,
        md: state.windowWidth <= 1264,
        lg: state.windowWidth <= 1904,
    };
}