<template>
  <div
    class="hero-banner"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="hero-banner__image js-parallax-watch-parent">
      <div class="hero-banner__image-wrap" ref="imageWrap">
        <div class="hero-banner__image-bg" :style="imageBackground" ref="image"
             v-parallax.onTop.watchParent="0.15"></div>
      </div>
    </div>
    <div class="hero-banner__overlay"></div>
    <img :src="image" class="hero-banner__image-print" />
    <div class="hero-banner__inner">
      <div class="hero-banner__wrap-right">
        <div class="hero-banner__category-wrap">
          <aside class="hero-banner__category u-f-opensans-semibold">{{ category }}</aside>
        </div>
        <span class="hero-banner__date u-f-bebas-neue-regular">{{ date }}</span>
        <h2 class="hero-banner__title u-f-montserrat-black" :class="{'small-font': useSmall}" ref="title">{{ title }}</h2>
        <p class="hero-banner__text" ref="text">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['title', 'description', 'date', 'image', 'mobileImage', 'category', 'useSmall'],

  computed: {
    ...mapGetters({
      getResponsiveBreakpoints: 'getResponsiveBreakpoints'
    }),
    isMobile() {
      return this.getResponsiveBreakpoints.xs
    },
    imageBackground() {
      if (this.isMobile && this.mobileImage) {
        return `background-image: url(${this.mobileImage});`;  
      }
      return `background-image: url('${this.image}');`;
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.imageWrap,
        opacity: [0, 1],
        scale: [1, 1.05],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });

      // {"translateY":["-20px",0],"opacity":1}
      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-banner {
  position: relative;
  background: $c_white;
  padding-top: 60px;
  // overflow: hidden;

  &__image {
    position: relative;
    height: calc(100vh - 85px);
    overflow: hidden;

    @media (orientation: portrait) {
      height: 60vh;
    }
    /*opacity: 0; // set in js*/

    @include hideInPrint;
  }

  &__image-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 0; // set in js
  }

  &__image-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top center;
  }

  &__image-print {
    display: none;

    @media print {
      display: block;
      height: 350px;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__overlay {
    @include overlay;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);

    @include hideInPrint;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    margin-top: -132px;
    padding-left: 100px;
    padding-right: 200px;

    @include createQuery($sr_w_md) {
      padding: 0 20px;
      max-width: 940px;
    }

    @include createQuery($sr_w_sm) {
      margin-top: -94px;
    }

    @include printMaxWith;

    @media print {
      margin-top: 0;
    }
  }

  &__wrap-right {
    position: relative;
    padding: 50px 45px 0 100px;
    background-color: $c_white;
    width: 100%;
    z-index: 1000;

    @include createQuery($sr_w_md) {
      padding-top: 20px;
      padding-left: 45px;
    }

    @include createQuery($sr_w_xs) {
      width: 100%;
    }

    @media print {
      padding-left: 50px;
      padding-right: 0;
    }
  }

  &__content {
    position: relative;
  }

  &__date {
    margin-left: 2px;
    font-size: 14px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 12px;
      letter-spacing: 0.86px;
    }
  }

  &__category-wrap {
    position: absolute;
    left: 0;
    top: 82px;
    width: 84px;

    @include createQuery($sr_w_md) {
      top: 54px;
      width: 22px;
      left: 12px;
    }

    @media print {
      left: -50px;
    }
  }

  &__category {
    float: right;
    height: 220px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 3.75px;
    text-align: right;
    text-transform: uppercase;
    text-orientation: mixed;
    writing-mode: tb;
    transform: rotate(-180deg);
    color: $c_remax_blue;

    @include createQuery($sr_w_md) {
      letter-spacing: 2.5px;
      font-size: 8px;
      height: 100px;
      float: none;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 12px;
    margin-right: 100px;
    font-size: 42px;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_md) {
      margin-bottom: 13px;
      font-size: 42px;
      min-height: 60px;
    }

    @include createQuery($sr_w_xs) {
      margin-right: 0;
      font-size: 31px;

      &.small-font {
        font-size: 28px;
      }
    }

    @media print {
      margin-right: 0;
    }
  }

  &__text {
    margin-bottom: 13px;
    @include defaultCopyStyles;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @media print {
      margin-right: 0;
    }
  }
}
</style>
