<template>
  <div
    :class="`search-box ${landing ? 'search-box--landing' : ''} ${className ? className : ''}`"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <form @submit.prevent="onSearch">
      <div class="search-box__inner" >
        <h3 class="search-box__title u-f-montserrat-black">
          {{ label }}
        </h3>
      </div>
      <div class="search-box__inner">
        <TextInput placeholder="What are you looking for?" v-model="keyword" />
        <TheLink className="search-box__submit link--red" label="search" @click="onSearch" />
      </div>
    </form>
  </div>
</template>

<script>
import TextInput from '../../elements/inputs/TextInput';
import TheLink from '../../elements/buttons/TheLink';
export default {
  props: ['label', 'value', 'landing', 'className'],
  components: {
    TextInput,
    TheLink
  },
  data() {
    return {
      keyword: ''
    };
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      // this.$anime.timeline().add({
      //   targets: this.$refs.category,
      //   opacity: [0, 1],
      //   translateY: ['10px', 0],
      //   easing: 'easeOutSine',
      //   delay: 600,
      //   duration: 600,
      // });
    },
    onSearch() {
      this.$router.push({
        name: 'search',
        params: {
          keyword: this.keyword
        }
      }).catch(()=>{});
      this.$emit('search', this.keyword);
    },
    onKeywordChange(val) {
      this.keyword = val;
    }
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  $root: &;
  margin-top: 109px;
  margin-bottom: 63px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  &--landing {
    margin-top: 0;
    margin-bottom: 0;

    #{$root}__inner {
      padding-left: 200px;
      padding-right: 200px;

      @include createQuery($sr_w_md) {
        padding: 0 17px;
      }
    }
  }

  @include createQuery($sr_w_md) {
    margin-bottom: 48px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    padding-left: 100px;
    padding-right: 100px;

    @include createQuery($sr_w_md) {
      padding: 0 17px;
    }

    @include createQuery($sr_w_md) {
      flex-direction: row;
    }

    .input {
      flex: 1;
    }
  }

  &__input-wrapper {
    @include createQuery($sr_w_md) {
      flex-direction: row;
    }
  }

  &__title {
    display: block;
    margin-top: 0;
    margin-bottom: 21px;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.75px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.58px;
    }
  }

  &__submit {
    display: block;
    margin-left: 16px;
    margin-right: 16px;
    padding: 16px 8px;

    @include createQuery($sr_w_md) {
      margin-right: 0;
      margin-left: 13px;
    }

    @include createQuery($sr_w_xs) {
      padding: 5px 0;
    }
  }
}
</style>
