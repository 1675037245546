<template>
  <div class="pagination">
    <span class="pagination__prev" @click="onClickPrev">
      <arrowLeft />
    </span>
    <div class="pagination__content u-f-opensans-regular" v-if="count < 7">
      <span
        v-for="(item, index) in [...pages(1, count + 1)]"
        :key="`index-${index}`"
        class="pagination__item"
        :class="{ active: item === page }"
        @click="onClickPaginate(item)"
      >
        {{ item }}
      </span>
    </div>
    <div class="pagination__content u-f-opensans-regular" @mouseleave="hideHidden" v-if="count > 6">
      <span
        v-for="(item, index) in [...pages(1, 4)]"
        :key="`prev_${index}`"
        class="pagination__item"
        :class="{ active: item === page }"
        @click="onClickPaginate(item)"
      >
        {{ item }}
      </span>
      <span class="pagination__item plus" @mouseover="showHidden" ref="plus">+</span>
      <div class="pagination__items-hidden" ref="hidden">
        <span
          v-for="(item, index) in [...pages(4, count - 3)]"
          :key="`middle_${index}`"
          class="pagination__item"
          :class="{ active: item === page }"
          @click="onClickPaginate(item)"
        >
          {{ item }}
        </span>
      </div>
      <span
        v-for="(item, index) in [...pages(count - 3, count)]"
        :key="`next_${index}`"
        class="pagination__item"
        :class="{ active: item === page }"
        @click="onClickPaginate(item)"
      >
        {{ item }}
      </span>
    </div>
    <span class="pagination__next" @click="onClickNext">
      <arrowRight />
    </span>
  </div>
</template>

<script>
import _ from 'lodash';
import arrowLeft from '@/assets/svg/arrow-left.svg';
import arrowRight from '@/assets/svg/arrow-right.svg';

export default {
  props: ['page', 'count'],
  components: {
    arrowLeft,
    arrowRight
  },
  methods: {
    onClickPaginate(item) {
      this.$emit('onPaginate', item);
    },
    onClickPrev() {
      if (this.page > 1) {
        this.$emit('onPaginate', this.page - 1);
      }
    },
    onClickNext() {
      if (this.page < this.count) {
        this.$emit('onPaginate', this.page + 1);
      }
    },
    pages(start, end) {
      return _.range(start, end);
    },
    showHidden() {
      this.$anime({
        targets: this.$refs.plus,
        maxWidth: ['40px', 0],
        easing: 'easeInSine',
        delay: 0,
        duration: 100,
      });
      this.$anime({
        targets: this.$refs.hidden,
        maxWidth: [0, '1000px'],
        easing: 'easeInSine',
        delay: 0,
        duration: 800,
      });
    },
    hideHidden() {
      this.$anime({
        targets: this.$refs.hidden,
        maxWidth: 0,
        easing: 'easeOutSine',
        delay: 0,
        duration: 800,
      });
      this.$anime({
        targets: this.$refs.plus,
        maxWidth: [0, 40],
        easing: 'easeOutSine',
        delay: 600,
        duration: 400,
      });
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.pagination {
  $root: &;

  &__prev {
    display: block;
    margin-right: 4px;
    cursor: pointer;
  }

  &__next {
    display: block;
    margin-left: 4px;
    cursor: pointer;
  }

  &__content {
    display: flex;

    #{$root}__items-hidden {
      display: flex;
      max-width: 0;
      overflow: hidden;
    }

    #{$root}__item {
      margin-left: 4px;
      margin-right: 4px;
      font-size: 12px;
      letter-spacing: 2.55px;
      color: $c_ship_gray;
      cursor: pointer;

      &.plus {
        display: inline;
        margin: 0;
        overflow: hidden;
      }

      &.hidden {
        width: 0;
      }

      &.active {
        font-weight: bold;
        cursor: default;
      }
    }
  }
}
</style>
