<template>
  <div
    class="the-title"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="the-title__content">
      <span class="the-title__category u-f-opensans-bold" ref="category">
        {{ category }}
      </span>
      <h3 class="the-title__title u-f-montserrat-black" ref="title">
        {{ headline }}
      </h3>
      <p class="the-title__text u-f-opensans-regular" ref="text">
        {{ subhead }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['category', 'headline', 'subhead'],
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.category,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 900,
        duration: 600,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.the-title {
  margin-bottom: 54px;
  position: relative;
  background: $c_white;
  // overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-bottom: 20px;
  }

  &__content {
    position: relative;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 100px;

    @include createQuery($sr_w_xs) {
      padding: 0;
    }
  }

  &__category {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: $c_remax-blue;

    @include createQuery($sr_w_xs) {
      font-size: 12px;
      letter-spacing: 3.75px;
    }
  }

  &__title {
    margin: 0;
    font-size: 58px;
    line-height: normal;
    text-transform: uppercase;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      font-size: 31px;
    }
  }

  &__text {
    margin-top: 18px;
    @include defaultCopyStyles;
    line-height: 1.5;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      margin-top: 9px;
      font-size: 13px;
      line-height: 1.38;
    }
  }
}
</style>
