<template>
  <div>
    <p>Loading the Prismic Preview...</p>
  </div>
</template>

<script>
export default {
  name: 'Preview',
  beforeCreate() {
    const event = new CustomEvent('remax_load_preview');
    window.dispatchEvent(event);

    const { token, documentId } = this.$route.query;

    this.$prismic.client
      .getPreviewResolver(token, documentId)
      .resolve(this.$prismic.linkResolver, '/')
      .then(url => window.location.replace(url));
  },
};
</script>
