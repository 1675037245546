<template>
    <div class="item">
        <h3 v-if="title" class="title">
            {{ title }}
        </h3>
        <div class="wrap" :class="{'vertical': imageLayout}">
            <div v-if="icon" class="icon">
                <img :src="icon" :alt="iconAlt" class="icon-img" v-if="!imageLayout">
                <img :src="icon" :alt="iconAlt" class="image" v-else>
            </div>
            <p v-if="copy" class="copy">
                {{ copy }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item'],

    computed: {
        title() {
            if (this.item) {
                return this.item.title1
            }
            return ''
        },

        icon() {
            if (this.item) {
                return this.item.icon ? this.item.icon.url : ''
            }
            return ''
        },
        iconAlt() {
            if (this.item) {
                return this.item.icon ? this.item.icon.alt : ''
            }
            return ''
        },

        imageLayout() {
            if (this.item) {
                return this.item.image1
            }
            return false
        },

        copy() {
            if (this.item) {
                return this.$prismic.richTextAsPlain(this.item.copy)
            }
            return ''
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    margin-bottom: 20px;
}
.title {
    margin-top: 45px;
    margin-bottom: 20px;
    @include montserratSemi23;

    @include createQuery($sr_w_sm) {
        @include montserratBlack14;
        margin-top: 18px;
    }
}
.wrap {
    display: flex;

    .copy {
        align-self: center;
    }

    &.vertical {
        flex-direction: column;
    }
}
.icon-img {
    width: 75px;
    height: auto;
    margin-right: 16px;

    @include createQuery($sr_w_sm) {
        width: 65px;
    }
}
.image {
    max-width: 100%;
}
.copy {
    @include defaultCopyStyles;
}
</style>