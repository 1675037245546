<template>
    <div class="blurb">
        <div class="blurb__inner">
            <div v-if="image" class="blurb__image-wrap">
                <img :src="image" :alt="imageAlt">
            </div>
            <h3 v-if="title" class="blurb__title u-f-montserrat-black">{{ title }}</h3>
            <p v-if="copy" class="blurb__copy">{{ copy }}</p>
            <div v-if="ctaLink" class="blurb__cta">
                <TheLink :to="ctaLink" :label="ctaText" className="link--blue" />
            </div>
        </div>
    </div>
</template>

<script>
import TheLink from '@/components/elements/buttons/TheLink'

export default {
    components: {
        TheLink,
    },

    props: ['module'],

    computed: {
        title() {
            if (this.module && this.module.primary) {
                return this.module.primary.title1
            }
            return ''
        },

        image() {
            if (this.module && this.module.primary) {
                return this.module.primary.image1 ? this.module.primary.image1.url : ''
            }
            return ''
        },
        imageAlt() {
            if (this.module && this.module.primary) {
                return this.module.primary.image1 ? this.module.primary.image1.alt : ''
            }
            return ''
        },

        ctaLink() {
            if (this.module && this.module.primary) {
                return this.module.primary.cta_link
            }
            return ''
        },

        ctaText() {
            if (this.module && this.module.primary) {
                return this.module.primary.cta_text
            }
            return 'Read More'
        },

        copy() {
            if (this.module && this.module.primary) {
                return this.$prismic.richTextAsPlain(this.module.primary.copy)
            }
            return ''
        }
    }
}
</script>

<style lang="scss">
.blurb {
  margin-top: 53px;
  margin-bottom: 80px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 22px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    flex-direction: column;
    @include inner;

    @include printMaxWith;
  }

  &__title {
    margin: 0;
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.78px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 17px;
      line-height: 1.29;
      letter-spacing: 0.53px;
    }
  }

  &__image-wrap {
    margin-bottom: 30px;

    @include createQuery($sr_w_xs) {
        max-width: 171px;

        img {
            width: 100%;
            height: auto;
        }
    }
  }

  &__copy {
    @include defaultCopyStyles;
    margin-bottom: 30px;
  }
}
</style>