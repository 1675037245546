import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions.js';
import * as mutations from './mutations.js';
import * as getters from './getters.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loading: false,
        pageData: {},
        slices: [],
        pageTitle: '',
        article: [],
        promoted: [],
        articles: [],
        commentCount: 0,
        categories: [],
        settings: {},
        headerMenu: [],
        bios: [],
        bio: {},
        data: {},
        timeline: [],
        mainContent: null,
        searchArticles: [],

        windowWidth: window.innerWidth,
    },
    mutations,
    actions,
    getters,
    modules: {}
});
