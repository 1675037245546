import _ from 'lodash';
const jsonpAdapter = require('axios-jsonp');
import axios from 'axios';

export async function queryPage(store, data) {

    const vm = data.vm;
    const uid = data.uid;

    store.commit('setLoading', true);
    const response = await vm.$prismic.client.getByUID('basic_page', uid);

    if (!response) {
        store.commit('setLoading', false);
        throw new Error('Page not found');
    }

    if (response.data) {
      const document = response.data;
      const article = document.body;
      store.commit('setPageData', _.omit(document, 'body'));
      store.commit('setArticle', article);
    }

    store.commit('setLoading', false);
}

export async function queryLandingPage(store, { vm }) {
    const response = await vm.$prismic.client.getSingle('landing_page');
    console.log('LANDING PAGE: ', response)

    if (response && response.data) {
        let doc = response.data;
        store.commit('setPageData', _.omit(doc, 'body'));
        store.commit('setSlices', doc.body);
    }
} 

export async function queryBios(store, { vm }) {
    const response = await vm.$prismic.client.query(
        vm.$prismic.Predicates.at('document.type', 'bio'),
        { pageSize: 100 }
    );

    console.log('BIOS: ', response)

    if (response && response.results) {
        let results = response.results ?? [];
        store.commit('setBios', results);
    }
} 

export async function querySingleBio(store, { uid, vm }) {
    store.commit('setLoading', true);
    const response = await vm.$prismic.client.getByUID('bio', uid);
  
    // throw 404 if cat not exists
    if (!response) {
      store.dispatch('throw404Error');
      return;
    }
  
    // // set prismic page id to enable the admin edit button
    // if (response.id) store.dispatch('setPagePrismicId', response.id);
  
    // // set prismic page title
    if (response.data && response.data.name && response.data.name[0])
      store.dispatch('setPageTitle', response.data.name[0].text);
  
    // store.dispatch('buildMediaCartList', response);
  
    if (response.data) {
      const document = response.data;
      const bio = document.body;
  
      store.commit('data', _.omit(document, 'body'));
      store.commit('setBio', bio);
    }
    store.commit('setLoading', false);
}

export async function querySingleArticle(store, { uid, vm }) {
    store.commit('setLoading', true);

    const response = await vm.$prismic.client.getByUID('news_article', uid, {
      fetchLinks: 'topic.title',
    });

    // throw 404 if article not exists
    if (!response) {
      store.dispatch('throw404Error');
      return;
    }
  
    // set prismic page id to enable the admin edit button
    // if (response.id) store.dispatch('setPagePrismicId', response.id);
  
    // set prismic page title
    if (response.data && response.data.title && response.data.title[0])
      store.dispatch('setPageTitle', response.data.title[0].text);
  
    // store.dispatch('buildMediaCartList', response);
  
    // reset comment count
    // store.commit('setCommentCount', 0);
  
    if (response.data) {
      const document = response.data;
      const article = document.body;
  
      store.commit('data', _.omit(document, 'body'));
      store.commit('setArticle', article);
    }
    store.commit('setLoading', false);
}

export async function queryLeadershipPage(store, { vm }) {
    store.commit('setLoading', true);

    const response = await vm.$prismic.client.getSingle('leadership');
    console.log('LEADERSHIP PAGE: ', response)

    if (response && response.data) {
        let doc = response.data;
        store.commit('setPageData', _.omit(doc, 'body'));
        store.commit('setSlices', doc.body);
    }

    store.commit('setLoading', false);
} 

export async function querySettings(store, { vm }) {
  const response = await vm.$prismic.client.getSingle('settings');
  console.log('SETTINGS: ', response)

  if (response && response.data) {
    store.commit('setSettings', response.data)
  }
    
  store.dispatch('constructMenu');
  store.dispatch('setFavicon');
}

export function setFavicon({state}) {
  const favicon = state.settings.favicon ? state.settings.favicon.url : false
  if (favicon) {
    document.getElementById('favicon').href = favicon
  }
}

export async function constructMenu({commit, state}) {
    let items = state.settings.body2;
    
    if (!items || items.length === 0) {
        console.error('Menu is empty');
        return 
    }

    if (items[0].slice_type != 'top_level_item') {
        console.error('First menu item must be top_level_item');
        return
    }

    let index = 0;
    let currentTopLevelItem;
    let currentLevelOneItem;
    let menu = [];

    while (index < items.length) {
        let srcItem = items[index];
        
        let menuItem = {
            text: srcItem.primary.link_text,
            link: srcItem.primary.link,
            sublinks: []
        }

        if (srcItem.slice_type === 'top_level_item') {
            menu.push(menuItem);
            currentTopLevelItem = menuItem;
        }
        if (srcItem.slice_type === 'level_1_item') {
            currentTopLevelItem.sublinks.push(menuItem);
            currentLevelOneItem = menuItem;
        }
        if (srcItem.slice_type === 'level_2_item') {
            if (srcItem.primary.link.link_type === 'Any') {
                menuItem.sublinks = srcItem.items;
            }
            currentLevelOneItem.sublinks.push(menuItem);
        }

        index++;
    }

    commit('setHeaderMenu', menu);
    console.log('HEADER MENU: ', menu);
}

export function throw404Error(store) {
    console.warn('404 ERROR');
    window.RMXContext.$router.push('/not-found');
    store.commit('setLoading', false);
}

export function setPageTitle(store, title) {
    store.commit('setPageTitle', title);
}

export async function queryNewsPage(store, { vm }) {
    store.commit('setLoading', true);
    const response = await vm.$prismic.client.getSingle('news_page');
    console.log('NEWS PAGE: ', response)

    if (response && response.data) {
        let doc = response.data;
        store.commit('setPageData', _.omit(doc, 'body'));
        if (doc.body) {
          store.commit('setSlices', doc.body);
        }
    }
    console.log('[queryNewsPage] DONE')
}

export async function queryPromotedNewsArticles({commit, state}, { vm }) {
  let ids = []

  if (state.pageData && state.pageData) {
    if (state.pageData.featured_story_right) {
      ids.push(state.pageData.featured_story_right.id)
    }
    if (state.pageData.featured_story_left) {
      ids.push(state.pageData.featured_story_left.id)
    }
    if (state.pageData.featured_story_bottom) {
      ids.push(state.pageData.featured_story_bottom.id)
    } 

    const response = await vm.$prismic.client.getByIDs(ids);

    if (response.results) {
      commit('articlesPromoted', response.results);
    } else {
      commit('articlesPromoted', []);
    }
  } 
  commit('setLoading', false);
}

export async function queryNewsArticles(store, { vm, pageSize, page }) {

    // let excludeIDs = [];
    // // ensure that queryArticleByTopic is run after slices are loaded
    // if (excludeDefaults) {
    //   // exclude story banner link
    //   if (store.getters.slices && Array.isArray(store.getters.slices)) {
    //     store.getters.slices.map(slice => {
    //       if (slice.slice_type === 'story_banner' && slice.primary && slice.primary.link && slice.primary.link.uid) {
    //         excludeIDs.push(slice.primary.link.id);
    //       }
    //     });
    //   }
  
    //   // exclude promoted articles
    //   if (store.getters.topicArticlesPromoted && Array.isArray(store.getters.topicArticlesPromoted)) {
    //     store.getters.topicArticlesPromoted.map(article => {
    //       if (article && article.uid) {
    //         excludeIDs.push(article.id);
    //       }
    //     })
    //   }
    // }
  
    // // create predicates for [excludeIDs]
    // const extraPredicates = [];
    // if (excludeIDs.length > 0) {
    //   for (let i = 0; i < excludeIDs.length; i++) {
    //     extraPredicates.push(vm.$prismic.Predicates.not('document.id', excludeIDs[i]));
    //   }
    // }
  
    const response = await vm.$prismic.client.query(
      [
        vm.$prismic.Predicates.at('document.type', 'news_article'),
        // vm.$prismic.Predicates.not('document.tags', ['Promoted']),
        // vm.$prismic.Predicates.at('my.news_article.topics.topic', id),
        // ...extraPredicates
      ],
      {
        orderings: '[document.first_publication_date desc]',
        pageSize,
        page,
      }
    );
    if (response.results) {
      const { results, page, next_page, total_pages } = response;
  
      store.commit('articles', { results, page, total_pages, next_page });
    }
}

export async function queryNewsCategories(store, { vm }) {
  const response = await vm.$prismic.client.query(
    [
      vm.$prismic.Predicates.at('document.type', 'news_article_category'),
    ],
    {
      orderings: '[document.first_publication_date desc]',
    }
  );

  if (response.results) {
    console.log('CATEGORIES: ', response.results);
    store.commit('setCategories', response.results);
  }
}

export function setCommentCount (store) {
  axios({
    url: 'https://disqus.com/api/3.0/threads/set.jsonp',
    adapter: jsonpAdapter,
    params: {
      forum: 'remaxnews',
      api_key: 'If2pnRaEYyZeR8CG8uLmEmsi7VNI89Laee3D8NcXMwzkqhFC9wzmr6sDOJeXYr8W',
      thread: [`link:${window.location.href}`],
    }
  }).then((response) => {
    console.warn('disqus comments', response);
    if (response && response.data && response.data.response && response.data.response[0] && response.data.response[0].posts) {
      store.commit('setCommentCount', response.data.response[0].posts);
    } else {
      store.commit('setCommentCount', 0);
    }
  });
}

export async function queryTimeline(store, { vm }) {
  store.commit('setLoading', true);

  const response = await vm.$prismic.client.query(
    vm.$prismic.Predicates.at('document.type', 'timeline')
  );
  if (response.results) {
    const results = response.results;
    console.log('[QUERY TIMELINE]: ', results)
    store.commit('timelines', results);
  }

  store.commit('setLoading', false);
}

export async function queryArticleBySearch(store, { keyword, pageSize, page, vm }) {
  let filter = [
    vm.$prismic.Predicates.any('document.type', ['news_article', 'basic_page']),
    vm.$prismic.Predicates.not('my.news_article.exclude_from_search', 'Yes'),
    vm.$prismic.Predicates.fulltext('document', keyword),
  ];
  if (keyword === 'Editor\'s Pick') {
    filter = [
      vm.$prismic.Predicates.at('document.type', 'news_article'),
      vm.$prismic.Predicates.at('document.tags', ['Editors Picks'])
    ];
  }
  const response = await vm.$prismic.client.query( filter,
    {
      fetchLinks: 'topic.title',
      orderings: '[document.last_publication_date desc]',
      pageSize,
      page,
    }
  );

  if (response.results) {
    const { results, page, next_page, total_pages } = response;

    store.commit('articlesBySearch', {
      keyword,
      results,
      page,
      total_pages,
      next_page,
    });
  }
}