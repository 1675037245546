<template>
  <div class="resources-links">
    <div class="resources-links__title u-f-montserrat-black">Resources</div>

    <template v-if="getResources && getResources.length">
      <TheLink className="resources-links__item"
               :unstyled="true"
               v-for="(resource, resourceIndex) in getResources"
               :key="resourceIndex"
               :to="resource.link">
        <img :src="resource.image"
             :alt="`${resource.title} icon`">
        <span class="resources-links__item-title">{{resource.title}}</span>
      </TheLink>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import TheLink from "../../elements/buttons/TheLink";

export default {
  components: {
    TheLink,
  },
  computed: {
    ...mapGetters({
      getByModule: 'page/getByModule',
    }),
    getResources () {
      if (this.getByModule('resources')) {
        return this.getByModule('resources').map(item => ({
          title: item.headline[0].text,
          image: item.image.url || '',
          link: item.link || '',
        }));
      }
      return null;
    },
  }
}
</script>

<style lang="scss">
.resources-links {
  $root: &;

  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  @include createQuery($sr_w_md) {
    flex-wrap: wrap;
    align-items: flex-end;
  }

  &__title {
    flex: 1;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.75px;
    color: $c_ship_gray;
    margin-left: 0;
    margin-right: auto;

    @include createQuery($sr_w_md) {
      flex: 100%;
      margin-bottom: 30px;
    }

    @include createQuery($sr_w_xs) {
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.58px;
      margin-bottom: 13px;
      margin-left: 36px;
    }
  }

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    text-decoration: none;

    @include createQuery($sr_w_md) {
      flex-direction: column;
    }

    img {
      display: inline-block;

      @include createQuery($sr_w_xs) {
        width: 40px;
        height: auto;
      }
    }

    &:hover {
      #{$root}__item-title {
        color: $c_alizarin_crimson;
      }
    }
  }

  &__item-title {
    font-size: 16px;
    line-height: 1.69;
    letter-spacing: 0.5px;
    color: $c_heavy_gray;
    margin-left: 27px;

    transition: color $t_normal;

    @include createQuery($sr_w_md) {
      margin-left: 0;
      margin-top: 13px;
    }

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 2.08;
      letter-spacing: 0.41px;
      margin-top: 9px;
    }
  }
}
</style>
