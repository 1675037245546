<template>
  <div
    class="bottom-stories"
  >
    <div class="bottom-stories__inner">
      <StoryInline
        v-for="(story, index) in stories"
        :key="index"
        :image="getStory(story).image"
        :title="getStory(story).title"
        :description="getStory(story).description"
        :ctaText="getStory(story).ctaText"
        :ctaLink="getStory(story).ctaLink"
      />
    </div>
  </div>
</template>

<script>
import StoryInline from '../../elements/story/StoryInline';
export default {
  // props: ['module', 'stories', 'hasMore'],
  props: ['module'],
  components: {
    StoryInline
  },
  computed: {
    stories() {
      if (this.module.items) {
        return this.module.items
      }
      return []
    }
  },
  methods: {
    getStory(story) {
      if (story) {
        return {
          image: story.image1 ? story.image1.url : '',
          title: story.headline ? story.headline : '',
          description: story.copy ? story.copy : '',
          category: '',
          ctaText: story.cta_text || '',
          ctaLink: story.cta_link,
        }
      }
      return null;
    }
  }
}
</script>

<style lang="scss">
.bottom-stories {
  padding-bottom: 109px;

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;
    flex-direction: column;
  }

  .story-inline {
    $root: &;
    position: relative;
    margin-top: 86px;

    @include createQuery($sr_w_md) {
      margin-top: 34px;
    }

    &__title {
      margin-bottom: 6px;
    }

    &__link {
      @include createQuery($sr_w_xs) {
        flex-direction: column;
        margin-top: 15px;
      }

      // link button
      .story-inline__link {
        margin-top: 50px;
        @include createQuery($sr_w_xs) {
          margin-top: 15px;
        }
      }
    }

    &__figure--primary {
      flex: 1;
      max-width: 400px;
      padding-right: 16px;

      @include createQuery($sr_w_xs) {
        padding-left: 36px;
        padding-right: 42px;
      }
    }
 
    &__content {
      position: relative;
      flex: 1;
      max-width: 600px;
      padding-left: 50px;

      .story__category {
        left: 32px;
        font-size: 12px !important;
        // color: $c_alizarin_crimson !important;
        letter-spacing: 3.75px !important;

        @include createQuery($sr_w_md) {
          // left: 0;
        }

        @include createQuery($sr_w_xs) {
          max-height: 80px;
          left: 0;
          top: 0;
          font-size: 8px !important;
          letter-spacing: 2.5px !important;
        }
      }

      @include createQuery($sr_w_md) {
        padding-right: 16px;
      }

      @include createQuery($sr_w_xs) {
        margin-top: 23px;
        padding-left: 36px;
        padding-right: 42px;
      }
    }
  
    &__figure {
      &--primary {
        position: relative;
        width: 100%;

        .story__figure-content {
          padding-bottom: 54.79%;
        }
      }

      .story__image-shadow {
        position: absolute;
        left: -8px;
        top: -8px;
        width: 100%;
        height: 100%;
        background-color: $c_alizarin_crimson;
        z-index: -1;

        @include createQuery($sr_w_md) {
          left: -4px;
          top: -4px;
        }
      }
    }
  }
}
</style>
