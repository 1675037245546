<template>
  <div class="instagram__post">
    <a :href="url" target="_blank">
      <figure class="instagram__figure">
        <div class="instagram__figure-content">
          <img
            :src="image"
            :alt="alt"
            class="instagram__image"
            ref="image"
          />
        </div>
      </figure>
    </a>
  </div>
</template>

<script>
export default {
  props: ['post'],
  computed: {
    image() {
      if (this.post) {
        return this.post.image;
      }
      return '';
    },
    url() {
      if (this.post) {
        return this.post.url;
      }
      return '';
    },
    alt() {
      if (this.post) {
        return this.post.text;
      }
      return '';
    }
    // hasVideo() {
    //   if (this.post && this.post.video) {
    //     return true;
    //   }
    //   return false;
    // },
    // hasGallery() {
    //   if (this.post && this.post.images.length > 0) {
    //     return true;
    //   }
    //   return false;
    // }
  }
};
</script>

<style lang="scss">
.instagram {
  &__post {
    position: relative;
    flex-basis: 20%;

    @include createQuery($sr_w_md) {
      flex-basis: 25%;

      &:nth-child(1n + 5) {
        display: none;
      }      
    }

    @include createQuery($sr_w_xs) {
      flex-basis: 50%;
    }
  }

  &__figure {
    position: relative;
    margin: 0;
    display: block;
  }

  &__figure-content {
    position: relative;
    padding-bottom: 100%;
  }

  &__image {
    display: block;
    @include overlay;
    object-fit: cover;
  }
}
</style>
