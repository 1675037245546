<template>
    <div class="grid">
        <div class="inner">
            <template v-if="!getIsMobile">
                <div class="item" v-for="(item, index) in items" :key="index">
                    <div class="image-wrap">
                        <img :src="item.image1.url" :alt="item.image1.alt" class="image">
                    </div>
                    <div class="title">
                        <h3 class="u-f-montserrat-black">{{ item.title1 }}</h3>
                    </div>
                </div>
            </template>
            <template v-else>
                <carousel :perPage="1">
                    <slide v-for="(item, index) in items" :key="index">
                        <div class="item">
                            <div class="image-wrap">
                                <img :src="item.image1.url" :alt="item.image1.alt" class="image">
                            </div>
                            <div class="title">
                                <h3 class="u-f-montserrat-black">{{ item.title1 }}</h3>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: ['module'],

    computed: {
        ...mapGetters(['getIsMobile']),
        items() {
            return this.module.items ? this.module.items : []
        }
    }
}
</script>

<style lang="scss" scoped>
.grid {
    margin-top: 50px;
}
.inner {
    @include maxWidth;
    @include inner;
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-around;

    @include createQuery($sr_w_sm) {
        padding: 0;
    }

    @include createQuery($sr_w_sm) {
        flex-direction: column !important;
    }
}

.item {
    width: 45%;
    text-align: center;

    @include createQuery($sr_w_sm) {
        width: 100%;
    }
}

.image {
    width: 100%;
    max-width: 250px;

    @include createQuery($sr_w_sm) {
        object-fit: contain;
        object-position: center;
    }
}

.title {
    margin: 10px 0 80px;
}

.VueCarousel .title {
    margin-bottom: 10px;
}

::v-deep .VueCarousel {
    width: 100%;
    margin-bottom: 40px;
}

::v-deep .VueCarousel-pagination {
    .VueCarousel-dot-container {
        margin-top: 8px !important;

        .VueCarousel-dot {
            margin: 0 4px !important;
            padding: 0 !important;
            width: 8px !important;
            height: 8px !important;
            border: 1px solid $c_cobalt !important;
            background-color: transparent !important;

            &--active {
                background-color: $c_cobalt !important;
            }
        }
    }
}

</style>

<style lang="scss">
.grid .VueCarousel-dot-container {
    margin-top: 0 !important;
}
</style>