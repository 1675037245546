<template>
  <header 
    class="header"
    :class="{
      'header--undocked': isScrolled,
    }"
  >
    <aside class="header__info u-f-montserrat-semibold">
      <div class="header__info-text">Looking for a home? Search for properties on <a target="_blank" href="https://www.remax.com/">remax.com</a></div>
    </aside>
    <div class="inner">
      <div class="left">
        <div class="logo">
          <router-link to="/">
            <remaxLogo />
          </router-link>
        </div>
      </div>
      <div class="hamburger" v-if="getIsMobile">
        <HamburgerMenu @click="toggleMobileMenu" :isActive="isMobileMenuOpen" />
        <MobileMenu :isOpen="isMobileMenuOpen" @requestClose="closeMobileMenu" />
      </div>
      <div class="nav" v-else>
        <DesktopMenu />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import remaxLogo from '@/assets/svg/remax-holdings-logo-navy.svg';
import HamburgerMenu from '@/components/elements/header/HamburgerMenu';
import DesktopMenu from '@/components/blocks/Header/DesktopMenu';
import MobileMenu from '@/components/blocks/Header/MobileMenu';

export default {
  components: {
    remaxLogo,
    HamburgerMenu,
    DesktopMenu,
    MobileMenu
  },

  data() {
    return {
      isScrolled: false,
      windowTop: window.scrollY,
      isMobileMenuOpen: false,
    }
  },

  computed: {
    ...mapGetters([
      'getIsMobile',
      'getPageTitle',
    ]),

    menuItems() {
      if (this.getSettings && this.getSettings.body) {
        return this.getSettings.body;
      }
      return [];
    }
  },

  head: {
    title: function () {
      return {
        inner: this.getPageTitle
      }
    },
  },

  watch: {
    windowTop: function (val) {
      if (val === 0 && this.isScrolled) {
        this.isScrolled = false;
      } else if (!this.isScrolled) {
        this.isScrolled = true;
      }
    },

    getPageTitle() {
      this.$emit('updateHead');
    }
  },

  methods: {
    onScroll (e) {
      this.windowTop = e.detail.offset.y;
    },

    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },

    closeMobileMenu() {
      if (this.isMobileMenuOpen) {
        this.isMobileMenuOpen = false
      }
    }
  },

  mounted () {
    window.addEventListener("remax_scroll", this.onScroll);
  },
  beforeDestroy () {
    window.removeEventListener("remax_scroll", this.onScroll);
  },
}
</script>

<style lang="scss" scoped>
.header {
  $root: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z_index_header;
  // overflow: hidden;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  &__info {
    position: relative;
    z-index: $z_index_top;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: center;
    color: $c_white;
    background: $c_biscay;
    height: 46px;

    transition: margin $t_header;

    @include createQuery($sr_w_xs) {
      font-size: 8px;
    }

    @at-root #{$root}--undocked & {
      margin-top: -46px;
    }
    // overrides --undocked
    @at-root #{$root}--menu & {
      margin-top: 0;
    }

    a {
      color: white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--undocked {
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

    // .inner {
    //   background-color: $c_white;
    // }
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  background-color: $c_white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;

  @include createQuery($sr_w_not_sm) {
    padding: 16px 42px;
  }
}
</style>
