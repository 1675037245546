<template>
  <div
    class="category_resources"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="category_resources__inner">
      <ResourcesLinks class="category_resources__content" ref="resource"/>
    </div>
  </div>
</template>

<script>
import ResourcesLinks from '../common/ResourcesLinks';

export default {
  components: {
    ResourcesLinks
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.resource,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.category_resources {
  margin-top: 82px;
  margin-bottom: 81px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
  }

  &__content {
    opacity: 1;
  }
}
</style>
