<template>
  <article class="story story--default" :class="className" ref="story">
    <TheLink :unstyled="true" :to="ctaLink" className="story__link-wrap">
      <span v-if="country" class="story__location-tag u-f-opensans-regular">
        {{ country }}
      </span>
      <div class="story__figures">
        <StoryImage
          v-parallax="!noeffect && !!secondaryImage && !getIsMobile ? 0.1 : 0"
          key="img1"
          :image="primaryImage"
          className="story__figure--primary"
          :intersection="getImageIntersection"
          :animateShadow="!noeffect && !secondaryImage"
          :border="className === 'story-two-bottom'"
        />
        <StoryImage
          key="img2"
          v-if="secondaryImage"
          :image="secondaryImage"
          :intersection="getImageIntersection"
          className="story__figure--secondary"
        />
      </div>
      <div class="story__header" v-parallax="!noeffect && !getIsMobile ? 0.05 : 0">
        <StoryCategory :label="category" />
        <h2 class="story__title u-f-montserrat-black">{{ title }}</h2>
        <p class="story__text u-f-opensans-regular">{{ description }}</p>
        <TheButton 
          v-if="buttonStyleCta"
          color="navy"
          :to="ctaLink"
          :label="ctaText"
        />
        <TheLink 
          v-else
          className="story__link" 
          label="learn more" 
        />
      </div>
    </TheLink>
  </article>
</template>

<script>
import StoryImage from './StoryImage';
import TheLink from '../buttons/TheLink';
import TheButton from '../buttons/TheButton';
import StoryCategory from './StoryCategory';
import { mapGetters } from 'vuex';
export default {
  name: 'Story',
  props: [
    'className',
    'category',
    'primaryImage',
    'secondaryImage',
    'title',
    'description',
    'ctaText',
    'ctaLink',
    'country',
    'noeffect',
    'buttonStyleCta'
  ],
  computed:{
    ...mapGetters([
      'getIsMobile'
    ]),
    getImageIntersection () {
      return {
        root: this.$refs.story,
        rootMargin: '300px',
        threshold: 0.3
      }
    },
  },
  components: {
    StoryImage,
    TheLink,
    StoryCategory,
    TheButton,
  },
};
</script>

<style lang="scss">
.story {
  position: relative;
  width: 100%;

  &__link-wrap {
    -webkit-appearance: none;
    text-decoration: none;
    text-align: left;
    background: transparent;
    border: 0;
    outline: 0;
  }

  &__location-tag {
    position: absolute;
    top: 36px;
    right: -16px;
    padding: 5px 26px 4px 26px;
    font-size: 10px;
    letter-spacing: 3.13px;
    text-transform: uppercase;
    background-color: $c_cobalt;
    color: $c_white;
    z-index: 99;

    @include createQuery($sr_w_xs) {
      right: -12px;
      top: 14px;
      padding: 5px 16px 4px 16px;
      font-size: 8px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 25px;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      margin-bottom: 7px;
      font-size: 18px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.5;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      margin-top: 7px;
      font-size: 13px;
    }
  }
  &__link {
    margin-top: 32px;
    padding: 0;

    @include createQuery($sr_w_xs) {
      margin-top: 11px;
    }
  }
  &__figures {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

}
</style>
