<template>
  <div
    class="story-bottom"
  >
    <div class="story-bottom__inner" :class="{'story-bottom__inner--all-loaded': !hasMore}">
      <StoryInline
        v-for="(story, index) in stories"
        :key="index"
        :category="getStory(story).category"
        :image="getStory(story).image"
        :title="getStory(story).title"
        :description="getStory(story).description"
        :ctaText="getStory(story).ctaText"
        :ctaLink="getStory(story).ctaLink"
      />
    </div>
  </div>
</template>

<script>
import StoryInline from '../../elements/story/StoryInline';
export default {
  props: ['stories', 'hasMore'],
  components: {
    StoryInline
  },
  methods: {
    getStory(story) {
      if (story) {
        return {
          image: story.image ? story.image.url : '',
          title: story.title ? story.title[0].text : '',
          description: story.excerpt || '',
          category: story.category,
          ctaText: story.cta_text || '',
          ctaLink: `/news/${story.uid}`
        }
      }
      return null;
    }
  }
}
</script>

<style lang="scss">
.story-bottom {
  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;
    flex-direction: column;
    // padding: 0 100px;

    &--all-loaded {
      padding-bottom: 109px;
    }
  }

  .story-inline {
    $root: &;
    position: relative;
    
    margin-top: 86px;

    @include createQuery($sr_w_xs) {
      margin-top: 44px;
    }

    &__link {
      @include createQuery($sr_w_xs) {
        flex-direction: column;
      }
    }

    &__figure--primary {
      flex: 1;
      max-width: 400px;
      padding-right: 16px;

      @include createQuery($sr_w_xs) {
        padding-left: 36px;
        padding-right: 42px;
      }
    }
 
    &__content {
      position: relative;
      flex: 1;
      max-width: 600px;
      padding-left: 84px;

      .story__category {
        left: 32px;
        font-size: 12px !important;
        // color: $c_alizarin_crimson !important;
        letter-spacing: 3.75px !important;

        @include createQuery($sr_w_md) {
          // left: 0;
        }

        @include createQuery($sr_w_xs) {
          max-height: 80px;
          left: 0;
          top: 0;
          font-size: 8px !important;
          letter-spacing: 2.5px !important;
        }
      }

      @include createQuery($sr_w_md) {
        padding-right: 16px;
      }

      @include createQuery($sr_w_xs) {
        margin-top: 23px;
        padding-left: 36px;
        padding-right: 42px;
      }
    }
  
    &__figure {
      &--primary {
        position: relative;
        width: 100%;

        .story__figure-content {
          padding-bottom: 54.79%;
        }
      }

      .story__image-shadow {
        position: absolute;
        left: -8px;
        top: -8px;
        width: 100%;
        height: 100%;
        background-color: $c_alizarin_crimson;
        z-index: -1;

        @include createQuery($sr_w_md) {
          left: -4px;
          top: -4px;
        }
      }

      
    }

    &__text {
      @include createQuery($sr_w_xs) {
        display: none;
      }
    }
  }
}
</style>
