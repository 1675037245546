<template>
  <div
    class="article-audio"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
    ref="article"
  >
    <div class="article-audio__inner">
      <div class="article-audio__wrap">
        <h2 class="article-audio__title u-f-montserrat-black" ref="title">{{ title }}</h2>
        <p class="article-audio__text u-f-opensans-regular" ref="text">
          {{ text }}
        </p>
        <audio class="article-audio__player" :src="src" controls ref="player">
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'src', 'text'],
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      // {"translateY":["-20px",0],"opacity":1}
      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.player,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

// "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.article-audio {
  position: relative;
  overflow: hidden;
  margin-top: 142px;
  margin-bottom: 83px;

  @include createQuery($sr_w_xs) {
    margin-top: 110px;
    margin-bottom: 65px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    justify-content: center;
    @include inner;

    @include printMaxWith;
  }

  &__wrap {
    border-top: 1px solid $c_ship_gray;
    border-bottom: 1px solid $c_ship_gray;
    padding: 40px 0;

    @include createQuery($sr_w_xs) {
      padding: 20px 0;
    }
  }

  &__title {
    margin: 3px 0;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.75px;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.58px;
    }
  }

  &__text {
    margin: 0;
    font-size: 14px;
    line-height: 1.57;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1.38;
    }
  }

  &__player {
    width: 100%;
    margin-top: 40px;

    @include createQuery($sr_w_xs) {
      margin-top: 32px;
    }
  }
}
</style>
