<template>
  <div
    class="article-list"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-list__inner">
      <h3 class="article-list__title u-f-montserrat-semibold" ref="title">
        {{ title }}
      </h3>
      <div class="article-list__content">
        <p
          v-for="(text, index) in items"
          :key="index"
          class="article-list__text u-f-opensans-regular" ref="text"
        >
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'items'],
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.article-list {
  position: relative;
  background: $c_white;
  overflow: hidden;

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;
    flex-direction: column;

    @include printMaxWith;
  }

  &__title {
    margin: 53px 0 0 0;
    font-size: 23px;
    line-height: 1.35;
    letter-spacing: 0.72px;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      margin-top: 22px;
      font-size: 17px;
      line-height: 1.29;
      letter-spacing: 0.53px;
    }
  }

  &__text {
    margin-bottom: 54px;
    font-size: 16px;
    line-height: 2.06;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    &:last-child {
      margin-bottom: 0;
    }

    @include createQuery($sr_w_xs) {
      margin-bottom: 30px;
      font-size: 13px;
      line-height: 1.85;
    }
  }
}
</style>
