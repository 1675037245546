import vm from '../main';

export function fadeOut(ref, completedCallback) {
  vm.$anime({
    targets: ref,
    opacity: [1, 0],
    easing: 'easeOutSine',
    duration: 600,

    complete: completedCallback
  });
}

export function fadeIn(ref, completedCallback) {
  vm.$anime({
    targets: ref,
    opacity: [0, 1],
    easing: 'easeInSine',
    duration: 600,

    complete: completedCallback
  });
}