<template>
  <div :class="`watch-slot-change${className  ? ` ${className}` : ''}`" ref="observerContent">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data () {
    return {
      observer: null
    }
  },

  props: ['className'],

  mounted () {
    // create the observer
    this.observer = new MutationObserver(() => { // mutations
      this.$emit('slotChange');
    });
    // setup the observer
    this.observer.observe(
      this.$refs.observerContent,
      {attributes: true, childList: true, characterData: true, subtree: true}
    );
  },

  beforeDestroy () {
    // clean up
    this.observer.disconnect();
  }
}
</script>
