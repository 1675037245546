<template>
  <div
    class="category-top"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="category-top__inner">
      <div class="category-top__left">
        <TheTitle
          :category="this.category"
          :headline="this.headline"
          :subhead="this.subhead"
        />
        <div class="category-top__lines">
          <div class="category-top__lines-horizontal" />
          <div class="category-top__lines-vertical" />
        </div>
        <StoryTwoMiddle
          class="category-top__story-top"
          v-if="!getIsDesktop && getStory(0)"
          :story="getStory(0)"
          :smallImage="smallImage"
          :noeffect="!getIsDesktop"
        />
        <StoryOne
          class="category-top__story-left"
          v-if="getStory(1)"
          :story="getStory(1)"
          :noeffect="!getIsDesktop"
        />
      </div>
      <div v-if="getIsDesktop" class="category-top__right">
        <StoryTwoMiddle
          class="category-top__story-top"
          v-if="getStory(0)"
          :story="getStory(0)"
          :smallImage="smallImage"
          :noeffect="!getIsDesktop"
        />
        <div class="category-top__lines-vertical" />
      </div>
    </div>
    <div class="category-top__inner">
      <div class="category-top__middle">
        <StoryOne
          class="category-top__story-middle"
          v-if="getStory(2)"
          :story="getStory(2)"
          :noeffect="!getIsDesktop"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheTitle from './TheTitle';
import StoryOne from '../common/StoryOne';
import StoryTwoMiddle from '../common/StoryTwoMiddle';
export default {
  props: ['category', 'headline', 'subhead', 'stories', 'smallImage'],
  components: {
    TheTitle,
    StoryOne,
    StoryTwoMiddle,
  },
  computed: {
    ...mapGetters([
      'getIsDesktop'
    ])
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.category,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 900,
        duration: 600,
      });
    },
    getStory(index) {
      if (this.stories && this.stories[index]) {
        return {
          title: this.stories[index].title[0].text,
          description: this.stories[index].excerpt,
          category: this.stories[index].category,
          image: this.stories[index].image.url,
          cta_link: `/news/${this.stories[index].uid}`,
          uid: this.stories[index].uid
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.category-top {
  $root: &;
  margin-top: 239px;
  margin-bottom: 54px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 152px;
    margin-bottom: 0;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;

    @include createQuery($sr_w_md) {
      @include inner;
    }

    #{$root}__left {
      flex: 1;
      max-width: 600px;
      width: 100%;
      padding-right: 16px;

      #{$root}__story-left {
        margin-bottom: 45px;

        @include createQuery($sr_w_md) {
          margin-bottom: 8px;
        }
      }

      #{$root}__lines {
        position: absolute;
        left: 100px;
        z-index: $z_index_top;

        &-vertical {
          position: absolute;
          display: block;
          right: 0;
          width: 2px;
          height: 290px;
          background-color: $c_light_blue;
        }

        &-horizontal {
          display: block;
          width: 656px;
          height: 2px;
          background-color: $c_light_blue;
        }

        @include createQuery($sr_w_md) {
          display: none;
        }
      }

      @include createQuery($sr_w_xs) {
        max-width: 100%;
        padding: 0;
      }
    }

    #{$root}__right {
      position: relative;
      flex: 1;
      max-width: 600px;
      width: 100%;
      margin-top: 42px;
      padding-left: 100px;

      #{$root}__story-top {
        @include createQuery($sr_w_md) {
          margin-top: 96px;

          &::after {
            content: '';
            width: 100px;
            height: 100px;
            background-color: $c_light_blue;
          }
        }

        .story__header {
          padding-left: 0;
          background-color: $c_white;

          @include createQuery($sr_w_md) {
            padding-left: 36px;
          }

          .story__category {
            left: -42px;

            @include createQuery($sr_w_md) {
              left: 0;
            }
          }
        }
      }

      #{$root}__lines-vertical {
        position: absolute;
        left: 156px;
        width: 2px;
        height: 450px;
        top: calc(100% + 50px);
        z-index: $z_index_back;
        background-color: $c_light_blue;

        @include createQuery($sr_w_sm) {
          display: none;
        }
      }

      @include createQuery($sr_w_xs) {
        max-width: 100%;
        padding: 0;
        margin-top: 45px;
      }
    }

    #{$root}__middle {
      margin-top: 45px;
      margin-bottom: 82px;
      margin-left: 25.33%;
      max-width: 684px;

      @include createQuery($sr_w_md) {
        margin-left: 0;
        max-width: 584px;
      }

      @include createQuery($sr_w_xs) {
        max-width: 100%;
        margin-left: 0;
      }
    }
  }

  &__story-top {
    position: relative;
    @include createQuery($sr_w_md) {
      &::after {
        content: '';
        width: 2000px;
        height: 84px;
        display: block;
        position: absolute;
        top: -44px;
        right: 50%;
        border: 2px solid $c_light_blue;
        border-left: transparent;
        border-bottom: transparent;
        z-index: 100;
      }
    }
  }

  &__story-left {
    margin-top: 126px;

    @include createQuery($sr_w_md) {
      margin-top: 52px;
    }
  }

  .the-title__content {
    @include createQuery($sr_w_md) {
      padding: 0;
      padding-bottom: 80px;
      max-width: 300px;
    }
  }
}
</style>
