<template>
  <main class="page__content page__content--search">
    <SearchPageRenderer
      :data="data"
      :slices="slices"
      :searching="isLoading"
      @loadmore="onLoadMore"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import SearchPageRenderer from '../components/blocks/SearchPageRenderer';

export default {
  components: {
    SearchPageRenderer,
  },
  head: {
    // documentation: https://www.npmjs.com/package/vue-head
    title: {
      inner: 'Search'
    },
    meta: function() {
      return [
        { name: 'title', content: this.metaTitle, id: 'title' },
        { name: 'description', content: this.metaDescription, id: 'description' },
        { name: 'keywords', content: this.metaKeywords, id: 'keywords' },

        { property: 'og:description', content: this.metaDescription, id: 'description' },
        { property: 'og:title', content: this.metaTitle, id: 'og:title' },
      ];
    },
    link: function() {
      return [{
        rel: 'canonical',
        href: this.caronical,
        id: 'canonical'
      }];
    }
  },
  data () {
    return {
      keyword: '',
      id: null,
      page: 1,
      totalPages: null,
      pageSize: 5,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      data: 'getPageData',
      slices: 'getSlices',
    }),
    metaTitle() {
      if (this.data) {
        return this.data.meta_title;
      }
      return '';
    },
    metaDescription() {
      if (this.data) {
        return this.data.meta_description;
      }
      return '';
    },
    metaKeywords() {
      if (this.data) {
        return this.data.meta_keywords || '';
      }
      return '';
    },
    caronical() {
      return '';
    },
    isLoading() {
      return this.loading;
    }
  },
  created() {
    const { keyword } = this.$route.params;
    if (keyword) {
      this.keyword = keyword;
      this.loadPage();
    } else {
      this.$router.push('/not-found');
    }
  },
  beforeRouteUpdate (to, from, next) {
    const { keyword } = to.params;
    if (keyword) {
      this.keyword = keyword;
      this.page = 1;
      this.loadPage(keyword);
    } else {
      this.$router.push('/not-found');
    }
    next();
  },
  methods: {
    async loadArticles(page) {
      if (this.keyword) {
        let pageSize = this.pageSize;
        if (page === 1) {
          pageSize = pageSize + 2;
        }
        this.loading = true;
        await this.$store.dispatch('queryArticleBySearch', { pageSize, page, keyword: this.keyword, vm: this });
        this.loading = false;
      }
    },
    async loadPage(keyword) {
      this.loadArticles(this.page, keyword);
      this.$emit('updateHead');
    },
    async onLoadMore () {
      this.page++;
      this.loadArticles(this.page);
    },
  }
};
</script>

<style lang="scss">
.page {
  &__content {
    &--search {
      margin-top: 239px;

      @include createQuery($sr_w_xs) {
        margin-top: 152px;
      }
    }
  }
}
/*@import "../../assets/scss/_blocks/page";*/
</style>
