<template>
  <div
    class="article-copy"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-copy__inner">
      <div class="article-copy__content" ref="content">
        <h3 class="article-copy__title u-f-montserrat-semibold">
          {{ headline }}
        </h3>
        <prismic-rich-text
          class="article-copy__text u-f-opensans-regular"
          :field="paragraph"
          />
        <!-- <p
          v-for="(text, index) in paragraphs"
          :key="index"
          class="article-copy__text u-f-opensans-regular"
          ref="text"
        >
          {{ text }}
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['paragraph', 'headline'],
  computed: {
    ...mapGetters(['getIsMobile'])
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.content,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss">
.hero-banner + .article-content > .article-copy:first-child {
  margin-top: 0;
}
.article-copy {
  margin-bottom: 54px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 22px;
    margin-bottom: 20px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;

    @include printMaxWith;
  }

  &__content {
    position: relative;

    opacity: 0; // set in js

    @include noAnimationInPrint;
  }

  &__title {
    margin: 0;
    font-size: 23px;
    line-height: 1.35;
    letter-spacing: 0.72px;
    color: $c_ship_gray;

    @include createQuery($sr_w_sm) {
      font-size: 18px;
      line-height: 1.29;
      letter-spacing: 0.53px;
    }
  }

  &__text {
    @include defaultCopyStyles;
  }
}
</style>
