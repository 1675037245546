<template>
  <div
    class="article-image"
    :class="{'full-width': isFullWidth}"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
    v-parallax="isFullWidth && !getIsMobile ? 0.05 : 0"
  >
    <div class="article-image__inner" :class="{'full-width': isFullWidth, 'portrait': isPortrait}">
      <figure class="article-image__figure">
        <div class="article-image__figure-content js-parallax-watch-parent">
          <div class="article-image__backdrop" ref="backdrop"></div>
          <div class="article-image__image-wrap" ref="imageWrap">
            <img
              v-parallax.watchParent="isFullWidth && !getIsMobile ? 0.1 : 0"
              :src="image"
              class="article-image__image"
            />
          </div>
        </div>
      </figure>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['width', 'image'],
  computed: {
    ...mapGetters([
      'getIsMobile'
    ]),
    isFullWidth() {
      return this.width === 'full-width';
    },
    isPortrait() {
      return this.width === 'portrait'
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      // const transformation = {
      //   targets: this.$refs.imageWrap,
      //   easing: 'easeOutSine',
      //   delay: 1200,
      //   duration: 450,
      // };
      //
      // if (this.isFullWidth) transformation.scale = [1, 1.05];
      //
      // this.$anime(transformation);

      if (this.$refs.imageWrap)
        this.$refs.imageWrap.style.opacity = 0;

      this
        .$anime
        .timeline()
        .add({
          targets: this.$refs.backdrop,
          translateY: ['105%', 0],
          easing: 'easeOutSine',
          duration: 400,
          complete: () => {

            if (this.$refs.imageWrap)
              this.$refs.imageWrap.style.opacity = 1;

          }
        })
        .add({
          targets: this.$refs.backdrop,
          translateY: [0, '-105%'],
          easing: 'easeInSine',
          duration: 400
        });

    },
  },
};
</script>

<style lang="scss" scoped>
.article-image {
  $root: &;
  position: relative;
  background: $c_white;
  overflow: hidden;

  margin-top: 50px;
  margin-bottom: 50px;

  &.full-width {
    margin-bottom: 70px;
  }

  @include createQuery($sr_w_sm) {
    margin-top: 16px;
    margin-bottom: 40px;

    &.full-width {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;

    @include printMaxWith;

    &.full-width {
      max-width: 100%;
      padding: 0;

      #{$root}__figure-content {
        padding-bottom: 33.89%;

        @include createQuery($sr_w_xs) {
          padding-bottom: 61.25%;
        }
      }
    }

    &.portrait {
      #{$root}__image {
        object-fit: contain;
        height: 100%;
        // height: auto;
        top: 0px;
      }

      #{$root}__backdrop {
        background-color: transparent;
      }
    }
  }

  &__figure {
    position: relative;
    margin: 0;
    display: block;
    width: 100%;
  }

  &__figure-content {
    overflow: hidden;
    position: relative;
    padding-bottom: 51.02%;
  }

  &__backdrop {
    position: absolute;
    z-index: $z_index_min;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: $c_biscay;

    transform: translateY(105%);
  }

  &__image-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 0;

    @include noAnimationInPrint;
  }

  &__image {
    display: block;
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: calc(100% + 100px);
    top: -50px;
    object-fit: cover;
    object-position: center;

    @include createQuery($sr_w_md) {
      height: 100%;
      top: 0;
    }
  }
}
</style>
