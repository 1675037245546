<template>
  <div class="all-stars">
    <div class="all-stars__inner">
      <div class="all-stars__left">
        <JoinRemax 
          :buttons="story.buttons"
          :title="story.join_title"
          :copy="story.join_copy"
        />
        <div class="all-stars__left-lines">
          <div class="all-stars__left-lines--horizontal">
          </div>
        </div>
      </div>
      <div class="all-stars__right">
        <template v-if="story">
          <StoryOne
            :story="story"
            :buttonStyleCta="true"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import StoryOne from '../common/StoryOne';
import JoinRemax from './JoinRemax';

export default {
  props: ['story', 'stars'],
  components: {
    StoryOne,
    JoinRemax,
  },
}
</script>

<style lang="scss">
.all-stars {
  display: block;
  width: 100%;
  margin-top: 85px;
  padding: 0 0 120px 0;
  background: $c_white;
  overflow-y: visible;

  @include createQuery($sr_w_sm) {
    margin-top: 0;
    padding-bottom: 0;
  }

  &__inner {
    @include maxWidth;
    @include createQuery($sr_w_md) {
      flex-direction: row;
      padding-left: 20px;
      padding-right: 20px;
    }

    @include createQuery($sr_w_sm) {
      flex-direction: column;
    }
  }

  &__left {
    position: relative;
    margin-top: -205px;
    padding-left: 8.33%;
    padding-right: 9.66%;
    padding-bottom: 50px;
    flex: 1;
    max-width: 500px;

    @include createQuery($sr_w_not_sm) {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        border-left: 2px solid $c_sail;
      }
    }

    @include createQuery($sr_w_md) {
      padding-left: 5%;
      padding-right: 5%;
    }

    @include createQuery($sr_w_sm) {
      padding: 0;
      margin-top: 0;
      max-width: 100%;
      width: 100%;
      order: 2;

      .join-remax__inner {
        padding-right: 0;
      }
    }
  }

  &__left-lines {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    &--horizontal {
      width: calc(100% + 48px);
      margin-left: -48px;
      margin-top: -71px;
      height: 0;
      border-bottom: 2px solid $c_sail;
    }

    @include createQuery($sr_w_sm) {
      display: none;
    }
  }

  &__right {
    position: relative;
    flex: 1;
    max-width: 700px;
    padding-right: 0;

    .story {
      &__header {
        padding-left: 14.61% !important;
        padding-right: 7.31% !important;

        @include createQuery($sr_w_md) {
          padding-left: 20.88% !important;
        }

        @include createQuery($sr_w_sm) {
          margin-top: 32px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }

      &__category {
        left: 6.14% !important;

        @include createQuery($sr_w_md) {
          left: 9.68% !important;
          top: 0;
          max-height: 150px !important;
        }

        @include createQuery($sr_w_xs) {
          left: 0 !important;
        }
      }
    }

    @include createQuery($sr_w_md) {
      max-width: 100%;
      width: 100%;
      padding: 0;
      flex: 2;
      order: 1;
    }

    @include createQuery($sr_w_xs) {
      padding: 0;
    }
  }
}
</style>
