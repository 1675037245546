<template>
  <div class="animated-loader" style="display:none;" ref="animatedLoader">
    <div class="animated-loader__backdrop" ref="backdrop"></div>
    <div class="animated-loader__dots" ref="dots">
      <div class="animated-loader__dot"></div>
      <div class="animated-loader__dot"></div>
      <div class="animated-loader__dot"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

const animatedLoaderOpts = {
  checkIfLoadedEvery: 100,

  animateInEasing: 'easeOutCubic',
  animateOutEasing: 'easeOutCubic',
  animateInDuration: 800,
  animateOutDuration: 600,

  animateContentInEasing: 'easeOutCubic',
  animateContentOutEasing: 'easeOutCubic',
  animateContentInDuration: 150,
  animateContentOutDuration: 550,
  animateContentOutDelay: 400,

  transitionMinDuration: 1400,
};

export default {
  data () {
    return {
      loaderTimeout: '',
      isVisible: false,
      dontAnimateNext: false,
      isFinishedAnimatingAndVisible: false,
      loadTime: 0,
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
    ]),
  },
  watch: {
    isVisible: function (val) {
      if (val) {
        this.playShowAnimation();
      } else {
        this.playHideAnimation();
      }
    }
  },
  methods: {
    triggerFakeResize () {
      window.dispatchEvent(new Event('resize'));
    },
    onChangeRoute (e) {
      if (e && !e.detail) this.dontAnimateNext = true;
      this.isVisible = true;
    },
    onLoadPreview () {
      this.playShowAnimation(true);
    },
    checkIfIsLoaded () {
      this.loadTime += animatedLoaderOpts.checkIfLoadedEvery;
      if (this.isLoading) return;

      // done loading, play or delay hide
      if (animatedLoaderOpts.transitionMinDuration > this.loadTime) {
        window.setTimeout(() => {
          this.isVisible = false;
        }, animatedLoaderOpts.transitionMinDuration - this.loadTime);
      } else {
        this.isVisible = false;
      }
    },
    playShowAnimation (dontAnimate) {
      this.loadTime = 0;
      this.loaderTimeout = window.setInterval(this.checkIfIsLoaded, animatedLoaderOpts.checkIfLoadedEvery);

      // is first, so dont animate
      if (this.dontAnimateNext) dontAnimate = true;
      this.dontAnimateNext = false;

      this.$refs.animatedLoader.style.display = 'flex';
      this.$refs.animatedLoader.style.top = 0;
      this.$refs.animatedLoader.style.bottom = 'auto';

      if (this.isFinishedAnimatingAndVisible) return;

      // main content
      const mainContent = document.querySelector('.js-main-content');
      this.$anime({
        targets: mainContent,
        opacity: [1, 0],
        easing: animatedLoaderOpts.animateContentInEasing,
        duration: animatedLoaderOpts.animateContentInDuration,
        complete () {

          mainContent.style.display = 'none';

        }
      });

      // backdrop
      this.$refs.backdrop.style.transform = 'translateX(-50%)';

      this.$anime({
        targets: this.$refs.backdrop,
        translateY: ['50%', '-50%'],
        translateX: '-50%',
        easing: animatedLoaderOpts.animateInEasing,
        delay: 100,
        duration: dontAnimate ? 0 : animatedLoaderOpts.animateInDuration,
        complete: () => {

          this.isFinishedAnimatingAndVisible = true;

        }
      });

      // dots
      // only if not first
      if (!dontAnimate) {
        this.$anime({
          targets: this.$refs.dots,
          opacity: [0, 1],
          translateY: [40, 0],
          easing: animatedLoaderOpts.animateInEasing,
          duration: animatedLoaderOpts.animateInDuration,
          delay: animatedLoaderOpts.animateInDuration / 2
        });
        this.$refs.dots.classList.add('is-animated');
      }  else this.$refs.dots.style.opacity = 0;
    },
    playHideAnimation () {
      window.clearInterval(this.loaderTimeout);

      const dontAnimate = false;

      this.$anime.remove(this.$refs.animatedLoader);
      this.$anime.remove(this.$refs.dots);

      this.$refs.animatedLoader.style.top = 'auto';
      this.$refs.animatedLoader.style.bottom = 0;

      this.isFinishedAnimatingAndVisible = false;

      window.setTimeout(() => {

        // main content
        const mainContent = document.querySelector('.js-main-content');
        mainContent.style.display = '';
        this.$anime({
          targets: mainContent,
          opacity: [0, 1],
          easing: animatedLoaderOpts.animateContentOutEasing,
          duration: animatedLoaderOpts.animateContentOutDuration,
          complete: () => {

            const event = new CustomEvent('remax_loading_done');
            window.dispatchEvent(event);

          }
        });
        this.triggerFakeResize();

      }, animatedLoaderOpts.animateContentOutDelay);

      // backdrop
      // const backdropWidth = this.backdropWidth;
      this.$anime({
        targets: this.$refs.backdrop,
        translateY: ['-50%', '50%'],
        translateX: '-50%',
        easing: animatedLoaderOpts.animateOutEasing,
        duration: dontAnimate ? 0 : animatedLoaderOpts.animateOutDuration,
        complete: () => {

          this.$refs.animatedLoader.style.display = 'none';

        }
      });

      // dots
      if (this.$refs.dots.classList.contains('is-animated')) {
        this.$anime({
          targets: this.$refs.dots,
          opacity: [1, 0],
          translateY: [0, 40],
          easing: animatedLoaderOpts.animateOutEasing,
          duration: dontAnimate ? 0 : (animatedLoaderOpts.animateOutDuration / 2.25),
        });
        this.$refs.dots.classList.remove('is-animated')
      }
    },
  },
  mounted () {
    window.addEventListener("remax_before_route", this.onChangeRoute);
    window.addEventListener("remax_load_preview", this.onLoadPreview);
  },
  beforeDestroy () {
    window.removeEventListener("remax_before_route", this.onChangeRoute);
    window.removeEventListener("remax_load_preview", this.onLoadPreview);
  },
}
</script>

<style lang="scss" scoped>
.animated-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z_index_max;
  display: none; // flex
  justify-content: center;
  align-items: center;

  pointer-events: none;
  touch-action: none;

  &__backdrop {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    background: $c_light_gray;
  }

  &__dots {
    position: relative;
    z-index: $z_index_top;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__dot {
    width: 14px;
    height: 14px;
    margin: 0 7px 0 0;
    border-radius: 50%;
    animation-name: bounceLoader;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &:first-of-type {
      background: $c_alizarin_crimson;
    }

    &:nth-of-type(2) {
      background: $c_cobalt;
      animation-delay: .25s;
    }

    &:last-of-type {
      margin-right: 0;
      background: $c_tall_poppy;
      animation-delay: .51s;
    }
  }
}

@keyframes bounceLoader {
  25% {
    transform: translateY(-20px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
