<template>
  <div
    class="article-report"
    :class="className"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-report__inner">
      <div class="article-report__wrapper">
        <div class="article-report__icon" ref="icon">
          <reportIcon class="article-report__icon-svg" />
        </div>
        <div class="article-report__text u-f-montserrat-black" ref="text">{{ description }}</div>
        <ul class="article-report__files u-f-montserrat-bold" ref="files">
          <li
            v-for="(item, index) in files"
            :key="index"
            class="article-report__item"
          >
            <a
              v-if="item.link.link_type === 'Media'"
              :href="`${item.link.url}`"
              target="_blank"
              class="article-report__link"
            >
              {{ item.label }}
            </a>
            <TheLink
              v-else-if="item.link.link_type === 'Document'"
              :to="`/${item.link.slug}`"
              :label="item.label"
              class="article-report__link"
            />
            <a v-else :href="item.link.url" target="_blank" rel="noreferrer" class="article-report__link">{{item.label}}</a>
          </li>
        </ul>
        <div class="article-report__lines article-report__lines--bottom">
          <div class="article-report__line article-report__line--full" ref="bottomLine"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reportIcon from '@/assets/svg/icon-download.svg';
import TheLink from '../../elements/buttons/TheLink';
export default {
  props: [
    'className',
    'description',
    'files'
  ],
  components: {
    reportIcon,
    TheLink
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      // icon scales in
      this.$anime({
        targets: this.$refs.icon,
        scale: [0, 1],
        opacity: [0, 1],
        duration: 300,
        delay: 600,
        easing: 'easeOutSine',

        complete: () => {
          this.$anime({
            targets: [this.$refs.text, this.$refs.files],
            opacity: [0, 1],
            translateY: ['20px', 0],
            easing: 'easeOutSine',
            duration: 600
          });
        }
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.article-report {
  $root: &;
  position: relative;
  margin-top: 32px;
  margin-bottom: 52px;
  width: 100%;

  @include createQuery($sr_w_xs) {
    margin-top: 20px;
    margin-bottom: 20px;
  }


  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;
    flex-direction: column;

    @include printMaxWith;
  }

  &__link-wrap {
    text-decoration: none;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    margin-right: 16px;
    display: flex;
    @include createQuery($sr_w_xs) {
      padding: 30px 0px 22px;
    }
  }

  &__icon {
    background-color: $c_white;

    opacity: 0;

    @include noAnimationInPrint;

    svg {
      @include createQuery($sr_w_xs) {
        width: 24px;
      }
    }
  }

  &__text {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.75px;
    text-align: center;
    color: $c_ship_gray;

    opacity: 0;

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }

  &__files {
    @include resetUL;
    
    margin-top: 3px;

    color: $c_alizarin_crimson;

    opacity: 0;

    @include noAnimationInPrint;

    #{$root}__item {
      margin-bottom: 16px;
      padding: 0 10px;
      font-size: 12px;
      line-height: 1;

      &:last-child {
        border: none;
      }

      @include createQuery($sr_w_xs) {
        font-size: 11px;
        padding: 0 6px;
      }
    }

    #{$root}__link {
      font-size: 9px;
      letter-spacing: 0.93px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      color: $c_text_gray;
    }
  }
}
</style>
