<template>
  <div class="navigation" :class="`navigation--${theme}`">
    <ul class="navigation__list">
      <li
        class="navigation__list-item"
        :class="{'active': decade === currentDecade}"
        v-for="(decade, key) in decades"
        :key="`${decade}_${key}`"
      >
        <span class="u-f-opensans-regular">{{ decade }}</span>
        <a
          class="navigation__list-item-btn"
          :class="{'active': getFirstYear(decade) === current}"
          href="#"
          @click="onClickYear(getFirstYear(decade))"
        ></a>
        <ul
          class="navigation__sub-list"
          :class="{ 'visible': currentDecade === decade }"
        >
          <li
            class="navigation__sub-list-item"
            v-for="(year, index) in getYears(decade)"
            :key="`year_${year}_${index}`"
          >
            <span
              class="u-f-opensans-regular"
              :class="{'active': year === current}"
            >{{ year }}</span>
            <a
              class="navigation__sub-list-item-btn"
              :class="{'active': year === current}"
              href="#"
              @click="onClickYear(year)"
            ></a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['current', 'decades', 'years', 'theme'],
  computed: {
    currentDecade() {
      if (this.current) {
        return parseInt(this.current) - (parseInt(this.current) % 10);
      }
      return null;
    },
  },
  methods: {
    onClickYear(year) {
      this.$emit('change', year);
    },
    getFirstYear(currentDecade) {
      const years = this.years.filter(year => (year - year % 10) === currentDecade);
      return years[0];
    },
    getYears(currentDecade) {
      const years = this.years.filter(year => (year - year % 10) === currentDecade);
      return years.slice(1);
    }
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  $root: &;
  position: fixed;
  right: 45px;
  width: 100px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  z-index: $z_index_top;

  @include createQuery($sr_w_xs) {
    display: none;
  }

  &__list {
    margin: 0;
    padding: 0;

    &-item {
      position: relative;
      list-style-type: none;

      &.active {
        > span {
          display: initial;
        }

        #{$root}__list-item-btn {
          width: 22px;
          height: 22px;
          background-color: $c_white;

          @at-root #{$root}--light-blue & {
            background-color: $c_white;
          }

          @at-root #{$root}--grey & {
            background-color: $c_regent_gray;
          }

          @at-root #{$root}--white & {
            // border-color: $c_lochmara;
            background-color: $c_silver_sand;

            &.active {
              border-color: $c_lochmara;
              background-color: transparent;
            }
          }

          &.active {
            border-color: $c_white;
            background-color: transparent;
          }
        }
      }

      &:hover {
        > span {
          display: initial;
        }
      }

      > span {
        position: absolute;
        right: 33px;
        font-size: 16px;
        letter-spacing: 2px;
        color: $c_white;
        display: none;

        transition: all .2s;

        @at-root #{$root}--white & {
          color: $c_ship_gray;
        }
      }

      &-btn {
        display: block;
        margin: 0 auto 17px;
        width: 16px;
        height: 16px;
        border: 2px solid transparent;
        border-radius: 11px;
        background-color: transparent; // change according to theme

        transition: all .2s;
        cursor: pointer;

        @at-root #{$root}--dark-blue & {
          background-color: $c_lochmara;
        }

        @at-root #{$root}--blue & {
          background-color: $c_sail;
        }

        @at-root #{$root}--light-blue & {
          background-color: $c_white;
        }

        @at-root #{$root}--grey & {
          background-color: $c_regent_gray;
        }

        @at-root #{$root}--white & {
          background-color: $c_silver_sand;
        }

        &:hover {
          background-color: transparent;
        }

        &.active {
          background-color: transparent;
        }
      }

      #{$root}__sub-list {
        display: block;
        margin: 0;
        padding: 0;
        max-height: 0;
        overflow-y: hidden;
        // overflow: hidden;

        transition: all 1s;

        &.visible {
          max-height: 1000px;
        }

        &-item {
          position: relative;
          list-style-type: none;

          &:hover {
            > span {
              opacity: 1;
            }
          }

          > span {
            position: fixed;
            right: 75px;
            margin-top: -8px;
            font-size: 16px;
            letter-spacing: 2px;
            color: $c_white;
            opacity: 0;

            transition: all .2s;

            @at-root #{$root}--white & {
              color: $c_ship_gray;
            }

            &.active {
              opacity: 1;
            }
          }

          &-btn {
            display: block;
            margin: 0 auto 20px;
            width: 8px;
            height: 8px;
            border: 2px solid $c_white;
            border-radius: 11px;
            background-color: $c_white;

            transition: all .2s;
            cursor: pointer;
            
            @at-root #{$root}--light-blue & {
              border: 2px solid $c_lochmara;
              background-color: $c_lochmara;
            }

            @at-root #{$root}--white & {
              border: 2px solid $c_lochmara;
              background-color: $c_lochmara;
            }

            &.active {
              background-color: transparent;
            }

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  
}
</style>
