<template>
  <div class="section">
    <div class="line--horizontal"></div>
    <div class="intro">
      <div class="line--vertical"></div>
      <div class="inner">
        <h2 class="title" v-if="title">{{ title }}</h2>
        <p class="paragraph" v-if="paragraph">{{ paragraph }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'paragraph'],
}
</script>

<style lang="scss" scoped>
.section {
  position: relative;
  margin-bottom: 46px;
  margin-left: 20px;
}

.intro {
  @include maxWidth;
  padding-left: 20px !important;
  height: 100%;
  position: relative;
}

.inner {
  max-width: 860px;
  padding: 36px 0;

  @include createQuery($sr_w_not_sm) {
    padding: 72px 0;
    margin: 0 100px 0 80px;
  }
}

.line {
  &--horizontal {
    position: absolute;
    height: 2px;
    width: 50vw;
    bottom: 0;
    left: -20px;
    background: linear-gradient(to right, $c_remax_blue, transparent);
  }

  &--vertical {
    position: absolute;
    height: calc(100% + 46px);
    width: 2px;
    top: 0;
    left: 0;
    background: $c_remax_blue;
    @include createQuery($sr_w_sm) {
      top: 72px;
    }
  }
}

.paragraph {
  font-size: 16px;
  line-height: 33px;

  @include createQuery($sr_w_sm) {
    font-size: 14px;
    line-height: 23px;
  }
}
</style>