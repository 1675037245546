<template>
    <div>
        <ul class="menu">
            <li 
                v-for="(item, index) in menuItems" 
                :key="index"
                class="menu__item"
            >
                <TheLink
                    v-if="isSubmenu(item)"
                    :label="item.text"
                    @click="toggleSubmenu(item)"
                    :className="`menu__link ${item.open ? 'menu__link--active' : ''}`"
                />
                <ul class="submenu" :class="{'submenu--active': item.open}" v-if="isSubmenu(item)">
                    <li class="submenu__item" v-if="item.link">
                        <TheLink
                            className="submenu__link link--learn-more"
                            :to="item.link"
                            :label="'Learn More'"
                        />
                    </li>
                    <li class="submenu__item" v-for="(sublink, subindex) in item.sublinks" :key="'sublink_' + subindex">
                        <TheLink
                            v-if="isSubmenu(sublink)"
                            :label="sublink.text"
                            @click="toggleSubmenu(sublink)"
                            :className="`submenu__link ${sublink.open ? 'submenu__link--active' : ''}`"
                        />
                        <ul class="sublist" :class="{'sublist--active': sublink.open}" v-if="isSubmenu(sublink)">
                            <li v-for="(link, linkIndex) in sublink.sublinks" :key="'sublistitem_' + linkIndex">
                                <TheLink
                                    v-if="isSubmenu(link)"
                                    :label="link.text"
                                    @click="toggleSubmenu(link)"
                                    :className="`sublist__link ${link.open ? 'sublist__link--active' : ''}`"
                                />
                                <ul class="sublist" :class="{'sublist--active': link.open}" v-if="isSubmenu(link)">
                                    <li v-for="(l, i) in link.sublinks" :key="'link_' + i">
                                        <TheLink 
                                            :label="l.sublink_text"
                                            :to="l.sublink"
                                            :external="externalLink(l.sublink)"
                                            :className="'sublist__link'"
                                        />
                                    </li>
                                </ul>
                                <TheLink 
                                    v-else
                                    :label="link.text"
                                    :to="link.link"
                                    :external="externalLink(link)"
                                    :className="`sublist__link`"
                                />
                            </li>
                        </ul>
                        <TheLink
                            v-else
                            :to="sublink.link"
                            :label="sublink.text"
                            :external="externalLink(sublink.link)"
                            :className="`submenu__link`"
                        />
                    </li>
                </ul>
                <TheLink
                    v-else
                    :to="item.link"
                    :label="item.text"
                    :external="externalLink(item)"
                    className="menu__link"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheLink from '@/components/elements/buttons/TheLink';

export default {
    components: {
        TheLink
    },

    data() {
        return {
            active: false,
            menuItems: []
        }
    },

    computed: {
        ...mapGetters([
            'getHeaderMenu',
        ]),

        // menuItems() {
        //     if (!this.getHeaderMenu) return []
        //     return this.getHeaderMenu
        // }
    },

    methods: {
        isSubmenu(item) {
            if (item.sublinks && item.sublinks.length > 0) return true;
            return false;
        },

        toggleSubmenu(item) {
            item.open = !item.open
        },

        externalLink(item) {
            if (item.link && item.link.link_type) {
                return item.link.link_type === 'Web'
            }
            if (item.link_type) {
                return item.link_type === 'Web'
            }
            return false
        },

        setItems() {
            if (this.getHeaderMenu.length) {
                this.menuItems = this.getHeaderMenu.map(item => {
                    if (item.sublinks && item.sublinks.length) {
                        item.sublinks = item.sublinks.map(subitem => {
                            if (subitem.sublinks && subitem.sublinks.length) {
                                subitem.sublinks = subitem.sublinks.map(link => {
                                    if (link.sublinks && link.sublinks.length) {
                                        return {
                                            ...link,
                                            open: false
                                        }
                                    } else {
                                        return { ...link }
                                    }
                                })
                                return {
                                    ...subitem,
                                    open: false,
                                }
                            } else {
                                return { ...subitem }
                            }
                        })
                        return {
                            ...item,
                            open: false,
                        }
                    } else {
                        return { ...item }
                    }
                })
            }
        }
    },

    watch: {
        getHeaderMenu() {
            this.setItems();
        },

        $route() {
            this.$emit('navigated')
        }
    },

    mounted() {
        this.setItems();
    }
}
</script>

<style lang="scss" scoped>
@mixin after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 1px solid $c_remax_blue;
    border-left-color: transparent;
    border-bottom-color: transparent;
    transform: rotate(45deg);
    position: relative;
    top: -1px;
    left: -8px;
    opacity: 0;
    transition: all $t_fast;
}

.menu {
    list-style-type: none;

    &__item {
        margin-bottom: 30px;
    }

    &__link {
        line-height: 1.2;
        text-align: left;
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-size: 14px;

        &:hover {
            color: $c_text_gray;
        }

        &::after {
            @include after;
        }

        &--active {
            color: $c_remax_blue;

            &:hover {
                color: $c_remax_blue;
            }

            &::after {
                opacity: 1;
                left: 2px;
            }
        }
    }
}

.submenu {
    list-style-type: none;
    padding-left: 35px;
    overflow: hidden;
    max-height: 0px;
    transition: all 0.5s;

    &--active {
        max-height: 1000px;
    }

    &__item {
        margin-top: 18px;
    }

    &__link {
        text-transform: none;
        font-size: 14px;

        &:hover {
            color: $c_text_gray;
        }

        &::after {
            @include after;
        }

        &.link--green {
            text-transform: uppercase !important;
        }

        &--active {
            color: $c_remax_blue;

            &:hover {
                color: $c_remax_blue;
            }

            &::after {
                opacity: 1;
                left: 2px;
            }
        }
    }
}

.sublist {
    list-style-type: none;
    padding-left: 35px;
    overflow: hidden;
    max-height: 0px;
    transition: all 0.5s;

    &--active {
        max-height: 1000px;
    }

    &__link {
        text-transform: none;
        text-transform: none;
        letter-spacing: 0.5px;
        line-height: 1.5;
        font-size: 12px;
        font-weight: 400;
        display: block;
        margin-top: 12px;

        &:hover {
            color: $c_text_gray;
        }

        &::after {
            @include after;
        }

        &--active {
            color: $c_remax_blue;

            &:hover {
                color: $c_remax_blue;
            }

            &::after {
                opacity: 1;
                left: 2px;
            }
        }
    }
}
</style>