<template>
  <input
    v-bind="getProps"
    @blur="onBlur"
    @focus="onFocus"
    @change="onChange"
    @input="onInput"
    @keyup="onKeyUp"
    ref="input"
  />
</template>

<script>
export default {
  props: [
    'placeholder',
    'className',
    'value',
    'id',
    'autocomplete',
    'type',
    'maxlength',
    'required',
  ],
  computed: {
    // getMask () {
    //   return this.mask ? this.mask : null;
    // },
    classNames() {
      return `input${this.className ? ` ${this.className}` : ''}`;
    },
    getProps() {
      const theProps = {};
      // if (this.mask) theProps.mask = this.mask;
      if (this.id) theProps.id = this.id;
      if (this.autocomplete) theProps.autocomplete = this.autocomplete;
      if (this.placeholder) theProps.placeholder = this.placeholder;
      if (this.maxlength) theProps.maxlength = this.maxlength;
      if (this.required) theProps.required = this.required;
      theProps.type = this.getType;
      theProps.class = this.classNames;
      return theProps;
    },
    getType() {
      return this.type ? this.type : 'text';
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('updated', val);
      },
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.$refs.input.value);
    },

    onChange(e) {
      this.$emit('change', e);
    },

    onFocus(e) {
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.$emit('blur', e);
    },

    onKeyUp(e) {
      this.$emit('keyup', e);
    }
  },
};
</script>

<style lang="scss">
.input {
  padding: 16px 56px 12px 15px;
  border-radius: 3px;
  border: 1px solid $c_silver_sand;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0.43px;
  color: $c_ship_gray;
  outline: 0;
  transition: all $t_fast;

  &:focus {
    border-color: #ecf0f9;
    background: #ecf0f9;
  }

  @include createQuery($sr_w_xs) {
    padding: 3px 53px 1px 8px;
  }

  @include placeholder {
    font-weight: normal;
    color: $c_mid_gray;
  }

  caret-color: $c_alizarin_crimson;

  &--thin {
    padding: 8px 56px 6px 15px;
  }
}
</style>
