<template>
  <div class="thumb-popup" ref="thumbpopup">
    <div class="thumb-popup__inner">
      <div class="thumb-popup__content">
        <h2 class="thumb-popup__title u-f-montserrat-bold">
          {{ title }}
        </h2>
        <div class="thumb-popup__thumbs">
          <figure
            v-for="(thumb, index) in photos"
            :key="index"
            class="thumb-popup__thumb-figure"
            @click="onClickThumbnail(index)"
          >
            <img
              :src="thumb.image"
              class="thumb-popup__thumb-image"
              ref="image"
            />
          </figure>
        </div>
      </div>
      <closeIcon class="thumb-popup__close" @click="onCloseModal" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import closeIcon from '@/assets/svg/icon-x.svg';
export default {
  props: ['photos', 'title'],
  components: {
    closeIcon
  },
  mounted() {
    this.playAnimation();
  },
  methods: {
    onCloseModal() {
      this.$emit('close');
    },
    playAnimation() {
      this
        .$anime({
          targets: this.$refs.thumbpopup,
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          delay: 0,
          duration: 450,
        });
    }
  },
};
</script>

<style lang="scss">
.thumb-popup {
  $root: &;
  position: fixed;
  top: 0; // stripe height
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px;
  background-color: rgba(0,0,0,0.85);
  z-index: $z_index_overlay;

  opacity: 0;

  &__inner {
    position: relative;
    @include maxWidth;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__title {
    margin-bottom: 31px;
    text-align: center;
    font-size: 25px;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_white;
  }

  &__close {
    position: absolute;
    left: 0;
    bottom: 94px;
    color: $c_white;
    cursor: pointer;

    @include createQuery($sr_w_xs) {
      left: 17px;
      bottom: 14px;
    }
  }

  &__content {
    max-width: 100%;
    width: 510px;
  }

  &__thumbs {
    display: flex;
    flex-wrap: wrap;

    #{$root}__thumb-figure {
      position: relative;
      margin: 0 2px 32px 0;
      width: 100px;
      height: 58px;
      cursor: pointer;

      @include createQuery($sr_w_xs) {
        width: calc(33% - 2px);
      }

      #{$root}__thumb-image {
        display: block;
        @include overlay;
        object-fit: cover;
      }
    }
  }
}
</style>
