<template>
  <TheLink
    className=""
    :to="item.link"
    :target="itemTarget"
    :label="itemText"
  />
</template>

<script>
import TheLink from '@/components/elements/buttons/TheLink';

export default {
  props: ['item'],

  components: {
    TheLink,
  },

  computed: {
    itemText() {
      return this.item.text
    },
    itemTarget() {
      if (this.item.link.target) return this.item.link.target
      return ''
    },
  }
}
</script>

<style>

</style>