<template>
    <div v-if="module" class="wrap">
        <div class="inner">
            <TheFilter :current="current" :filters="filters" @filter="onChangeFilter" :fullWidth="true" />
            
            <!-- <ProfileList :profiles="profiles" :filter="current" /> -->
            <div v-if="currentItem" ref="tabContent">
                <div class="image-wrap" v-if="currentItem.image1 && currentItem.image1.url ">
                    <img class="image" :src="currentItem.image1.url" :alt="currentItem.image1.alt">
                </div>
                <h3 class="title u-f-montserrat-black">{{ currentItem.headline }}</h3>
                <div class="copy" v-html="$prismic.asHtml(currentItem.copy)"></div>
            </div>
            
        </div>
  </div>
</template>

<script>
import _ from 'lodash';
import TheFilter from './TheFilter';

export default {
    components: {
        TheFilter,
    },

    props: ['module'],

    data() {
        return {
            current: null,
            currentItem: null,
        }
    },

    computed: {
        items() {
            if (this.module && this.module.items) {
                return this.module.items
            }
            return []
        },

        filters() {
            if (this.items != []) {
                return this.items.map(item => {
                    return item.tab_text
                })
            }
            return []
        }
    },

    methods: {
        onChangeFilter(filter) {
            this.current = filter;
        },
    },

    watch: {
        current(val) {
            const self = this;

            this.$anime({
                targets: this.$refs.tabContent,
                opacity: [1, 0],
                easing: 'easeOutSine',
                duration: 300,
                complete: () => {
                    self.currentItem = _.find(self.items, { tab_text: val });

                    self.$anime({
                        targets: this.$refs.tabContent,
                        opacity: [0, 1],
                        easing: 'easeOutSine',
                        duration: 300,
                    })
                }
            })
        }
    },

    mounted() {
        this.current = this.module.items ? this.module.items[0].tab_text : '';
    }
}
</script>

<style lang="scss" scoped>
.inner {
    @include maxWidth;
    flex-direction: column !important;
    align-items: stretch;
    @include inner;
    padding-bottom: 50px;
}

.image-wrap {
    margin-top: 20px;
    margin-bottom: 40px;

    @include createQuery($sr_w_md) {
        max-width: 171px;
        margin-bottom: 20px;

        .image {
            width: 100%;
            height: auto;
        }
    }
}

.title {
    font-size: 25px;
    line-height: 1.24;
    letter-spacing: 0.78px;
    max-width: 564px;
    margin-bottom: 20px;
}

.copy {
    @include defaultCopyStyles;
}
</style>