<template>
    <div class="icons">
        <div class="inner">
            <template v-for="(item, index) in items">
                <IconListItem :item="item" :key="index" />
            </template>
        </div>
    </div>
</template>

<script>
import IconListItem from './IconListItem';

export default {
    components: {
        IconListItem,
    },

    props: ['module'],

    computed: {
        items() {
            if (this.module && this.module.items) {
                return this.module.items
            }
            return []
        }
    }
}
</script>

<style lang="scss" scoped>
.inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;
    flex-direction: column;
    margin-bottom: 100px;

    @include createQuery($sr_w_md) {
        margin-bottom: 75px;
    }

    @include printMaxWith;
}
</style>