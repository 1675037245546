<template>
    <ArticlePage 
        v-if="uid" 
    />
    <NewsPage v-else />
</template>

<script>
import ArticlePage from './ArticlePage';
import NewsPage from './NewsPage';

export default {
    computed: {
        uid() {
            const { uid } = this.$route.params;
            return uid;
        }
    },

    components: {
        ArticlePage, NewsPage,
    }
}
</script>

<style>

</style>