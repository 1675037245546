<template>
  <div class="profile-list">
    <div class="profile-list__inner">
      <div class="profile-list__content">
        <ProfileSmall
          v-for="profile in profiles"
          :key="`${filter}-${profile.uid}`"
          class="profile-list__item"
          :profile="profile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileSmall from '../../elements/bio/ProfileSmall';
export default {
  props: ['profiles', 'filter'],
  components: {
    ProfileSmall
  },
};
</script>

<style lang="scss" scoped>
.profile-list {
  $root: &;
  position: relative;

  @include createQuery($sr_w_xs) {
    margin-bottom: 20px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    padding-left: 100px;
    padding-right: 200px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    #{$root}__item {
      display: block;
      max-width: 284px;
      width: 100%;
      margin-right: 16px;
      margin-bottom: 71px;

      @include createQuery($sr_w_xs) {
        margin-bottom: 50px;
      }
    }
  }  
}
</style>
