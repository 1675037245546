/**
 * To learn more about Link Resolving check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/link-resolving
 */

export default function (doc) {
    if (doc.isBroken) {
        console.warn('doc is broken', doc);
        return '/not-found';
    }

    if (['basic_page'].includes(doc.type)) {
        return `/${doc.uid}`;
    }

    if (doc.type === 'landing_page' || doc.type === 'settings') {
      return '/'
    }

    if (doc.type === 'leadership') {
      return '/leadership'
    }

    if (doc.type === 'bio') {
      return `/bio/${doc.uid}`;
    }

    if (doc.type === 'news_article') {
        return `/news/${doc.uid}`;
    }
    
    if (doc.type === 'news_page') {
      return `/news`;
    }
    //
    // if (doc.type === 'press_release') {
    //   // if (doc.tags && Array.isArray(doc.tags) && doc.tags.includes('Housing Report')) return `/housing-report/${doc.uid}`;
    //   return `/press-release/${doc.uid}`;
    // }
    //
    // if (doc.type === 'page') {
    //   return `/page/${doc.uid}`;
    // }
    //
    // if (doc.type === 'topic') {
    //   return `/topic/${doc.uid}`;
    // }

    // console.warn('doc could not be resolved', doc);

    return '/not-found';
}
