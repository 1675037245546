<template>
  <div class="join-remax">
    <div class="join-remax__inner">
      <div class="join-remax__container">
        <div class="join-remax__shadow">
        </div>
        <div class="join-remax__wrapper">
          <div class="join-remax__left">
            <h3 class="join-remax__title u-f-montserrat-black">{{ title }}</h3>
            <div class="join-remax__description" v-html="theCopy"></div>
          </div>
          <div class="join-remax__right" v-for="(button, index) in buttons" :key="index">
            <TheButton 
              :to="button.button_link" 
              :label="button.button_text" 
              :animationDelay="1000" 
              color="green"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '../../elements/buttons/TheButton';
export default {
  props: ['buttons', 'title', 'copy'],
  components: {
    TheButton,
  },
  computed: {
    theCopy() {
      return this.$prismic.asHtml(this.copy)
    }
  }
};
</script>

<style lang="scss" scoped>
.join-remax {
  $root: &;
  display: block;
  width: 100%;
  padding: 72px 0;

  &__inner {
    position: relative;
    @include maxWidth;
    // padding: 0 100px;

    @include createQuery($sr_w_md) {
      // padding: 0 84px 0 100px;
    }

    @include createQuery($sr_w_xs) {
      // padding: 0 16px 0 32px;
    }

    #{$root}__shadow {
      display: block;
      position: absolute;
      left: -16px;
      top: -16px;
      width: 100%;
      height: 100%;
      border: 3px solid #d7d7d7;
      z-index: 95;

      @include createQuery($sr_w_md) {
        left: -16px;
        top: -16px;
      }
    }

    #{$root}__container {
      position: relative;
      width: 100%;
    }

    #{$root}__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 34px 0 38px;
      background: $c_off_white;
      z-index: 96;
    }

    #{$root}__left {
      flex: 1;
      max-width: 700px;
      padding-left: 10%;
      padding-right: 5%;
    }

    #{$root}__right {
      margin-top: 20px;
      flex: 1;
      max-width: 300px;
      padding-left: 10%;
      padding-right: 5%;
    }
  }

  &__title {
    display: block;
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 25px;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      margin-bottom: 8px;
      font-size: 18px;
    }
  }

  &__description {
    @include createQuery($sr_w_xs) {
      font-size: 13px;
    }
  }
}
</style>
