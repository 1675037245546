<template>
  <main class="page page__content error-page">
    <p class="error-page__content u-f-montserrat-semibold"><strong class="u-f-montserrat-black">ERROR 404</strong>PAGE
      NOT
      FOUND
    </p>
    <div class="error-page__actions">
      <TheLink className="error-page__link" to="/" label="back to homepage"/>
    </div>
  </main>
</template>

<script>
import TheLink from "../components/elements/buttons/TheLink";

export default {
  name: 'NotFound',
  components: {
    TheLink,
  },
  created () {
    this.loadPage();
  },
  methods: {
    async loadPage () {
      this.$emit('updateHead');
    },
  }
}
</script>

<style lang="scss">
.error-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__link {
    text-decoration: none;
  }

  &__content {
    white-space: nowrap;
    color: $c_ship_gray;
    padding: 60px 0 0 0;

    strong {
      color: $c_cobalt;

      &:after {
        content: "";
        display: inline-block;
        width: 1px;
        background: $c_silver_sand;
        height: 40px;
        vertical-align: middle;
        margin: 0 1em;
      }
    }
  }

  &__actions {
    padding: 30px 0 60px 0;
    text-align: center;
  }
}
</style>
