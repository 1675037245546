<template>
  <div
    class="article-quote"
    :class="className"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-quote__inner">
      <div class="article-quote__wrapper" ref="wrapper">
        <div class="article-quote__lines article-quote__lines--top">
          <div class="article-quote__line article-quote__line--left" ref="topLeftLine"></div>
          <div class="article-quote__line article-quote__line--right" ref="topRightLine"></div>
        </div>
        <quoteIcon class="article-quote__icon" ref="icon" />
        <div class="article-quote__text u-f-opensans-light" ref="content">{{ description }}</div>
        <div class="article-quote__byline u-f-bebas-neue-regular" ref="byline">{{ byline }}</div>
        <div class="article-quote__lines article-quote__lines--bottom">
          <div class="article-quote__line article-quote__line--full" ref="bottomLine"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import quoteIcon from '@/assets/svg/icon-quote-marks.svg';
export default {
  props: [
    'className',
    'description',
    'byline'
  ],
  components: {
    quoteIcon,
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      // this.$refs.icon.style.opacity = 0;
      // this.$refs.topLeftLine.style.width = '0%';
      // this.$refs.topRightLine.style.width = '0%';
      // this.$refs.bottomLine.style.width = '0%';

      // icon scales in
      this.$anime({
          targets: this.$refs.icon,
          scale: [0, 1],
          opacity: [0, 1],
          duration: 300,
          delay: 600,
          easing: 'easeOutSine',
          complete:()=>{

            // lines are drawn
            this.$anime({
              targets: [this.$refs.topLeftLine, this.$refs.topRightLine],
              width: ['0%', '50%'],
              easing: 'easeOutSine',
              duration: 800,
              complete: () => {

                // content
                this.$anime({
                  targets: this.$refs.content,
                  opacity: [0, 1],
                  translateY: ['20px', 0],
                  easing: 'easeOutSine',
                  duration: 600,
                  complete: () => {

                    // byline
                    this.$anime({
                      targets: this.$refs.byline,
                      opacity: [0, 1],
                      translateY: ['20px', 0],
                      easing: 'easeOutSine',
                      delay: 200,
                      duration: 600,
                    });

                  }
                });

              }
            });

            this.$anime({
              targets: this.$refs.bottomLine,
              width: ['0%', '100%'],
              easing: 'easeOutSine',
              duration: 800,
            });

          }
        });

      // this.$anime({
      //   targets: this.$refs.wrapper,
      //   opacity: [0, 1],
      //   translateY: ['10px', 0],
      //   easing: 'easeOutSine',
      //   delay: 600,
      //   duration: 600,
      // });
      //
      // this.$anime({
      //   targets: this.$refs.title,
      //   opacity: [0, 1],
      //   translateY: ['10px', 0],
      //   easing: 'easeOutSine',
      //   delay: 900,
      //   duration: 600,
      // });
      //
      // this.$anime({
      //   targets: this.$refs.content,
      //   opacity: [0, 1],
      //   translateY: ['10px', 0],
      //   easing: 'easeOutSine',
      //   delay: 1200,
      //   duration: 600,
      // });

      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.article-quote {
  position: relative;
  margin-top: 70px;
  margin-bottom: 70px;
  width: 100%;

  @include createQuery($sr_w_xs) {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  &__lines {
    position: absolute;
    width: 100%;
    left: 0;

    &--top {
      top: 0;
    }

    &--bottom {
      bottom: 0;
    }
  }

  &__line {
    position: absolute;
    border-top: 1px solid lighten($c_ship_gray, 50%);

    &--left {
      right: 50%;
      /*width: 50%;*/
      width: 0;
    }

    &--right {
      left: 50%;
      /*width: 50%;*/
      width: 0;
    }

    &--full {
      left: 50%;
      /*width: 100%;*/
      width: 0;
      transform: translate(-50%, 0);
    }
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;
    flex-direction: column;

    @include printMaxWith;
  }

  &__link-wrap {
    text-decoration: none;
  }

  &__wrapper {
    position: relative;
    margin-right: 16px;
    padding: 54px 84px 39px;
    width: 100%;
    /*border-bottom: 1px solid lighten($c_ship_gray, 50%);*/

    /*opacity: 0;*/

    @include createQuery($sr_w_xs) {
      padding: 30px 0 22px;
    }
  }

  &__icon {
    position: absolute;
    top: -18px;
    left: calc(50% - 18px);

    opacity: 0;

    @include noAnimationInPrint;
  }

  &__text {
    font-size: 20px;
    line-height: 1.45;
    letter-spacing: 0.5px;
    text-align: center;
    color: $c_ship_gray;

    opacity: 0;

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      font-size: 16px;
    }
  }

  &__byline {
    margin-top: 24px;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: $c_remax_blue;

    opacity: 0;

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      font-size: 12px;
      letter-spacing: 0.86px;
    }
  }
}
</style>
