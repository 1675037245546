<template>
  <div class="social-popup" ref="socialpopup">
    <div class="social-popup__inner">
      <div class="social-popup__content">
        <h2 class="social-popup__title u-f-montserrat-bold">
          {{ title }}
        </h2>
        <div class="social-popup__share">
          <TheLink className="social-popup__share-btn" label="Share" />
        </div>
        <ul class="social-popup__socials">
          <li v-for="(link, index) in links" class="social-popup__social-item" :key="index">
            <a @click="onShare(link)">
              <span class="social-popup__social-icon">
                <component :is="getIcon(link)" :class="link" />
              </span>
              <span class="social-popup__social-label u-f-opensans-semi-bold">{{ link }}</span>
            </a>
          </li>
        </ul>
      </div>
      <closeIcon class="social-popup__close" @click="onCloseModal" />
    </div>
  </div>
</template>

<script>
import facebookIcon from '@/assets/svg/facebook.svg';
import linkedinIcon from '@/assets/svg/linkedin.svg';
import pinterestIcon from '@/assets/svg/pinterest.svg';
import twitterIcon from '@/assets/svg/twitter.svg';
import printIcon from '@/assets/svg/icon-print.svg';
import emailIcon from '@/assets/svg/icon-email.svg';
import closeIcon from '@/assets/svg/icon-x.svg';
import TheLink from '../buttons/TheLink';
export default {
  props: ['title'],
  components: {
    facebookIcon,
    linkedinIcon,
    pinterestIcon,
    twitterIcon,
    printIcon,
    emailIcon,
    closeIcon,
    TheLink
  },
  data() {
    return {
      links: ['facebook', 'twitter', 'email', 'linkedin', 'pinterest', 'print']
    }
  },
  mounted() {
    this.playAnimation();
  },
  methods: {
    onCloseModal() {
      this.$emit('close');
    },
    playAnimation() {
      this
        .$anime({
          targets: this.$refs.socialpopup,
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          delay: 0,
          duration: 450,
        });
    },
    getIcon(title) {
      switch (title) {
        case 'facebook':
          return facebookIcon;
        case 'pinterest':
          return pinterestIcon;
        case 'linkedin':
          return linkedinIcon;
        case 'print':
          return printIcon;
        case 'twitter':
          return twitterIcon;
        case 'email':
          return emailIcon;
      }
    },
    onShare(type) {
      this.$emit(type);
    }
  },
};
</script>

<style lang="scss">
.social-popup {
  $root: &;
  position: fixed;
  top: 0; // stripe height
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px;
  background-color: $c_ship_gray;
  z-index: $z_index_overlay;

  opacity: 0;

  &__inner {
    position: relative;
    @include maxWidth;
    align-items: center;
    width: 100%;
  }

  &__title {
    margin-bottom: 9px;
    font-size: 18px;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_white;
  }

  &__share {
    display: block;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    #{$root}__share-btn {
      font-size: 10px;
      font-weight: 500;
      padding: 0;
      color: $c_sail;
    }
  }

  &__socials {
    @include resetUL;

    #{$root}__social-item {
      padding: 20px 9px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      > a {
        display: flex;
        align-items: center;
      }

      #{$root}__social-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        margin-right: 10px;
      }

      #{$root}__social-label {
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        color: $c_white;
      }

      svg {
        * {
          fill: $c_white;
          transition: fill $t_normal;
        }

        &.facebook {
          width: 8px;
        }

        &.twitter {
          width: 20px;
        }

        &.email {
          width: 25px;
        }

        &.linkedin {
          width: 16px;
        }

        &.pinterest {
          width: 18px;
        }

        &.print {
          width: 20px;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: $c_white;
    cursor: pointer;
  }

  &__content {
    margin-top: 60px;
    max-width: 100%;
    width: 100%;
  }
}
</style>
