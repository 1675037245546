<template>
  <Story
    className="story-two-middle"
    :category="category"
    :primaryImage="primaryImage"
    :secondaryImage="secondaryImage"
    :title="title"
    :description="description"
    :ctaText="ctaText"
    :ctaLink="ctaLink"
    :buttonStyleCta="buttonStyleCta"
    :noeffect="noeffect"
  />
</template>

<script>
import Story from '../../elements/story/Story';
export default {
  props: ['story', 'smallImage', 'buttonStyleCta', 'noeffect'],
  components: {
    Story
  },
  computed: {
    category() {
      return this.story.category || '';
    },
    primaryImage() {
      if (this.story.image) {
        return this.story.image;
      }
      return null;
    },
    secondaryImage() {
      if (this.smallImage) {
        return this.smallImage;
      }
      if (this.story.smallImage) {
        return this.story.smallImage;
      }
      return null;
    },
    title() {
      return this.story.title || '';
    },
    description() {
      return this.story.description || '';
    },
    ctaText() {
      if (this.story && this.story.cta_text) {
        return this.story.cta_text;
      }
      if (this.story && this.story.ctaText) {
        return this.story.ctaText;
      }
      return ''
    },
    ctaLink() {
      if (this.story && this.story.cta_link) {
        return this.story.cta_link;
      }
      if (this.story && this.story.ctaLink) {
        return this.story.ctaLink;
      }
      return '';
    }
  }
}
</script>

<style lang="scss">
.story {
  $root: &;

  &.story-two-middle {

    #{$root}__header {
      position: relative;
      margin-top: 47px;

      #{$root}__category {
        left: -8.33% !important;
        font-size: 12px !important;
        color: $c_remax_blue !important;
        letter-spacing: 3.75px !important;

        @include createQuery($sr_w_xs) {
          left: 8px !important;
          font-size: 8px !important;
        }
      }

      @include createQuery($sr_w_xs) {
        padding-left: 36px;
      }
    }

    #{$root}__figure {
      &--primary {
        width: 79.13%;

        #{$root}__figure-content {
          padding-bottom: 123.24%;
        }
      }

      &--secondary {
        width: 41.32%;
        margin-left: -20.66%;
        margin-top: 70.33%;
        z-index: 98;

        #{$root}__figure-content {
          padding-bottom: 91.5%;
        }

/*        #{$root}__image-shadow {
          width: calc(100% + 8px);
          height: calc(100% + 8px);
        }*/

      }
    }

    #{$root}__text {
      @include createQuery($sr_w_sm) {
        margin-bottom: 13px;
      }
    }
  }
}
</style>
