<template>
  <table class="table-body">
    <thead class="table-body__header u-f-opensans-bold" v-if="hasHeader">
      <tr>
        <th v-for="(item, index) in head" :key="index">{{ item }}</th>
      </tr>
    </thead>
    <tbody class="table-body__body u-f-opensans-regular">
      <tr v-for="(row, index1) in body" :key="index1">
        <td v-for="(item, index2) in row" :key="index2">{{ item }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// import TextInput from '../inputs/TextInput';
// import TheLink from '../buttons/TheLink';
export default {
  props: ['head', 'body', 'hasHeader'],
  methods: {
    onSearch() {
      this.$emit('search');
    }
  }
};
</script>

<style lang="scss">
.table-body {
  $root: &;
  position: relative;
  width: 100%;
  margin-top: 21.5px;
  border: none;
  border-collapse: collapse;

  &__header {
    border-top: 1px solid $c_ship_gray;
    border-bottom: 1px solid $c_ship_gray;
    font-size: 16px;
    line-height: 1.5;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1.38;
    }

    tr {
      th {
        padding-top: 12.5px;
        padding-bottom: 13.5px;
        padding-left: 17px;
        text-align: left;

        &:first-child {
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }

  &__body {
    font-size: 16px;
    line-height: 1.5;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1.38;
    }

    tr {
      border-bottom: 1px solid $c_silver_sand;

      td {
        padding-top: 12.5px;
        padding-bottom: 13.5px;
        padding-left: 17px;
        text-align: left;

        &:first-child {
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }
}
</style>
