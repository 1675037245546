<template>
  <div
    class="article-callout"
    v-observe-visibility="{
       callback: visibilityChanged,
       once: true,
     }"
  >
    <div class="article-callout__image-wrap" ref="imageWrap">
      <div
        class="article-callout__image"
        ref="image"
        :style="imageBackground"
        v-parallax.watchParent="0.1"
      ></div>
    </div>
    <div class="article-callout__overlay"></div>
    <div class="article-callout__inner">
      <div class="article-callout__wrap">
        <h2 class="article-callout__title u-f-montserrat-black" ref="title">{{ title }}</h2>
        <div class="article-callout__text u-f-opensans-regular" ref="text">
          <prismic-rich-text
            :field="description"
          />
        </div>
      </div>
      <div v-if="items.length > 0" class="article-callout__items" ref="items">
        <div v-for="(item, index) in items" :key="index" class="article-callout__item">
          <div class="article-callout__icon-wrap">
            <img :src="item.image" class="article-callout__icon"/>
          </div>
          <prismic-rich-text
            class="article-callout__item-text u-f-opensans-regular"
            :field="item.text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'description', 'image', 'items'],
  computed: {
    imageBackground () {
      return `background-image:url('${this.image}');`;
    },
  },
  methods: {
    visibilityChanged (isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.imageWrap,
        opacity: [0, 1],
        scale: [1, 1.05],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });

      // {"translateY":["-20px",0],"opacity":1}
      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.items,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 900,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.article-callout {
  $root: &;
  position: relative;
  background: darken($c_regent_gray, 50%);
  overflow: hidden;

  &__image {
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: 0;
    width: 100%;
    /*height: 100%;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__image-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 0; // set in js
  }

  &__overlay {
    @include overlay;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    flex-direction: column;
    padding: 104px 200px 83px;

    @include createQuery($sr_w_md) {
      padding: 78px 17px 36px;
    }

    @include createQuery($sr_w_xs) {
      padding: 52px 32px 36px;
    }

    @include printMaxWith;
  }

  &__wrap {
    position: relative;
    margin-bottom: 35px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 9px;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.33;
    letter-spacing: 0.75px;
    color: $c_white;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.58px;
    }
  }

  &__text {
    margin-top: 9px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 2.06;
    color: $c_white;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 13px;
      line-height: 1.85;
    }

    ::v-deep a {
      color: inherit;
    }

    ::v-deep p {
      margin: 0;
    }
  }

  &__items {
    opacity: 0;

    #{$root}__item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 28px;

      @include createQuery($sr_w_xs) {
        flex-direction: column;
      }

      #{$root}__icon-wrap {
        width: 68px;
        margin-top: 8px;
        margin-right: 16px;
        text-align: center;

        @include createQuery($sr_w_xs) {
          margin-right: 0;
          width: 52px;
        }

        #{$root}__icon {
          width: auto;
          height: 60px;

          @include createQuery($sr_w_xs) {
            margin-right: 0;
            width: 50px;
          }
        }
      }

      #{$root}__item-text {
        flex: 1;
        font-size: 16px;
        line-height: 2.06;
        color: $c_white;

        ::v-deep a {
          color: inherit;
        }

        ::v-deep p {
          margin: 0;
          width: 100%;
        }

        @include createQuery($sr_w_xs) {
          margin-top: 11px;
          font-size: 13px;
          line-height: 1.85;
        }
      }
    }
  }
}
</style>
