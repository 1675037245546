<template>
  <div
    class="article-meta"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-meta__inner">
      <div class="article-meta__author u-f-opensans-regular">
        <span class="article-meta__author-prefix">Written by</span>
        <span class="article-meta__author-name">{{ author.name }}</span>
        <a class="article-meta__link" :href="emailLink">
          <emailIcon />
        </a>
      </div>
      <div class="article-meta__categories">
        <TopicLink
          v-for="(topic, index) in categories"
          :key="index"
          :to="`/topic/${topic.uid}`"
          className="article-meta__categories-item"
          :label="topic.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import emailIcon from '@/assets/svg/icon-email.svg';
import TopicLink from '../../elements/buttons/TopicLink';
export default {
  props: ['author', 'categories'],
  components: {
    emailIcon,
    TopicLink,
  },
  computed: {
    emailLink() {
      return `mailto:${this.author.email}`;
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.article-meta {
  margin-top: 72px;
  margin-bottom: 54px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    @include inner;
    flex-direction: column;
    align-items: flex-start;

    @include printMaxWith;
  }

  &__author {
    display: flex;
    align-self: center;
    width: 100%;
    margin-bottom: 17px;
    font-size: 14px;
    font-style: italic;
  }

  &__author-name {
    margin-left: 5px;
    font-weight: bold;
  }

  &__link {
    margin-left: 10px;
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: -8px;
    margin-right: -8px;

    @include createQuery($sr_w_md) {
      max-width: calc(100% - 84px);
    }

    @include createQuery($sr_w_sm) {
      max-width: 100%;
      margin-left: -4px;
      margin-right: -4px;
    }
  }

  &__categories-item {
    flex-basis: calc(25% - 16px);

    @include createQuery($sr_w_md) {
      flex-basis: calc(33% - 16px);
    }

    @include createQuery($sr_w_sm) {
      flex-basis: calc(50% - 8px);
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}
</style>
