<template>
  <div
    class="article-table"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-table__inner">
      <Collapse :title="title" :defaultState="defaultState">
        <TableAction @search="onSearch"
                     :totalPages="totalPages"
                     @changePage="onPaginate" />
        <div class="article-table__wrap">
          <TableBody
            v-if="data.length > 0"
            :hasHeader="hasHeader"
            :head="getHeaders"
            :body="getRows"
          />
        </div>
        <Pagination
          v-if="data.length > 0 && hasPagination"
          class="article-table__pagination"
          :page="getPage"
          :count="totalPages"
          @onPaginate="onPaginate"
        />
        <GoToPage
          v-if="totalPages > 1"
          class="article-table__go-to-page"
          :totalPages="totalPages"
          @change="onPaginate"
        />
      </Collapse>
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse';
import Collapse from '../../elements/collapse/Collapse';
import TableAction from '../../elements/table/TableAction';
import TableBody from '../../elements/table/TableBody';
import Pagination from '../../elements/common/Pagination';
import GoToPage from '../../elements/table/GoToPage';

export default {
  props: ['url', 'title', 'hasHeader', 'hasPagination', 'rowsPerPage', 'defaultState'],
  components: {
    Collapse,
    TableAction,
    TableBody,
    Pagination,
    GoToPage,
  },
  data() {
    return {
      data: [],
      filtered: [],
      page: 1,
      limit: this.rowsPerPage,
      total: 0
    };
  },
  computed: {
    getRows() {
      if (this.data && this.data.length > 0) {
        return [...this.filtered].splice(((!this.page ? 1 : this.page) - 1) * this.limit, this.limit);
      }
      return [];
    },
    getHeaders() {
      if (this.data && this.data.length > 0) {
        const rows = [...this.data];
        return rows.splice(0, 1)[0];
      }
      return [];
    },
    getPage() {
      return this.page ? parseInt(this.page) : 1;
    },
    totalPages() {
      return Math.ceil(this.total / this.limit);
    },
    totalCount() {
      return this.total;
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
    onPaginate(item) {
      this.page = item;
    },
    onSearch(keyword) {
      let rows = [];
      if (keyword !== '') {
        if (this.hasHeader) {
          rows = [...this.data].splice(1);
        } else {
          rows = [...this.data];
        }
        this.filtered = rows.filter((row) => {
          let hasValue = false;
          row.forEach(element => {
            if (element.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
              hasValue = true;
            }
          });
          return hasValue;
        });
      } else {
        if (this.hasHeader) {
          rows = [...this.data].splice(1);
        } else {
          rows = [...this.data];
        }
        this.filtered = rows;
      }
      this.total = this.filtered.length;
    }
  },
  mounted() {
    Papa.parse(this.url, {
      download: true,
      complete: (results) => {
        this.data = results.data;
        if (this.hasHeader) {
          const rows = [...this.data].splice(1);
          this.filtered = rows;
        } else {
          this.filtered = [...this.data];
        }
        this.total = this.filtered.length;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
.article-table {
  margin-top: 57px;
  margin-bottom: 54px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 20px;
    margin-bottom: 0;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;

    @include printMaxWith;
  }

  &__wrap {
    overflow-x: auto;
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12.5px;
    text-align: right;

    @include createQuery($sr_w_xs) {
      justify-content: center;
    }
  }

  &__go-to-page {
    display: none;

    @include createQuery($sr_w_xs) {
      margin-top: 23px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
