<template>
  <aside class="story__category u-f-opensans-semibold">
    {{ label }}
  </aside>
</template>

<script>
export default {
  name: 'StoryCategory',
  props: ['label']
}
</script>

<style lang="scss">
.story {
  &__category {
    position: absolute;
    left: -42px;
    top: 6px;
    height: 200px;
    font-size: 10px;
    letter-spacing: 2.13px;
    text-align: right;
    text-transform: uppercase;
    text-orientation: mixed;
    writing-mode: tb;
    transform: rotate(-180deg);
    color: $c_remax_blue;

    @include createQuery($sr_w_xs) {
      font-size: 8px;
      letter-spacing: 2.5px;
    }
  }
}
</style>
