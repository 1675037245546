<template>
  <footer class="footer">
    <div class="inner">
      <div class="left">
        <h2 class="footer__title">{{ footerTitle }}</h2>
        <div class="footer__text" v-html="footerText"></div>
        <div class="footer__bottom">
          <div class="bottom-links">
            <TheLink
              v-for="(item, index) in bottomLinks"
              :key="'link_' + index"
              className="footer__link link--light"
              :to="item.link"
              :target="item.target ? item.target : ''"
              :label="item.link_text"
            />
          </div>

          <div class="logos">
            <TheLink
              v-for="(item, index) in logos"
              :key="'logo_' + index"
              :unstyled="true"
              :to="item.logo_link"
            ><img :src="item.logo.url" class="logo" width="25" height="25" :title="item.logo_caption ? item.logo_caption : ''" /></TheLink>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="group" v-for="(linkGroup, index) in footerLinks" :key="index">
          <h4 class="group__title" v-if="linkGroup.primary && linkGroup.primary.link_group_title">
            {{ linkGroup.primary.link_group_title[0].text }}
          </h4>
          <ul class="group__list" v-if="linkGroup.items">
            <li v-for="(item, index) in linkGroup.items" :key="index">
              <a class="group__link group__link--social" v-if="isSocialLink(item)" :href="item.link.url" :target="item.link.target" rel="noopener">
                <span class="social-icon"><component :is="getIcon(item.link_text[0].text)" /> </span>
                <span>{{ item.link_text[0].text }}</span>
              </a>
              <TheLink
                v-else
                :unstyled="true"
                className="group__link"
                :to="item.link"
                :target="item.link.target"
                :label="item.link_text[0].text"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import TheLink from '../elements/buttons/TheLink'
import facebookIcon from '@/assets/svg/facebook.svg';
import instagramIcon from '@/assets/svg/instagram.svg';
import linkedinIcon from '@/assets/svg/linkedin.svg';
import pinterestIcon from '@/assets/svg/pinterest.svg';
import twitterIcon from '@/assets/svg/twitter.svg';
import youtubeIcon from '@/assets/svg/youtube.svg';

export default {
  components: {
    TheLink,
    facebookIcon,
    instagramIcon,
    linkedinIcon,
    pinterestIcon,
    twitterIcon,
    youtubeIcon,
  },

  computed: {
    ...mapGetters([
      'getSettings',
      'isLoading',
    ]),

    footerTitle() {
      if (this.getSettings && this.getSettings.footer_title) {
        return this.getSettings.footer_title[0].text;
      }
      return '';
    },

    footerText() {
      if (this.getSettings && this.getSettings.footer_text) {
        return this.$prismic.asHtml(this.getSettings.footer_text);
      }
      return '';
    },

    footerLinks() {
      if (this.getSettings && this.getSettings.body) {
        return this.getSettings.body;
      }
      return [];
    },

    bottomLinks() {
      if (this.getSettings && this.getSettings.bottom_links) {
        return this.getSettings.bottom_links;
      }
      return [];
    },

    logos() {
      if (this.getSettings && this.getSettings.logos) {
        return this.getSettings.logos;
      }
      return [];
    }
  },

  methods: {
    isSocialLink(item) {
      return [
        'facebook',
        'twitter',
        'instagram',
        'youtube',
        'pinterest',
        'linkedin'
      ].includes(item.link_text[0].text.toLowerCase())
    },

    getIcon(title) {
      switch (title.toLowerCase()) {
        case 'facebook':
          return facebookIcon;
        case 'instagram':
          return instagramIcon;
        case 'linkedin':
          return linkedinIcon;
        case 'pinterest':
          return pinterestIcon;
        case 'twitter':
          return twitterIcon;
        case 'youtube':
          return youtubeIcon;
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.footer {
  @include opensans12;
  background: $c_navy;
  color: $c_white;
  
  .inner {
    @include container;
    display: flex;
    flex-direction: column-reverse;

    @include createQuery($sr_w_sm) {
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100% - 40px);
    }

    @include createQuery($sr_w_not_md) {
      flex-direction: row;

      .left,
      .right {
        flex: 1;
      }
    }
  }

  &__title {
    @include montserratSemi19;
    color: $c_accent_green;
    margin-bottom: 10px;

    @include createQuery($sr_w_not_sm) {
      @include montserratSemi23;
    }
  }

  &__text {
    margin-bottom: 24px;
    @include createQuery($sr_w_not_sm) {
      margin-bottom: 40px;
      max-width: 500px;
    }
  }

  @include createQuery($sr_w_not_sm) {
    &__link:not(:first-of-type) {
      margin-left: 40px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;

    @include createQuery($sr_w_sm) {
      align-items: flex-end;

      .logos {
        position: relative;
        top: -12px;
      }
    }
  }
}
.right {
  display: flex;
  flex-wrap: wrap;
  @include createQuery($sr_w_not_sm) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  @include createQuery($sr_w_not_md) {
    margin-top: 40px;
  }
}
.group {
  margin-bottom: 32px;

  &:nth-child(odd) {
    width: calc(50% + 40px);
  }

  &:nth-child(even) {
    width: calc(50% - 40px);
  }

  @include createQuery($sr_w_not_sm) {
    &:nth-child(even),
    &:nth-child(odd) {
      width: auto;
    }
  }

  &__title {
    color: $c_accent_green;
    @include montserratSemi14;
    margin-bottom: 10px;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
  }

  &__link {
    text-decoration: none;
    color: inherit;
    transition: color $t_fast;

    &--social {
      .social-icon {
        display: inline-block;
        width: 20px;
      }

      svg {
        height: 11px;
        width: auto;
        max-width: 11px;
      }
    }

    &:hover {
      color: $c_light_blue;

      path {
        fill: $c_light_blue;
      }
    }
  }
}

.bottom-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include createQuery($sr_w_sm) {
    flex-direction: column;
    align-items: flex-start;

    .link {
      margin-bottom: 16px;
    }
  }
}

.logos {
  margin-left: 40px;
}

.logo {
  margin-right: 40px;
}
</style>
