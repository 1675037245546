<template>
  <div
    class="article-qa"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="article-qa__inner"
    >
      <div class="article-qa__question u-f-opensans-bold" ref="question">
        <div class="article-qa__letter" ref="letterQ">Q</div>
        {{ item.question }}
      </div>
      <div class="article-qa__answer u-f-opensans-regular" ref="answer">
        <div class="article-qa__letter" ref="letterA">A</div>
        {{ item.answer }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items'],
  methods: {
    visibilityChanged (isVisible) {
      if (!isVisible) return;

      // this.$anime({
      //   targets: this.$refs.text,
      //   opacity: [0, 1],
      //   translateY: ['10px', 0],
      //   easing: 'easeOutSine',
      //   delay: 600,
      //   duration: 600,
      // });

      this.$anime({
        targets: this.$refs.question,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.answer,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.letterQ,
        opacity: [0, 1],
        translateX: ['-10px', 0],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.letterA,
        opacity: [0, 1],
        translateX: ['-10px', 0],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 600,
      });

      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.article-qa {
  $root: &;
  margin-top: 160px;
  margin-bottom: 54px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 40px;
    margin-bottom: 23px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;
    flex-direction: column;

    @include printMaxWith;
  }

  &__question {
    position: relative;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.5;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      margin-left: 45px;
      font-size: 13px;
      line-height: 1.85;
    }
  }

  &__letter {
    position: absolute;
    left: -45px;
    top: -16px;
    font-size: 40px;
    font-family: $f_montserrat;
    font-weight: 900;
    letter-spacing: 1.67px;
    color: $c_humming_bird;
  }

  &__answer {
    position: relative;
    margin-top: 16px;
    margin-bottom: 39px;
    font-size: 16px;
    line-height: 2.06;

    opacity: 0;

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      margin-left: 45px;
      font-size: 13px;
      line-height: 1.85;
      margin-bottom: 19px;
    }
  }
}
</style>
