<template>
  <div class="story-inline">
    <TheLink :unstyled="true" :to="ctaLink" className="story-inline__link">
      <StoryImage
        :image="image"
        className="story-inline__figure--primary"
      />
      <div class="story-inline__content">
        <StoryCategory :label="category" ref="category" />
        <h2 class="story-inline__title u-f-montserrat-black" ref="title">{{ title }}</h2>
        <p class="story-inline__text u-f-opensans-regular" ref="description">{{ description }}</p>
        <TheLink className="story-inline__link" :label="ctaText != '' ? ctaText : 'read more'" ref="cta" />
      </div>
    </TheLink>
  </div>
</template>

<script>
import StoryImage from './StoryImage';
import TheLink from '../buttons/TheLink';
import StoryCategory from './StoryCategory';
export default {
  name: 'StorySmall',
  props: [
    'className',
    'category',
    'image',
    'title',
    'description',
    'ctaText',
    'ctaLink',
    'country',
    'hideCategory'
  ],
  components: {
    StoryImage,
    TheLink,
    StoryCategory,
  },
}
</script>

<style lang="scss">
.story-inline {
  position: relative;
  width: 100%;

  &__link {
    text-decoration: none;
    display: flex;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 25px;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      margin-bottom: 7px;
      font-size: 18px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.5;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      margin-top: 7px;
      font-size: 13px;
    }
  }
  &__link {
    margin-top: 30px;
    padding: 0;

    @include createQuery($sr_w_xs) {
      margin-top: 11px;
    }
  }
  &__figures {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
