<template>
  <div class="collapse" ref="collapse">
    <button class="collapse__head" @click="onClickToggle">
      <span class="collapse__head-title u-f-montserrat-black">{{ title }}</span>
      <span class="collapse__head-toggle">
        <arrowDown v-if="!isOpen"/>
        <arrowUp v-if="isOpen"/>
      </span>
    </button>
    <div :class="`collapse__content${isRecalculating ? ' is-recalculating' : ''}${open ? ' is-open' : ''}`"
         ref="content">
      <WatchSlotChange @slotChange="onSlotUpdated">
        <slot></slot>
      </WatchSlotChange>
    </div>
  </div>
</template>

<script>
import arrowDown from '@/assets/svg/arrow-down.svg';
import arrowUp from '@/assets/svg/arrow-up.svg';
import WatchSlotChange from "../../blocks/common/WatchSlotChange";

export default {
  props: ['title', 'defaultState'],
  data () {
    return {
      open: this.defaultState === 'Initially open',
      isRecalculating: false,
      maxHeihgt: 0,
    };
  },
  computed: {
    isOpen () {
      return this.open;
    }
  },
  components: {
    WatchSlotChange,
    arrowDown,
    arrowUp
  },
  methods: {
    onSlotUpdated () {
      this.recalculateComponent();
    },
    onClickToggle () {
      this.open = !this.open;
    },
    playOpenAnimation (dontAnimate) {
      this.$anime.remove(this.$refs.content);
      const maxHeight = this.maxHeight ? this.maxHeight : 1000;
      this.$anime({
        targets: this.$refs.content,
        maxHeight: ['0px', `${maxHeight}px`],
        easing: 'easeInOutSine',
        delay: 0,
        duration: dontAnimate ? 0 : 500,
      });
    },
    playCloseAnimation (dontAnimate) {
      this.$anime.remove(this.$refs.content);
      const maxHeight = this.maxHeight ? this.maxHeight : 1000;
      this.$anime({
        targets: this.$refs.content,
        maxHeight: [`${maxHeight}px`, '0px'],
        easing: 'easeInOutSine',
        delay: 0,
        duration: dontAnimate ? 0 : 500,
      });
    },
    recalculateComponent () {
      this.isRecalculating = true;
      this.$nextTick(() => {
        this.maxHeight = this.$refs.content.clientHeight;
        this.isRecalculating = false;
        if (this.open) {
          this.$refs.content.style.maxHeight = `${this.maxHeight}px`
        }
      });
    }
  },
  watch: {
    open: function (val) {
      if (val) {
        // play open
        this.$nextTick(() => {
          this.playOpenAnimation();
        });
      } else {
        // play close
        this.$nextTick(() => {
          this.playCloseAnimation();
        });
      }
    }
  },
  destroyed () {

    window.removeEventListener('resize', this.recalculateComponent);

  },
  mounted () {

    if (!this.open) {
      this.playCloseAnimation(true);
    }

    window.addEventListener('resize', this.recalculateComponent);
    this.$nextTick(() => {
      this.recalculateComponent();
    });

  }
};
</script>

<style lang="scss">
.collapse {
  width: 100%;

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 22px 15px 16px;
    border-radius: 3px;
    background-color: rgba(164, 215, 244, 0.3);
    border: 0;
    outline: 0;
    cursor: pointer;

    @include createQuery($sr_w_xs) {
      padding: 9px 17px;
    }
  }

  &__head-title {
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.75px;
    color: $c_ship_gray;
    text-align: left;

    @include createQuery($sr_w_xs) {
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.58px;
    }
  }

  &__head-toggle {
    display: block;
    cursor: pointer;
  }

  &__content {
    position: relative;
    /*max-height: 0;*/

    &.is-recalculating {
      max-height: initial !important;
    }
  }
}
</style>
