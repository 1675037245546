<template>
  <aside
    class="article-iframe"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-iframe__inner" ref="content">
      <div :class="getWrapClasses" :style="getWrapStyles">
        <div class="article-iframe__embed" v-html="html"></div>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  props: [
    'html',
    'width',
    'height',
  ],
  computed: {
    getWrapStyles () {
      if (!this.width || isNaN(this.width) || !this.height || isNaN(this.height)) return '';
      else return `padding-bottom:${(this.height / this.width) * 100}%;`;
    },
    getWrapClasses () {
      let theClass = 'article-iframe__wrap';
      if (!this.getWrapStyles) return theClass;
      theClass += ' article-iframe__wrap--ratio';
      return theClass
    },
  },
  methods: {
    visibilityChanged (isVisible) {
      if (!isVisible) return;

      // icon scales in
      this.$anime({
        targets: this.$refs.content,
        opacity: [0, 1],
        duration: 300,
        delay: 600,
        easing: 'easeOutSine',
        complete: () => {

        }
      });
    },
  },
};
</script>

<style lang="scss">
.article-iframe {
  $root: &;

  margin-top: 70px;
  margin-bottom: 70px;
  width: 100%;

  @include createQuery($sr_w_xs) {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;


    @include printMaxWith;
  }

  &__wrap {
    position: relative;
    width: 100%;
    display: block;

    &--ratio {

      iframe,
      #{$root}__embed {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
