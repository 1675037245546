import Vue from 'vue';
import VueRouter from 'vue-router';
import LandingPage from '../views/LandingPage.vue';
import BasicPage from '../views/BasicPage.vue';
import NotFound from '../views/NotFound.vue';
import PrismicPreview from '../views/PrismicPreview.vue';
import BioPage from '../views/BioPage.vue';
import BioDetailPage from '../views/BioDetailPage.vue';
import NewsResolver from '../views/NewsResolver.vue';
import TimelinePage from '../views/TimelinePage.vue';
import SearchPage from "../views/SearchPage";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: LandingPage
    },
    {
        path: '/not-found',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/senior-leadership',
        name: 'leadership',
        alias: '/leadership',
        component: BioPage,
        props: {
            activeTab: 'Senior Leadership',
        }
    },
    {
        path: '/board-of-directors',
        component: BioPage,
        props: {
            activeTab: 'Board of Directors',
        }
    },
    {
        path: '/bio/dan-predovich',
        redirect: { name: 'leadership' }
    },
    {
        path: '/bio/ido-zucker',
        redirect: '/leadership'
    },
    {
        path: '/bio/john-sable',
        redirect: '/leadership'
    },
    {
        path: '/bio/adam-contos',
        redirect: '/leadership'
    },
    {
        path: '/bio/adam-contos2',
        redirect: '/leadership'
    },
    {
        path: '/bio/brett-richie',
        redirect: '/leadership'
    },
    {
        path: '/bio/joseph-desplinter',
        redirect: '/leadership'
    },
    {
      path: '/bio/serene-smith',
      redirect: '/leadership'
    },
    {
      path: '/bio/laura-kelly',
      redirect: '/leadership'
    },
    {
      path: '/bio/jason-romig',
      beforeEnter() {location.href = 'https://news.remax.com/leadership'}
    },
    {
      path: '/bio/roy-schwalm',
      redirect: '/leadership'
    },
    {
      path: '/bio/christopher-alexander',
      beforeEnter() {location.href = 'https://news.remax.com/bio/christopher-alexander'}
    },
    {
      path: '/bio/josh-bolgren',
      beforeEnter() {location.href = 'https://news.remax.com/bio/josh-bolgren'}
    },
    {
      path: '/bio/shawna-gilbert',
      beforeEnter() {location.href = 'https://news.remax.com/bio/shawna-gilbert'}
    },
    {
      path: '/bio/abby-lee',
      beforeEnter() {location.href = 'https://news.remax.com/bio/abby-lee'}
    },
    {
      path: '/bio/amy-lessinger',
      beforeEnter() {location.href = 'https://news.remax.com/bio/amy-lessinger'}
    },
    {
      path: '/bio/andy-schulz',
      redirect: '/leadership'
    },
    {
      path: '/bio/pam-harris',
      redirect: '/leadership'
    },
    {
      path: '/bio/mike-reagan',
      redirect: '/leadership'
    },
    {
      path: '/bio/ronald-harrison',
      redirect: '/leadership'
    },
    {
      path: '/bio/steve-joyce',
      redirect: '/bio/stephen-joyce'
    },
    {
      path: '/bio/serene-smith',
      redirect: '/leadership'
    },
    {
        path: '/bio/:uid',
        name: 'bio-detail',
        component: BioDetailPage
    },
    {
        path: '/news/:uid?',
        name: 'news',
        component: NewsResolver
    },
    {
        path: '/history',
        name: 'timeline',
        component: TimelinePage
    },
    {
        path: '/search/:keyword',
        name: 'search',
        component: SearchPage
    },
    {
        path: '/prismic-preview',
        name: 'prismic-preview',
        component: PrismicPreview
    },
    {
        path: '/:path/:uid',
        name: 'basic-page-with-path',
        component: BasicPage
    },
    {
        path: '/:uid',
        name: 'basic_page',
        component: BasicPage
    },
    {
        path: '*',
        redirect: { name: 'not-found' }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

window.RMXtriggerRouteEvt = to => {
    if (to && to.name === 'prismic-preview') return;
    const event = new CustomEvent('remax_before_route', {detail: to});
    window.dispatchEvent(event);
};

router.beforeEach((to, from, next) => {
    console.warn('route')
    if (from && to.name === from.name && to.path === from.path) {
      // same page no need to trigger before route animation
    } else {
      window.RMXtriggerRouteEvt(to);
    }
    next();
});

export default router;
