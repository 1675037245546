<template>
  <div
    class="timeline"
    :class="`timeline--${theme}`"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 500,
      once: true,
    }"
  >
    <div class="timeline__inner">
      <div class="timeline__left">
        <div class="timeline__primary-image" ref="primaryImage">
          <TimelineImage
            v-if="primaryImage"
            :image="primaryImage"
            :layout="primaryImageLayout"
            class="timeline__figure--primary"
            :class="{'timeline__figure--has-second': secondaryImage}"
            :animateShadow="!secondaryImage"
          />
        </div>
        <div class="timeline__secondary-image" ref="secondaryImage">
          <TimelineImage
            v-if="secondaryImage"
            :image="secondaryImage"
            class="timeline__figure--secondary"
          />
        </div>
      </div>
      <div class="timeline__right">
        <div class="timeline__lines" ref="lines" />
        <div class="timeline__year u-f-montserrat-thin" ref="year">
          {{ year }}
        </div>
        <div class="timeline__content">
          <div class="timeline__content-upper">
            <h3 class="timeline__title u-f-montserrat-black" ref="title">
              {{ title }}
            </h3>
            <div class="timeline__text u-f-opensans-regular" ref="text">
              <prismic-rich-text
                :field="description"
              />
            </div>
          </div>
          <div class="timeline__content-lower">
            <div class="timeline__ads" v-if="isAdCampaign" ref="ad">
              <span class="timeline__ads-tag u-f-opensans-semibold">AD CAMPAIGN</span>
              <span class="timeline__ads-text">
                {{ adsText }}
              </span>
            </div>
            <div class="timeline__event u-f-opensans-semibold" ref="event">
              {{ event }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimelineImage from './TimelineImage';

export default {
  props: ['data', 'visible', 'theme'],
  components: {
    TimelineImage
  },
  computed: {
    year() {
      if (this.data) {
        return parseInt(this.data.year);
      }
      return null;
    },
    title() {
      if (this.data) {
        return this.data.title
      }
      return '';
    },
    description() {
      if (this.data) {
        return this.data.description;
      }
      return '';
    },
    isAdCampaign() {
      if (this.data) {
        return this.data.adCampaign;
      }
      return false;
    },
    adsText() {
      if (this.data) {
        return this.data.adCampaignText;
      }
      return '';
    },
    event() {
      if (this.data) {
        return this.data.event;
      }
      return '';
    },
    primaryImage() {
      if (this.data) {
        return this.data.primaryImage;
      }
      return null;
    },
    primaryImageLayout() {
      if (this.data) {
        return this.data.primaryLayout;
      }
      return null;
    },
    secondaryImage() {
      if (this.data) {
        return this.data.secondaryImage;
      }
      return null;
    }
  },
  methods: {
    onNext() {},
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.animateSlideIn();
      }
    },
    animateSlideIn() {
      this.$anime({
        targets: this.$refs.primaryImage,
        opacity: [0, 1],
        translateY: ['30px', 0],
        easing: 'linear',
        delay: 100,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.secondaryImage,
        opacity: [0, 1],
        easing: 'linear',
        translateY: ['30px', 0],
        delay: 100,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.year,
        opacity: [0, 1],
        translateY: ['30px', 0],
        easing: 'linear',
        delay: 300,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['30px', 0],
        easing: 'linear',
        delay: 500,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['30px', 0],
        easing: 'linear',
        delay: 700,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.ad,
        opacity: [0, 1],
        translateY: ['30px', 0],
        easing: 'linear',
        delay: 900,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.event,
        opacity: [0, 1],
        translateY: ['30px', 0],
        easing: 'linear',
        delay: 900,
        duration: 300,
      });

      const lineHeight = this.$refs.lines.clientHeight;

      this.$anime({
        targets: this.$refs.lines,
        opacity: [0, 1],
        translateY: ['10px', 0],
        height: [0, lineHeight],
        easing: 'linear',
        delay: 500,
        duration: 300,
      });
    },
    animateSlideOut() {
      this.$anime({
        targets: this.$refs.primaryImage,
        opacity: [1, 0],
        translateY: [0, '-300px'],
        easing: 'linear',
        delay: 0,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.secondaryImage,
        opacity: [1, 0],
        translateY: [0, '-300px'],
        easing: 'linear',
        delay: 0,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.year,
        opacity: [1, 0],
        translateY: [0, '-300px'],
        easing: 'linear',
        delay: 0,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.title,
        opacity: [1, 0],
        translateY: [0, '-300px'],
        easing: 'linear',
        delay: 0,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [1, 0],
        translateY: [0, '-300px'],
        easing: 'linear',
        delay: 0,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.ad,
        opacity: [1, 0],
        translateY: [0, '-300px'],
        easing: 'linear',
        delay: 0,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.event,
        opacity: [1, 0],
        translateY: [0, '-300px'],
        easing: 'linear',
        delay: 0,
        duration: 300,
      });

      this.$anime({
        targets: this.$refs.lines,
        opacity: [1, 0],
        translateY: [0, '-300px'],
        easing: 'linear',
        delay: 0,
        duration: 300,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  $root: &;
  min-height: 85vh;
  display: flex;
  align-items: center;

  @include createQuery($sr_w_xs) {
    min-height: initial;
    margin-bottom: 50px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    padding-left: 100px;
    padding-right: 100px;

    @include printMaxWith;

    #{$root}__left {
      position: relative;
      padding-left: 100px;
      max-width: 400px;
      width: 100%;

      @include createQuery($sr_w_xs) {
        max-width: 100%;
        padding: 0;
      }
    }

    #{$root}__right {
      position: relative;
      max-width: 600px;
      width: 100%;
      padding-left: 20px;

      @include createQuery($sr_w_xs) {
        max-width: 100%;
        padding: 0;
      }
    }
  }

  &__year {
    margin-bottom: 40px;
    font-size: 75px;
    line-height: 1.07;
    color: $c_sail;

    opacity: 0;

    @include createQuery($sr_w_xs) {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 40px;
    }

    @at-root #{$root}--light-blue & {
      color: $c_cobalt;
    }

    @at-root #{$root}--grey & {
      color: $c_alizarin_crimson;
    }

    @at-root #{$root}--white & {
      color: $c_cobalt;
    }
  }

  &__lines {
    position: absolute;
    top: 130px;
    left: -140px;
    width: 200px;
    height: calc(100% - 130px);
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: $c_white;
    z-index: $z_index_back;

    opacity: 0;

    @include createQuery($sr_w_xs) {
      top: 65px;
      left: -178px;
      height: calc(100% - 65px);
    }

    @at-root #{$root}--white & {
      border-color: $c_alizarin_crimson;
    }
  }

  &__primary-image {
    opacity: 0;
  }

  &__secondary-image {
    opacity: 0;
  }
  // &__figure {
  //   &--primary {
  //     opacity: 0;
  //   }

  //   &--secondary {
  //     opacity: 0;
  //   }
  // }

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 272px;
    padding-left: 84px;

    @include createQuery($sr_w_xs) {
      padding-left: 54px;
      min-height: initial;
    }

    &-upper {
      flex: 1;
    }

    &-lower {
      /** */
    }

    #{$root}__title {
      margin: 0 0 9px 0;
      font-size: 25px;
      line-height: 1.24;
      letter-spacing: 0.78px;
      color: $c_white;

      opacity: 0;

      @include createQuery($sr_w_xs) {
        font-size: 18px;
      }

      @at-root #{$root}--white & {
        color: $c_ship_gray;
      }
    }

    #{$root}__text {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: normal;
      color: $c_white;

      opacity: 0;

      @include createQuery($sr_w_xs) {
        font-size: 13px;
      }

      @at-root #{$root}--light-blue & {
        color: $c_ship_gray;
      }

      @at-root #{$root}--grey & {
        color: $c_ship_gray;
      }

      @at-root #{$root}--white & {
        color: $c_ship_gray;
      }
    }

    #{$root}__event {
      margin-top: 40px;
      font-size: 12px;
      letter-spacing: 3.75px;
      line-height: normal;
      text-transform: uppercase;
      color: $c_sail;

      opacity: 0;

      @include createQuery($sr_w_xs) {
        margin-top: 13px;
        font-size: 8px;
      }

      @at-root #{$root}--light-blue & {
        color: $c_cobalt;
      }

      @at-root #{$root}--grey & {
        color: $c_alizarin_crimson;
      }

      @at-root #{$root}--white & {
        color: $c_cobalt;
      }
    }

    #{$root}__ads {
      margin-left: -67px;

      @include createQuery($sr_w_xs) {
        margin-left: -53px;
      }

      opacity: 0;

      &-tag {
        padding: 5px 10px;
        margin-right: 20px;
        font-size: 12px;
        letter-spacing: 3.75px;
        color: $c_lochmara;
        background-color: $c_sail;

        @include createQuery($sr_w_xs) {
          margin-right: 10px;
          font-size: 8px;
        }

        @at-root #{$root}--blue & {
          background-color: $c_biscay;
          color: $c_white;
        }

        @at-root #{$root}--light-blue & {
          background-color: $c_tall_poppy;
          color: $c_white;
        }

        @at-root #{$root}--grey & {
          background-color: $c_blue_ribbon;
          color: $c_white;
        }

        @at-root #{$root}--white & {
          background-color: $c_alizarin_crimson;
          color: $c_white;
        }
      }

      &-text {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: normal;
        color: $c_white;

        @include createQuery($sr_w_xs) {
          font-size: 13px;
        }

        @at-root #{$root}--light-blue & {
          color: $c_ship_gray;
        }

        @at-root #{$root}--grey & {
          color: $c_ship_gray;
        }

        @at-root #{$root}--white & {
          color: $c_ship_gray;
        }
      }
    }
  }
}
</style>
