<template>
  <div
    class="section"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="inner">
      <div class="top">
        <StoryTwoMiddle
          class="right-story"
          v-if="rightStory"
          :story="rightStory"
          :noeffect="!getIsDesktop"
        />
        <div class="copy" v-html="copy"></div>
        <div class="lines"></div>
      </div>
      <div class="bottom">
        <StoryOne
          class="left-story"
          v-if="leftStory"
          :story="leftStory"
          :noeffect="true"
        />
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StoryOne from '../common/StoryOne';
import StoryTwoMiddle from '../common/StoryTwoMiddle';

export default {
  props: ['module'],

  components: {
    StoryOne,
    StoryTwoMiddle,
  },

  computed: {
    ...mapGetters([
      'getIsDesktop'
    ]),
    copy() {
      if (this.module.primary && this.module.primary.copy) {
        return this.$prismic.asHtml(this.module.primary.copy)
      }
      return null
    },
    rightStory() {
      if (this.module.primary) {
        return {
          image: this.module.primary.right_story_image ? this.module.primary.right_story_image.url : '',
          smallImage: this.module.primary.right_story_small_image ? this.module.primary.right_story_small_image.url : '',
          title: this.module.primary.right_story_title ? this.module.primary.right_story_title : '',
          description: this.module.primary.right_story_description ? this.$prismic.richTextAsPlain(this.module.primary.right_story_description) : '',
          cta_link: this.module.primary.right_story_link ? this.module.primary.right_story_link : null
        }
      }
      return {}
    },
    leftStory() {
      if (this.module.primary) {
        return {
          image: this.module.primary.left_story_image ? this.module.primary.left_story_image.url : '',
          title: this.module.primary.left_story_title ? this.module.primary.left_story_title : '',
          description: this.module.primary.left_story_description ? this.$prismic.richTextAsPlain(this.module.primary.left_story_description) : '',
          cta_link: this.module.primary.left_story_link ? this.module.primary.left_story_link : null
        }
      }
      return {}
    }
  } 
}
</script>

<style lang="scss" scoped>
.section {
  padding-bottom: 72px;

  @include createQuery($sr_w_md) {
    padding-bottom: 35px;
  }

  .inner {
    @include maxWidth;
    flex-direction: column;
    @include createQuery($sr_w_md) {
      @include inner;      
    }
  }

  .top {
    @include createQuery($sr_w_not_md) {
      position: relative;
      padding-left: 200px;
      padding-right: 242px;
    }

    @include createQuery($sr_w_md) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .copy {
    @include defaultCopyStyles;
    @include createQuery($sr_w_md) {
      margin-bottom: 40px;
    }
  }

  .right-story {
    width: calc(100% + 10px);
    margin-left: -10px;
    max-width: 523px;
    margin-bottom: 35px;
    
    @include createQuery($sr_w_not_md) {
      shape-outside: border-box;
      padding-left: 40px;
      padding-top: 40px;
      margin-top: 110px;
      margin-right: -200px;
      margin-bottom: 0;
      float: right;
      margin-left: 50px;
    }

    .story__image-shadow {
      background: red !important;
    }

    .story__title {
      margin-bottom: 8px;
    }
  }

  .bottom {
    width: 100%;
    max-width: 600px;
  }

  .left-story {
    width: calc(100% + 10px);
    max-width: 523px;
    margin-left: -10px;

    @include createQuery($sr_w_not_md) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-top: -300px;
    }
  }

  .lines {
    position: absolute;
    top: calc(100% - 450px);
    left: 200px;
    width: 520px;
    height: 280px;
    border-top: 2px solid $c_light_blue;
    border-right: 2px solid $c_light_blue;
    z-index: 10000;

    @include createQuery($sr_w_md) {
      display: none;
    }
  }
}
</style>

<style lang="scss">
  .copy p {
    @include createQuery($sr_w_not_md) {
      margin-bottom: 33px;
      margin-right: 50px;
    }
  }

  .right-story .story__figure:not(.story__figure--secondary) .story__image-shadow{
    background: transparent !important;
  }

  .left-story .story__title,
  .right-story .story__title {
    margin-bottom: 8px;
  }

  .right-story.story.story-two-middle {
    @include createQuery($sr_w_md) {
      .story__header {
        margin-top: 0;
        padding-left: 0;
      }
    }
  }

  .left-story.story.story-one  {
    @include createQuery($sr_w_md) {
      .story__header {
        margin-top: 22px;
        padding-left: 0;
      }
    }
  }
</style>