<template>
  <div class="select" :class="className">
    <label for="" class="label" v-if="label != ''">{{ label }}</label>
    <select class="element" name="" id="" v-model="value" @change="onChange"> 
      <option value="">{{ placeholder ? placeholder : 'Select' }}</option>
      <option 
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >{{ option.text }}</option>
    </select>
    <div class="arrow">
      <Arrow />
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/svg/selectarrow.svg';
export default {
  components: { Arrow },

  props: ['label', 'options', 'default', 'placeholder', 'className'],

  data() {
    return {
      value: this.default ?? '',
    }
  },

  methods: {
    onChange() {
      this.$emit('change', this.value);
    }
  },

  mounted() {
    if (this.default) {
      this.onChange(this.default);
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  position: relative;
  display: flex;
  flex-direction: column;
}
.label {
  @include opensansSemi8;
  margin-bottom: 4px;
}
.element {
  // border: 1px solid $c_light_gray;
  border: 0;
  outline: 0;
  background: transparent;
  border-image: none;
  outline-offset: -2px;
  border-color: transparent;
  outline-color: transparent;
  box-shadow: none;
  -webkit-appearance: none;
  @include opensans12-12;
  border: 1px solid $c_light_gray;
  border-radius: 3px;
  padding: 6px 24px 6px 8px;
  color: $c_mid_gray;
  z-index: 1;
}
.arrow {
  position: absolute;
  bottom: 2px;
  right: 6px;
}
</style>