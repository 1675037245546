<template>
  <div class="table-action">
    <form class="table-action__left" @submit="onSearch">
      <TextInput className="table-action__search u-f-opensans-regular" placeholder="Search" @updated="onSearchChange" :value="keyword" />
      <TheLink type="submit" className="table-action__submit link--blue" label="search" />
    </form>
    <div class="table-action__right" v-if="totalPages > 1">
      <GoToPage :totalPages="totalPages" @change="onChangePage" />
    </div>
  </div>
</template>

<script>
import TextInput from '../inputs/TextInput';
import TheLink from '../buttons/TheLink';
import GoToPage from './GoToPage';

export default {
  props: ['totalPages'],
  data() {
    return {
      keyword: '',
      goToPage: ''
    };
  },
  components: {
    TextInput,
    TheLink,
    GoToPage
  },
  computed: {
    isChangePageValid () {
      if (!this.goToPage) return true;
      const numberGoTo = parseInt(this.goToPage);
      if (numberGoTo === 0) return false;
      if (isNaN(numberGoTo)) return false;
      if (numberGoTo !== parseFloat(this.goToPage)) return false;
      return numberGoTo <= this.totalPages;
    }
  },
  methods: {
    onSearch(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('search', this.keyword);
      this.goToPage = '';
      this.$emit('changePage', this.goToPage);
    },
    onSearchChange (val) {
      this.keyword = val;
      // if empty, reset
      if (val === '') this.$emit('search', this.keyword);
    },
    onChangePage (val) {
      this.goToPage = val;
      if (this.isChangePageValid) this.$emit('changePage', this.goToPage);
    }
  }
};
</script>

<style lang="scss">
.table-action {
  $root: &;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 21px;

  &__left {
    flex: 1;
    display: flex;
    max-width: 500px;
    max-height: 30px;
  }

  &__right {
    flex: 1;

    .go-to-page {
      justify-content: flex-end;
    }

    @include createQuery($sr_w_xs) {
      display: none;
    }
  }

  &__search {
    flex: 1;
    padding: 7px 16px 3px;
  }

  &__submit {
    padding: 0 16px 0 0;
    margin-left: 16px;
    margin-right: 13px;
  }
}
</style>
