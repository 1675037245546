<template>
  <TheLink :to="to" :label="label" :type="type" :className="classNames" @click="onClick" v-if="!isLoadable" />
  <TheLink :to="to" :type="type" :className="classNames" :disabled="isAnimating" @click="onClick" v-else>
    <span class="topic-link-loader" ref="animatedDots">
      <span class="topic-link-loader__dot"></span>
      <span class="topic-link-loader__dot"></span>
      <span class="topic-link-loader__dot"></span>
    </span>
    <span class="topic-link-loader__label" ref="label">{{isDisplayingDoneLoadingText && doneLoadingText ? doneLoadingText : label}}</span>
  </TheLink>
</template>

<script>
import TheLink from './TheLink';
export default {
  data(){
    return {
      isAnimating: false,
      isDisplayingDoneLoadingText: false,
      startedLoadingTime: null,
    }
  },
  props: ['to', 'label', 'className', 'type', 'loading', 'isLoadable', 'doneLoadingText'],
  components: {
    TheLink
  },
  computed: {
    classNames() {
      return `link--topic ${this.className}${this.isLoadable ? ' link--loadable' : ''}${this.loading ? ' link--animating' : ''}`;
    }
  },
  watch: {
    loading: function (val) {
      if (val === true) this.$nextTick(() => {

        this.isDisplayingDoneLoadingText = false;
        this.startedLoadingTime = new Date();

        this.$anime.remove(this.$refs.label);
        this.$anime.remove(this.$refs.animatedDots);

        // this.$refs.label.style.opacity = 1;
        // this.$refs.animatedDots.style.opacity = 0;
        // this.$refs.label.style.transform = 'translateY(0px)';
        // this.$refs.animatedDots.style.transform = 'translateY(-10px)';

        // this.$nextTick(() => {
        //   this.isAnimating = true;
        // });

        this
          .$anime({
            targets: this.$refs.label,
            opacity: [1, 0],
            translateY: [0, 10],
            easing: 'easeOutSine',
            duration: 300,
            complete: () => {

              this.isAnimating = true;

            }
          });

        this
          .$anime({
            targets: this.$refs.animatedDots,
            opacity: [0, 1],
            translateY: [-10, 0],
            easing: 'easeOutSine',
            duration: 300,
          });

      });
      else {

        const now = new Date();
        const timeDiff = now - this.startedLoadingTime;
        const animationDiff = 300 - timeDiff;

        window.setTimeout(() => {

          this.$anime.remove(this.$refs.label);
          this.$anime.remove(this.$refs.animatedDots);

          this.isDisplayingDoneLoadingText = true;

          this
            .$anime({
              targets: this.$refs.label,
              opacity: [0, 1],
              translateY: [10, 0],
              easing: 'easeOutSine',
              duration: 300,
              delay: 500,
            });

          this
            .$anime({
              targets: this.$refs.animatedDots,
              opacity: [1, 0],
              translateY: [0, -10],
              easing: 'easeOutSine',
              duration: 300,
              delay: 500,
              complete: () => {

                this.isAnimating = false;

                this.$anime.timeline()
                  .add({
                    targets: this.$refs.label,
                    opacity: [1, 0],
                    easing: 'easeOutSine',
                    duration: 150,
                    delay: 1500,
                    complete: () => {

                      this.isDisplayingDoneLoadingText = false;

                    }
                  })
                  .add({
                    targets: this.$refs.label,
                    opacity: [0, 1],
                    easing: 'easeOutSine',
                    duration: 150,
                    // complete: () => {
                    // this.isAnimating = false;
                    // }
                  });

              }
            });

        }, animationDiff > 0 ? animationDiff : 5);

      }
    }
  },
  methods: {

    onClick () {
      this.$emit('click');
    },

  }
}
</script>

<style lang="scss">
.topic-link-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;

  &__label {
    position: relative;
    display: block;
    width: 100%;
  }

  &__dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 5px 0 0;
    border-radius: 50%;
    animation-name: bounceLoaderSmall;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: $c_white;
    opacity: .9;

/*    &:first-of-type {
      background: $c_alizarin_crimson;
    }*/

    &:nth-of-type(2) {
      /*background: $c_cobalt;*/
      animation-delay: .25s;
    }

    &:last-of-type {
      margin-right: 0;
      /*background: $c_tall_poppy;*/
      animation-delay: .51s;
    }
  }
}

@keyframes bounceLoaderSmall {
  25% {
    transform: translateY(-4px);
  }
  75% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
