<template>
  <div>
    <template v-if="!isLoading && timeline.length > 0">
      <Hero @next="onNext" />
      <div class="timeline-main" ref="main">
        <TimelineDecade
          v-for="(decade, index) in timelines"
          :ref="`decade_${decade.decade}`"
          :key="`decade_${index}`"
          :data="decade"
          :decades="decades"
          :current="currentYear"
          :theme="decade.theme"
          @change="onYearChange"
          @offset="onGetOffsets"
        />
      </div>
      <template v-if="currentYear && decades && decades.length > 0">
        <Navigation
          ref="navigation"
          :current="currentYear"
          :theme="theme"
          :decades="decades"
          :years="years"
          @change="onYearChange"
        />
        <NavigationMobile
          v-if="getResponsiveBreakpoints.xs"
          ref="nav_mobile"
          :current="currentYear"
          :years="years"
          @change="onYearChange"
        />
      </template>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Navigation from './Timeline/Navigation';
import NavigationMobile from './Timeline/NavigationMobile';
import TimelineDecade from './Timeline/TimelineDecade';
import Hero from './Timeline/Hero';

export default {
  props: ['data', 'timeline'],
  components: {
    Hero,
    Navigation,
    NavigationMobile,
    TimelineDecade,
  },
  data() {
    return {
      current: null,
      yearPos: [],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      mainContent: 'getMainContent',
      decades: 'getTimelineDecades',
      years: 'getTimelineYears',
      getResponsiveBreakpoints: 'getResponsiveBreakpoints',
    }),
    currentYear() {
      if (this.current) {
        return this.current;
        // } else if (this.decades.length > 0) {
        //   this.current = this.decades[0];
        //   return this.decades[0];
      }
      return null;
    },
    decade() {
      if (this.current) {
        return this.current - this.current % 10;
      }
      return null;
    },
    currentDecade() {
      if (this.current) {
        const decade = parseInt(this.current) - (parseInt(this.current) % 10);
        const filtered = this.timeline.filter(
          data => parseInt(data.data.decade) === decade
        );
        if (filtered.length > 0) {
          return filtered[0];
        }
        return null;
      }
      return null;
    },
    theme() {
      if (this.currentDecade) {
        const theme = this.currentDecade.data.theme;
        switch (theme) {
          case 'Dark Blue':
            return 'dark-blue';
          case 'Blue':
            return 'blue';
          case 'Light Blue':
            return 'light-blue';
          case 'Grey':
            return 'grey';
          case 'White':
            return 'white';
          default:
            return null;
        }
      }
      return null;
    },
    timelines() {
      if (this.timeline && this.timeline.length > 0) {
        return this.timeline
          .map(data => ({
            decade: data ? parseInt(data.data.decade) : 0,
            theme: data ? this.getTheme(data) : null,
            years:
              data && data.data.body
                ? data.data.body.map(body => ({
                    adCampaign: body.primary.ad_campaign || false,
                    adCampaignText: body.primary.ad_campaign_text || '',
                    description: body.primary.description,
                    event: body.primary.event || '',
                    iconLayout: body.primary.icon_layout || '',
                    title: this.$prismic.richTextAsPlain(body.primary.title1),
                    primaryImage: body.primary.primary_image
                      ? this.getImageUrl(body.primary.primary_image.url)
                      : null,
                    primaryLayout: body.primary.primary_image_layout
                      ? body.primary.primary_image_layout
                      : null,
                    secondaryImage: body.primary.secondary_image
                      ? this.getImageUrl(body.primary.secondary_image.url)
                      : null,
                    year: parseInt(body.primary.year) || 0,
                  }))
                : [],
          }))
          .sort((a, b) => a.decade - b.decade);
      }
      return [];
    },
  },
  methods: {
    onYearChange(year) {
      this.current = year;
      const curIndex = this.yearPos.findIndex(function(elem){
        return elem.year === year;
      });
      if (this.yearPos[curIndex]) {
        this.mainContent.scrollTo(0, this.yearPos[curIndex].pos, 800);
      }
    },
    onGetOffsets(offsets) {
      this.yearPos = _.uniqBy([...this.yearPos, ...offsets], 'year').sort(function(a, b) {
        return a.pos - b.pos;
      });
    },
    onScroll(e) {
      const scroll = e.detail.offset.y;
      if (this.$refs.navigation) {
        this.$refs.navigation.$el.style.top = scroll + 'px';
      }

      if (this.$refs.nav_mobile) {
        this.$refs.nav_mobile.$el.style.top = scroll + window.innerHeight + 'px';
      }

      const offsetScroll = scroll + 400;
      let year;
      for (let it = this.yearPos.length - 1; it >= 0; it--) {
        if (this.yearPos[it].pos < offsetScroll) {
          year = this.yearPos[it].year;
          break;
        }
      }
      if (year && year !== this.current) {
        this.current = year;
      }
    },
    onNext() {
      if (this.$refs.main) {
        const pos = this.$refs.main.offsetTop;
        this.mainContent.scrollTo(0, pos, 800);
        this.current = this.yearPos[0].year;
      }
    },
    nextYear() {
      const curIndex = this.years.indexOf(this.current);
      if (curIndex < this.years.length - 1) {
        this.current = this.years[curIndex + 1];
      }
    },
    prevYear() {
      const curIndex = this.years.indexOf(this.current);
      if (curIndex > 0) {
        this.current = this.years[curIndex - 1];
      }
    },
    getImageUrl(url) {
      if (url && url.indexOf('&rect') > -1) {
        return url.substring(0, url.indexOf('&rect'));
      }
      return url;
    },
    getTheme(decade) {
      if (decade.data) {
        const theme = decade.data.theme;
        switch (theme) {
          case 'Dark Blue':
            return 'dark-blue';
          case 'Blue':
            return 'blue';
          case 'Light Blue':
            return 'light-blue';
          case 'Grey':
            return 'grey';
          case 'White':
            return 'white';
          default:
            return null;
        }
      }
      return null;
    },
  },
  mounted() {
    window.addEventListener('remax_scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('remax_scroll', this.onScroll);
  },
};
</script>

<style lang="scss">
</style>
