import { render, staticRenderFns } from "./ProfileShareSmall.vue?vue&type=template&id=db2e5b52&scoped=true&"
import script from "./ProfileShareSmall.vue?vue&type=script&lang=js&"
export * from "./ProfileShareSmall.vue?vue&type=script&lang=js&"
import style0 from "./ProfileShareSmall.vue?vue&type=style&index=0&id=db2e5b52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db2e5b52",
  null
  
)

export default component.exports