<template>
  <div
    class="article-slideshow"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
    v-parallax="0.05"
  >
    <div class="article-slideshow__wrap"
         ref="slide">
      <div class="article-slideshow__inner">
        <Slideshow
          :title="title"
          :count="photos.length"
          :image="firstImage"
          @view="onViewSlideshow"
        />
      </div>
      <SlidePopup
        :photos="photos"
        :title="title"
        :top="getOffsetTop"
        @close="onCloseSlideshow"
        v-if="isShowSlide"
      />
    </div>
  </div>
</template>

<script>
import Slideshow from '../../elements/slideshow/Slideshow';
import SlidePopup from '../../elements/slideshow/SlidePopup';
export default {
  props: ['photos', 'title'],
  components: {
    Slideshow,
    SlidePopup
  },
  data() {
    return {
      showSlide: false,
      offsetTop: 0
    };
  },
  computed: {
    firstImage() {
      if (this.photos && this.photos.length > 0) {
        return this.photos[1].image;
      }
      return null;
    },
    isShowSlide() {
      return this.showSlide;
    },
    getOffsetTop() {
      return this.offsetTop;
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.slide,
        opacity: [0, 1],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });
    },
    onViewSlideshow() {
      const offsetTop = this.$refs.slide.offsetTop;
      const rect = this.$refs.slide.getBoundingClientRect();
      this.offsetTop = offsetTop - rect.top;
      this.showSlide = true;
    },
    onCloseSlideshow() {
      this.showSlide = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.article-slideshow {
  $root: &;
  position: relative;
  margin-top: 63px;
  margin-bottom: 54px;
  background: $c_white;
  overflow: hidden;

  &__wrap {
    opacity: 0;

    @include noAnimationInPrint;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;

    @include createQuery($sr_w_xs) {
      max-width: 100%;
      padding: 0;
    }

    @include printMaxWith;
  }
}
</style>
