<template>
  <main class="page__content">
    <BioDetailPageRenderer
      :data="data"
      :bio="bio"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import BioDetailPageRenderer from '@/components/blocks/BioDetailPageRenderer';

export default {
  components: {
    BioDetailPageRenderer,
  },
  head: {
    // documentation: https://www.npmjs.com/package/vue-head
    // title: {
    //   inner: 'Bio Detail'
    // },
    meta: function() {
      return [
        { name: 'title', content: this.metaTitle, id: 'title' },
        { name: 'description', content: this.metaDescription, id: 'description' },
        { name: 'keywords', content: this.metaKeywords, id: 'keywords' },

        { property: 'og:description', content: this.metaDescription, id: 'description' },
        { property: 'og:title', content: this.metaTitle, id: 'og:title' },
      ];
    },
    link: function() {
      return [{
        rel: 'canonical',
        href: this.caronical,
        id: 'canonical'
      }];
    }
  },
  computed: {
    ...mapGetters({
      data: 'getData',
      bio: 'getBio'
    }),
    metaTitle() {
      if (this.data) {
        return this.data.meta_title;
      }
      return '';
    },
    metaDescription() {
      if (this.data) {
        return this.data.meta_description;
      }
      return '';
    },
    metaKeywords() {
      if (this.data) {
        return this.data.meta_keywords || '';
      }
      return '';
    },
    caronical() {
      return '';
    }
  },
  created() {
    const { uid } = this.$route.params;
    if (uid) {
      this.loadPage(uid);
    } else {
      this.$router.push('/not-found');
    }
  },
  beforeRouteUpdate (to, from, next) {
    const { uid } = to.params;
    if (uid) {
      this.loadPage(uid);
    } else {
      this.$router.push('/not-found');
    }
    next();
  },
  methods: {
    async loadPage(uid) {
      await this.$store.dispatch('querySingleBio', { uid: uid, vm: this });
      // await this.$store.dispatch('page/querySettings');
      // await this.$store.dispatch('page/getLocation');
      this.$emit('updateHead');
    }
  }
};
</script>

<style lang="scss">
/*@import "../../assets/scss/_blocks/page";*/
.article-content {
  &__inner {
    position: relative;
    z-index: $z_index_top;
    display: flex;
    @include maxWidth;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
  }
}
</style>
