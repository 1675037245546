<template>
  <div class="timeline-decade" :class="[visibleClass, className]" ref="container">
    <h2 class="timeline-decade__header u-f-montserrat-black" ref="title">{{decade}}s</h2>
    <div class="timeline-decade__content">
      <div
        class="timeline-decade__year"
        :ref="`year_${item.year}`"
        :class="{'visible': isVisible(item)}"
        v-for="(item, index) in timeline"
        :key="`${item.year}_${index}`"
      >
        <Timeline :data="item" :theme="theme" :visible="isVisible(item)" />
      </div>
    </div>
  </div>
</template>

<script>
import Timeline from '../../elements/timeline/Timeline';
export default {
  props: ['data', 'current', 'decades', 'theme'],
  components: {
    Timeline,
  },
  data() {
    return {
      visible: ''
    }
  },
  computed: {
    decade() {
      if (this.data) {
        return this.data.decade;
      }
      return null;
    },
    timeline() {
      if (this.data) {
        return this.data.years;
      }
      return [];
    },
    className() {
      return `timeline-decade--${this.theme}`;
    },
    visibleClass() {
      return this.visible;
    },
  },
  methods: {
    onScroll(e) {
      const scroll = e.detail.offset.y;
      if (
        scroll >= this.$refs.container.offsetTop &&
        scroll <= this.$refs.container.offsetTop + this.$refs.container.offsetHeight
      ) {
        this.$refs.title.style.top = scroll + 'px';
        this.visible = 'visible';
      } else {
        this.$refs.title.style.top = 0;
        this.visible = '';
      }
    },
    isVisible(item) {
      return this.current && item.year === parseInt(this.current);
    },
  },
  mounted() {
    // const offsets = [];
    // let globalOffset = 0;
    // if (this.$refs.container) {
    //   globalOffset = this.$refs.container.offsetTop;
    // }

    // this.$nextTick(() => {
    //   for (let item of this.timeline) {
    //     if (this.$refs[`year_${item.year}`]) {
    //       offsets.push({
    //         year: item.year,
    //         pos: this.$refs[`year_${item.year}`][0].offsetTop + globalOffset,
    //       });
    //     }
    //   }
    //   this.$emit('offset', offsets);
    // });

    window.addEventListener('remax_scroll', this.onScroll);
  },
  updated() {
    const offsets = [];
    let globalOffset = 0;
    if (this.$refs.container) {
      globalOffset = this.$refs.container.offsetTop;
    }

    this.$nextTick(() => {
      for (let item of this.timeline) {
        if (this.$refs[`year_${item.year}`]) {
          offsets.push({
            year: item.year,
            pos: this.$refs[`year_${item.year}`][0].offsetTop + globalOffset,
          });
        }
      }
      this.$emit('offset', offsets);
    });
  },
  beforeDestroy() {
    window.removeEventListener('remax_scroll', this.onScroll);
  },
};
</script>

<style lang="scss">
.timeline-decade {
  $root: &;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: $z_index_min;

  &.visible {
    #{$root}__header {
      position: fixed;
    }
  }

  &--dark-blue {
    background-color: $c_biscay;
  }

  &--blue {
    background-color: $c_lochmara;
  }

  &--light-blue {
    background-color: $c_sail;
  }

  &--grey {
    background-color: $c_silver_sand;
  }

  &--white {
    background-color: $c_white;
  }

  &__header {
    position: absolute;
    width: 100%;
    margin: 0;
    font-size: 80px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $c_white;

    @at-root #{$root}--white & {
      color: $c_ship_gray;
    }

    z-index: $z_index_top;

    @include createQuery($sr_w_xs) {
      font-size: 42px;
    }

    &:before {
      display: block;
      margin: auto;
      width: 2px;
      height: 110px;
      background-color: $c_alizarin_crimson;
      content: '';

      @include createQuery($sr_w_xs) {
        height: 59px;
      }
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // filter: blur(20px);
      // backdrop-filter: blur(2px);
      content: '';
    }
  }

  &__content {
    position: relative;
    margin-top: 207px;
    flex: 1;
  }
}
</style>
