<template>
  <div class="list-body">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="list-body__item"
    >
      <figure class="list-body__figure">
        <img
          :src="item.image"
          class="list-body__image"
          ref="image"
        />
      </figure>
      <div class="list-body__content">
        <h3 class="list-body__name u-f-opensans-bold">
          {{ item.name }}
        </h3>
        <p class="list-body__position u-f-opensans-regular">
          {{ item.position }}
        </p>
        <p class="list-body__location u-f-opensans-regular">
          {{ item.location }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  methods: {
    onSearch() {
      this.$emit('search');
    }
  }
};
</script>

<style lang="scss">
.list-body {
  $root: &;
  position: relative;
  width: 100%;
  border: none;
  border-collapse: collapse;

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 27px 0 23px;
    border-bottom: 1px solid $c_silver_sand;

    @include createQuery($sr_w_xs) {
      padding: 17.5px 0;
    }
  }

  &__figure {
    margin: 0 13px 0 0;
    width: 84px;
    height: 95px;
    position: relative;

    @include createQuery($sr_w_xs) {
      margin-right: 19px;
      width: 69px;
      height: 78px;
    }

    #{$root}__image {
      display: block;
      @include overlay;
      object-fit: cover;
    }
  }

  &__name {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1.38;
    }
  }

  &__location {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1.38;
    }
  }

  &__position {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1.38;
    }
  }
}
</style>
