<template>
  <div
    class="top-matches"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="top-matches__inner top-matches__inner--title">
      <h3 class="top-matches__title u-f-montserrat-black">Top Matches</h3>
    </div>
    <div class="top-matches__inner">
      <div class="top-matches__left">
        <StoryOne
          class="top-matches__story-left"
          v-if="getStory(0)"
          :story="getStory(0)"
          :shadow="true"
          :noeffect="true"
        />
      </div>
      <div class="top-matches__right">
        <StoryOne
          class="top-matches__story-right"
          v-if="getStory(1)"
          :story="getStory(1)"
          :shadow="true"
          :noeffect="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StoryOne from '../common/StoryOne';
export default {
  props: ['stories'],
  components: {
    StoryOne,
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.category,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 900,
        duration: 600,
      });
    },
    getStory(index) {
      if (this.stories && this.stories[index]) {
        const story = this.stories[index];
        return {
          title: this.$prismic.richTextAsPlain(story.title),
          description: story.excerpt,
          category:
            story.category,
          image: story.image.url,
          cta_link: `${
            story.type &&
            story.type === 'news_article'
              ? '/news'
              : ''
          }/${story.uid}`,
          link: story.link,
          uid: story.uid,
        };
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.top-matches {
  $root: &;
  margin-top: 50px;
  padding-bottom: 100px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_md) {
    .story__text {
      display: none;
    }

    .story__link {
      margin-top: 0;
    }

    .story__title {
      margin-bottom: 8px;
    }

    .story.story-one .story__header .story__category {
      left: 5.33%;
    }
  }
  @include createQuery($sr_w_xs) {
    padding-bottom: 0;

    .story.story-one .story__header .story__category {
      left: 0;
    }
  }


  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;

    @include createQuery($sr_w_not_sm) {
      flex-direction: row !important;
      padding-left: 20px;
      padding-right: 20px;
    }

    &--title {
      padding-left: 100px;
      padding-right: 100px;

      @include createQuery($sr_w_xs) {
        padding: 0 20px;
      }
    }

    #{$root}__left {
      flex: 1;
      max-width: 600px;
      width: 100%;
      padding-right: 8px;

      @include createQuery($sr_w_sm) {
        max-width: 100%;
        width: 100%;
        padding: 0;
        margin-bottom: 41px;
      }
    }

    #{$root}__right {
      flex: 1;
      max-width: 600px;
      width: 100%;
      padding-left: 8px;

      @include createQuery($sr_w_sm) {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 14px;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.75px;
  }
}
</style>
