<template>
  <div
    class="article-list-table"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-list-table__inner">
      <Collapse :title="title" :defaultState="defaultState">
        <div class="article-list-table__wrap">
          <ListBody
            :list="getRows"
          />
          <Pagination
            v-if="list.length > 0 && hasPagination"
            class="article-list-table__pagination"
            :page="getPage"
            :count="totalPages"
            @onPaginate="onPaginate"
          />
          <GoToPage
            v-if="totalPages > 1"
            class="article-list-table__go-to-page"
            :totalPages="totalPages"
            @change="onPaginate"
          />
        </div>
      </Collapse>
    </div>
  </div>
</template>

<script>
import Collapse from '../../elements/collapse/Collapse';
import ListBody from '../../elements/list/ListBody';
import Pagination from '../../elements/common/Pagination';

export default {
  props: ['list', 'title', 'hasPagination', 'defaultState'],
  components: {
    Collapse,
    ListBody,
    Pagination,
  },
  data() {
    return {
      page: 1,
      limit: 3,
      total: this.list.length
    };
  },
  computed: {
    getRows() {
      if (this.list && this.list.length > 0) {
        return [...this.list].splice((this.page - 1) * this.limit, this.limit);
      }
      return [];
    },
    getPage() {
      return this.page;
    },
    totalPages() {
      return Math.ceil(this.list.length / this.limit);
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
    onPaginate(item) {
      this.page = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.article-list-table {
  margin-top: 57px;
  margin-bottom: 54px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;

    @include printMaxWith;
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12.5px;
    text-align: right;

    @include createQuery($sr_w_xs) {
      justify-content: center;
    }
  }

  &__go-to-page {
    display: none;

    @include createQuery($sr_w_xs) {
      margin-top: 23px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
