<template>
  <component
    v-bind="getProps"
    :is="getType"
    :class="getClassName"
    @click="onClick"
    v-observe-visibility="{
               callback: visibilityChanged,
               once: true,
             }"
  >
    <span class="btn__shadow" ref="shadow"></span>
    <span class="btn__content">{{ label }}</span>
  </component>
</template>

<script>
export default {
  data() {
    return {
      isAnimating: true,
    };
  },

  props: ['label', 'className', 'to', 'color', 'animationDelay', 'disabled'],

  computed: {
    getProps() {
      const theProps = {};
      if (this.disabled) theProps.disabled = this.disabled;
      if (this.isPrismicLink) theProps.field = this.to;
      if (this.getType === 'a') theProps.href = this.to;
      else theProps.to = this.to;
      return theProps;
    },

    getClassName() {
      return `btn${this.disabled ? ` btn--disabled` : ''}${
        !this.isAnimating ? ` btn--loaded` : ''
      }${this.color ? ` btn--${this.color.toLowerCase()}` : ''} u-f-montserrat-bold ${
        this.className
      }`;
    },

    getType() {
      if (this.to) {
        // prismic field
        if (this.isPrismicLink) return 'prismic-link';
        // if email, <a>
        if (this.to.startsWith('mailto:')) return 'a';
        // if abs URL, use <a> tag, else router-link
        const isAbsURLRegExp = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (isAbsURLRegExp.test(this.to)) return 'a';
        else return 'router-link';
      }
      return 'button';
    },
    isPrismicLink() {
      return this.to && typeof this.to === 'object';
    },
  },

  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime
        .timeline()
        .add({
          targets: this.$el,
          opacity: [0, 1],
          easing: 'easeOutSine',
          duration: 450,
          delay: this.animationDelay ? this.animationDelay : 0,
        })
        .add({
          targets: this.$refs.shadow,
          translateX: -8,
          translateY: 8,
          easing: 'easeOutSine',
          duration: 450,
          complete: () => {
            this.isAnimating = false;
            this.$el.removeAttribute('style');
            if (this.$refs.shadow) this.$refs.shadow.removeAttribute('style');
          },
        });
    },

    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.btn {
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  margin-left: 8px;
  padding: 0;

  text-decoration: none;

  cursor: pointer;
  border: 0;
  outline: 0;

  opacity: 0; // set in js

  &__shadow {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*transform: translate(-8px, 8px);*/
    background: $c_sail;

    transition: transform $t_fast;
  }

  &__content {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: $c_white;
    min-width: 190px;
    max-width: 100%;

    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    color: $c_ship_gray;

    transition: transform $t_fast;

    @include createQuery($sr_w_xs) {
      padding: 16px;
      min-width: 152px;
      font-size: 9.6px;
    }
  }

  &:not(.btn--disabled):hover {
    .btn__shadow {
      transform: translate(-10px, 10px);

      transition: transform $t_normal;
    }
  }

  &:not(.btn--disabled):active {
    .btn__shadow {
      transform: translate(-8px, 8px);

      transition: transform $t_fast;
    }

    .btn__content {
      transform: translate(-2px, 2px);
    }
  }

  &--loaded {
    opacity: 1;

    .btn__shadow {
      transform: translate(-8px, 8px);
    }
  }

  &--red {
    .btn__shadow {
      background: $c_alizarin_crimson;
    }
  }

  &--blue {
    .btn__shadow {
      background: $c_cobalt;
    }
  }

  &--green {
    .btn__shadow {
      background: $c_accent_green;
    }
  }

  &--navy {
    .btn__shadow {
      background: $c_navy;
    }
  }

  &--disabled {
    .btn__content {
      color: $c_regent_gray;
    }

    .btn__shadow {
      background: $c_light_gray;
    }
  }
}
</style>
