<template>
  <div class="filter">
    <div class="inner">
      <TheSelect
        label="Filter By" 
        :options="filterOptions"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import TheSelect from '@/components/elements/inputs/TheSelect';

export default {
  props: ['categories'],

  components: {
    TheSelect,
  },

  computed: {
    filterOptions() {
      return this.categories.map(cat => { return {value: cat.uid, text: cat.data.name} })
    }
  },

  methods: {
    onChange(val) {
      this.$emit('change', val)
    }
  },
}
</script>

<style lang="scss" scoped>
.filter {
  @include maxWidth;
  padding: 0 100px;
  margin-top: 90px;
}

.inner {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 16px;
  border-bottom: 1px solid $c_text_gray;
}
</style>