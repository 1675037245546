<template>
  <main class="page__content">
    <ArticlePageRenderer
      :data="data"
      :article="article"
      :uid="uid"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import ArticlePageRenderer from '@/components/blocks/ArticlePageRenderer';

export default {
  components: {
    ArticlePageRenderer,
  },
  head: {
    // documentation: https://www.npmjs.com/package/vue-head
    // title: {
    //   inner: 'Article'
    // },
    meta: function() {

      const metaArr = [
        { name: 'title', content: this.metaTitle, id: 'title' },
        { name: 'description', content: this.metaDescription, id: 'description' },
        { name: 'keywords', content: this.metaKeywords, id: 'keywords' },

        { property: 'og:description', content: this.metaDescription, id: 'description' },
        { property: 'og:image', content: this.metaShareImage, id: 'og:image' },
        { property: 'og:title', content: this.metaTitle, id: 'og:title' },
        { property: 'twitter:card', content: 'summary_large_image', id: 'twitter:card' },
      ];

      if (this.data.exclude_from_search === 'Yes') {
        metaArr.push({ name: 'robots', content: 'noindex', id: 'robots' });
      }

      return metaArr;
    },
    link: function() {
      return [{
        rel: 'canonical',
        href: this.caronical,
        id: 'canonical'
      }];
    }
  },
  data () {
    return {
      title: this.data ? this.data.meta_title : '',
      image: this.data && this.data.image ? this.data.image.url : '',
      description: this.data ? this.data.meta_description : '',
      keywords: this.data ? this.data.meta_keywords : '',
    }
  },
  computed: {
    ...mapGetters({
      data: 'getData',
      article: 'getArticle',
    }),
    metaShareImage () {
      return this.image;
    },
    metaTitle() {
      return this.title;
    },
    metaDescription() {
      return this.description;
    },
    metaKeywords() {
      return this.keywords;
    },
    caronical() {
      return '';
    },
    uid() {
      const { uid } = this.$route.params;
      if (uid) return uid
      return ''
    }
  },
  created() {
    const { uid } = this.$route.params;
    if (uid) {
      this.loadPage(uid);
    } else {
      this.$router.push('/not-found');
    }
  },
  beforeRouteUpdate (to, from, next) {
    const { uid } = to.params;
    if (uid) {
      this.loadPage(uid);
    } else {
      this.$router.push('/not-found');
    }
    next();
  },
  watch: {
    '$route' () {
      this.updateMeta();
      this.$nextTick(function () {
        this.$emit('updateHead');
      });
    },
  },
  methods: {
    async loadPage(uid) {
      await this.$store.dispatch('querySingleArticle', { uid, vm: this });
      // await this.$store.dispatch('page/queryByTag', { tag:'Popular' });
      // await this.$store.dispatch('page/queryByType', 'topic');
      // await this.$store.dispatch('page/querySettings');
      // await this.$store.dispatch('page/queryInstagram');
      // await this.$store.dispatch('page/getLocation');
      this.updateMeta();
      this.$nextTick(function () {
        this.$emit('updateHead');
      });
    },
    updateMeta() {
      this.title = this.data ? this.data.meta_title : '';
      this.image = this.data && this.data.image ? this.data.image.url : '';
      this.description = this.data ? this.data.meta_description : '';
      this.keywords = this.data ? this.data.meta_keywords : '';
    }
  }
};
</script>

<style lang="scss">
/*@import "../../assets/scss/_blocks/page";*/
.article-content {
  &.no-hero {
    padding-top: 100px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_top;
    display: flex;
    @include maxWidth;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
  }
}
</style>
