<template>
  <li class="header__nav-item header__nav-item--hamburger">
    <a @click="onClickMenu" class="header__nav-link">
      <TransitionParent tag="span">
        <closeIcon key="close" v-if="isActive"/>
        <combinedShape key="open" v-else/>
      </TransitionParent>
    </a>
  </li>
</template>

<script>
import closeIcon from '@/assets/svg/icon-x.svg';
import combinedShape from '@/assets/svg/combined-shape.svg';
import TransitionParent from '../common/TransitionParent';

export default {
  name: 'HamburgerMenu',
  props: ['isActive'],
  components: {
    combinedShape,
    closeIcon,
    TransitionParent,
  },
  methods: {
    onClickMenu () {
      this.$emit('click');
    }
  }
}
</script>

<style lang="scss">
.header__nav-item {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;

  &--hamburger {
    .header__nav-link {
      width: 60px;
      height: 60px;
      display: block;
      /* display: flex;
       justify-content: center;
       align-items: center;*/
      background-color: $c_remax_blue;
      cursor: pointer;

      > span {
        display: inline-block;

        > svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          path {
            fill: $c_white;
          }

          @include createQuery($sr_w_xs) {
            width: 24px;
            height: 18px;
          }
        }
      }
    }
  }
}
</style>
