<template>
  <div class="story-small">
    <router-link :to="ctaLink" class="story-small__link">
      <StoryImage
        className="story-small__image"
        :image="image"
      />
      <div class="story-small__content">
        <div class="story-small__category u-f-opensans-semibold" :class="{'white': whiteText}" v-if="showCategory">
          {{ category }}
        </div>
        <h3 class="story-small__title u-f-montserrat-black" :class="{'white': whiteText}">
          {{ title }}
        </h3>
      </div>
    </router-link>
  </div>
</template>

<script>
import _ from 'lodash';
import StoryImage from './StoryImage';
export default {
  name: 'StorySmall',
  components: {
    StoryImage
  },
  props: {
    story: Object,
    showLocation: {
      type: Boolean,
      default: true
    },
    showCategory: {
      type: Boolean,
    },
    whiteText: Boolean,
  },
  data() {
    return {
      category: '\xa0',
    }
  },
  computed: {
    image() {
      if (this.story && this.story.data) {
        // if article or has a featured img
        if (this.story.data.image.url) {
          return this.story.data.image.url;
        }
        
        
        // else, try hero image
        if (this.story.data.body) {
          const hero = _.find(this.story.data.body, { slice_type: 'hero' });
          if (hero.primary.image1) {
            return hero.primary.image1.url;
          }
        }
      }

      // for manual stories section (PageSection cpt)
      if (this.story && this.story.story_image && this.story.story_image.url) {
        return this.story.story_image.url;
      }

      return '';
    },
    title() {
      if (this.story && this.story.data) {
        return this.story.data.title[0].text || '';
      }
      // for manual stories section (PageSection cpt)
      if (this.story && this.story.story_title) {
        return this.story.story_title
      }
      return '';
    },
    ctaLink() {
      // for manual stories section (PageSection cpt)
      if (this.story && this.story.story_link) {
        return this.story.story_link
      }

      if (this.story) {
        if (this.story.type === 'news_article') {
          return `/news/${this.story.uid}`;
        } else if (this.story.type === 'bio') {
          return `/bio/${this.story.uid}`;
        } else {
          return `/${this.story.uid}`;
        }
      }
      
      return '#';
    }
  },

  mounted() {
    this.getCategory();
  },

  methods: {
    async getCategory() {
      if (this.story.type === 'basic_page') return '\xa0';
      if (this.story && this.story.data && this.story.data.categories && this.story.data.categories.length) {
        const res = await this.$prismic.client.getByID(this.story.data.categories[0].category.id)
        if (res.data && res.data.name) {
          this.category = res.data.name;
          return
        }
      }
      this.category = '\xa0';
    }
  }
}
</script>

<style lang="scss">
.story-small {
  position: relative;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;

  &__link {
    text-decoration: none;
  }

  &__title {
    margin-top: 10px;
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: 0.7px;
    color: $c_ship_gray;

    &.white {
      color: $c_white;
    }
  }

  &__category {
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: $c_cobalt;

    &--red {
      color: $c_alizarin_crimson;
    }

    &.white {
      color: $c_white;
    }
  }

  &__image {
    .story__figure-content {
      padding-bottom: 59.78%;

      .story__image-shadow {
        top: 0 !important;
        left: 0 !important;
      }
    }
  }

  &__location-tag {
    position: absolute;
    top: 14px;
    right: -8px;
    padding: 5px 16px;
    font-size: 10px;
    letter-spacing: 3.13px;
    text-transform: uppercase;
    background-color: $c_cobalt;
    color: $c_white;
    z-index: $z_index_top;
  }
}
</style>
