<template>
    <div class="multi">
        <div class="inner">
            <div class="left">
                <h3 class="title u-f-montserrat-black">{{ headline }}</h3>
                <p class="text">{{ description }}</p>
                <div v-if="ctas">
                    <TheLink 
                        v-for="(cta, index) in ctas"
                        :key="index"
                        :to="cta.cta_link"
                        :label="cta.cta_text"
                        class="the-link"
                        className="link--no-text-transform"
                    />
                </div>
            </div>
            
            <div class="right">
                <StoryImage
                    key="img1"
                    :image="image"
                    :animateShadow="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import StoryImage from '../../elements/story/StoryImage';
import TheLink from '../../elements/buttons/TheLink';

export default {
    components: {
        StoryImage,
        TheLink,
    },

    props: ['module'],

    computed: {
        image() {
            if (this.module.primary) {
                return this.module.primary.image1 ? this.module.primary.image1.url : '';
            }
            return ''
        },

        headline() {
            if (this.module.primary) {
                return this.module.primary.headline ? this.module.primary.headline : '';
            }
            return ''
        },

        description() {
            if (this.module.primary) {
                return this.module.primary.description ? this.module.primary.description : '';
            }
            return ''
        },

        ctas() {
            if (this.module.items && this.module.items.length) {
                return this.module.items
            }
            return []
        }
    }
}
</script>

<style lang="scss" scoped>
.multi {
    padding-bottom: 80px;

    @include createQuery($sr_w_md) {
        padding-bottom: 0;
    }
}
.inner {
    @include maxWidth;
    @include inner;
    padding-right: 0;
    align-items: center;

    @include createQuery($sr_w_md) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

.left {
    width: 300px;
    padding-right: 50px;

    @include createQuery($sr_w_md) {
        flex-direction: column-reverse;
        width: calc(100% + 10px);
        margin-left: -10px;
    }
}

.right {
    flex: 1;
    width: 100%;

    @include createQuery($sr_w_md) {
        width: calc(100% + 10px);
        max-width: 523px;
        margin-left: -10px;
        margin-bottom: 22px;
    }
}

.the-link {
    display: block;
    margin-bottom: 16px;
}

.title {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 25px;
    line-height: 1.24;
    letter-spacing: 0.78px;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
        margin-bottom: 7px;
        font-size: 18px;
    }
}

.text {
    font-size: 16px;
    line-height: 1.5;
    color: $c_ship_gray;
    margin-bottom: 38px;

    @include createQuery($sr_w_xs) {
        margin-top: 7px;
        font-size: 13px;
    }
}
</style>

<style lang="scss">
.multi .story__figure-content {
    padding-bottom: 54.79%;
}
.multi .story__image-shadow {
    background: $c_navy !important;
}
</style>