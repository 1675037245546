<template>
  <div
    class="article-recommend"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-recommend__inner">
      <div class="article-recommend__wrapper">
        <h4 class="article-recommend__headline u-f-montserrat-regular" ref="headline">{{headline}}</h4>
        <div class="article-recommend__content" ref="content">
          <template v-if="category_tag">
            <span class="article-recommend__category u-f-opensans-semibold">{{category_tag}}</span>
            <span class="article-recommend__separator u-f-opensans-bold">|</span>
          </template>

          <a class="article-recommend__title u-f-opensans-bold" target="_blank" rel="noreferrer" :href="content.link.url" v-if="content.link && content.link.link_type === 'Web'">{{title}}</a>
          <TheLink v-else class="article-recommend__title u-f-opensans-bold" :to="link" :label="title" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLink from '../../elements/buttons/TheLink';
export default {
  props: ['content'],
  components: {
    TheLink
  },
  computed: {
    category_tag() {
      if (this.content.category_tag) {
        return this.content.category_tag;
      }
      return null;
    },
    headline() {
      if (this.content.headline) {
        return this.content.headline;
      }
      return null;
    },
    title() {
      if (this.content.title) {
        return this.content.title;
      }
      return null;
    },
    link() {
      if (this.content.link && this.content.link.uid) {
        return `/${this.content.link.uid}`;
      }
      return '';
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.headline,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.content,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss" scoped>
.article-recommend {
  margin-top: 63px;
  margin-bottom: 43px;
  position: relative;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 20px;
    margin-bottom: 26px;
  }

  &__wrapper {
    border-left: 3px solid $c_navy;
    padding-left: 13px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    @include inner;
    flex-direction: column;

    @include printMaxWith;
  }

  &__headline {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.67px;
    color: $c_ship_gray;

    opacity: 0; // set in js

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1;
      letter-spacing: 0.54px;
    }
  }

  &__content {
    opacity: 0;

    @include noAnimationInPrint;

    @include createQuery($sr_w_xs) {
      margin-top: 8px;
      line-height: 1;
    }
  }

  &__category {
    margin-right: 4px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3.75px;
    color: $c_remax_blue;

    @include createQuery($sr_w_xs) {
      display: block;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 3.13px;
    }
  }

  &__separator {
    @include createQuery($sr_w_xs) {
      display: none;
    }
  }

  &__title {
    margin-left: 4px;
    padding: 0;
    font-size: 14px;
    text-transform: initial;
    letter-spacing: normal;
    text-decoration: none;
    color: $c_ship_gray;
    text-align: left;

    @include createQuery($sr_w_xs) {
      margin-left: 0;
      font-size: 12px;
    }
  }
}
</style>
