<template>
  <div
    class="article-carousel"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="article-carousel__inner">
      <Carousel :cards="cards" />
    </div>
  </div>
</template>

<script>
import Carousel from '../../elements/carousel/Carousel';
export default {
  props: ['cards'],
  components: {
    Carousel
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.image,
        opacity: [0, 1],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.article-carousel {
  $root: &;
  position: relative;
  margin-top: 63px;
  // margin-bottom: 54px;
  background: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_xs) {
    margin-top: 49px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    @include inner;


    @include printMaxWith;
  }
}
</style>
