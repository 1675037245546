<template>
  <div class="article-popular" :class="{'wide': background}">
    <div class="article-popular__background" v-if="background" :style="`background-image: url(${background});`"></div>
    <div class="article-popular__inner">
      <h3 class="article-popular__title u-f-montserrat-black" :class="{'white': whiteText}">
        {{ title }}
      </h3>
    </div>
    <div class="article-popular__inner">
      <p v-if="subhead" class="article-popular__subhead" :class="{'white': whiteText}">
        {{ subhead }}
      </p>
    </div>
    <div class="article-popular__inner">
      <div class="article-popular__articles">
        <template v-if="articles">
          <carousel :perPage="1" :perPageCustom="[[600, 3], [1265, 4]]">
            <slide v-for="(article, index) in articles" :key="index">
              <StorySmall
                :story="article"
                :showLocation="false"
                :showCategory="showCategories"
                :whiteText="whiteText"
              />
            </slide>
          </carousel>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import StorySmall from '../../elements/story/StorySmall';
export default {
  props: ['articles', 'showCategories', 'data'],
  components: {
    StorySmall,
  },

  computed: {
    title() {
      return this.data.title ? this.data.title : 'More:'
    },
    subhead() {
      return this.data.subhead ? this.data.subhead : ''
    },
    background() {
      return this.data.background
    },
    whiteText() {
      return this.data.whiteText
    }
  }
}
</script>

<style lang="scss" scoped>
.article-popular {
  $root: &;
  display: block;
  width: 100%;
  padding: 67px 0 61px 0;
  margin-top: 25px;
  background-color: $c_off_white;
  position: relative;

  @include createQuery($sr_w_xs) {
    padding: 22px 0 27px;
  }

  @include hideInPrint;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__inner {
    @include maxWidth;
    @include inner;
    @include createQuery($sr_w_xs) {
      width: calc(100% - 80px);
    }

    @include printMaxWith;
  }

  &__title {
    display: block;
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.75px;
    color: $c_ship_gray;
    z-index: 1;

    &.white {
      color: $c_white;
      font-size: 25px;
      line-height: 31px;

      @include createQuery($sr_w_xs) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 4px;
      }
    }

    @include createQuery($sr_w_xs) {
      font-size: 14px;
    }
  }

  &__subhead {
    display: block;
    z-index: 1;
    margin-bottom: 24px;
    @include defaultCopyStyles;

    &.white {
      color: $c_white;
    }
  }

  &__articles {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-left: -8px;
    margin-right: -8px;

    ::v-deep .VueCarousel {
      width: 100%;
    }

    ::v-deep .VueCarousel-pagination {
      .VueCarousel-dot-container {
        margin-top: 8px !important;

        .VueCarousel-dot {
          margin: 0 4px !important;
          padding: 0 !important;
          width: 8px !important;
          height: 8px !important;
          border: 1px solid $c_cobalt !important;
          background-color: transparent !important;

          &--active {
            background-color: $c_cobalt !important;
          }
        }
      }
    }

    @include createQuery($sr_w_xs) {
      padding: 0;
    }
  }

  &.wide {
    padding-top: 120px;
    padding-bottom: 120px;
    @include createQuery($sr_w_sm) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    .article-popular__inner {
      @include createQuery($sr_w_not_md) {
        padding-left: 100px;
        padding-right: 200px;
      }
    }

    ::v-deep .VueCarousel-pagination {
      .VueCarousel-dot-container {
        .VueCarousel-dot {
          border: 1px solid $c_white !important;
          background-color: transparent !important;

          &--active {
            background-color: $c_white !important;
          }
        }
      }
    }
  }
}
</style>
