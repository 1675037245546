<template>
  <div class="profile__social" v-if="showLabel">
    <span class="profile__social-heading u-f-opensans-bold" v-if="hasLinks">connect</span>
    <ul>
      <li v-for="(link, index) in links" :key="index">
        <a v-if="link.link" :href="link.link" :target="link.target">
          <component :is="getIcon(link.label)" :class="link.label" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import facebookIcon from '@/assets/svg/facebook.svg';
import instagramIcon from '@/assets/svg/instagram.svg';
import linkedinIcon from '@/assets/svg/linkedin.svg';
import twitterIcon from '@/assets/svg/twitter.svg';

export default {
  props: ['facebook', 'twitter', 'instagram', 'linkedin'],
  components: {
    facebookIcon,
    instagramIcon,
    linkedinIcon,
    twitterIcon,
  },
  computed: {
    hasLinks () {
      if (!(this.links && Array.isArray(this.links) && this.links.length > 0)) return false;
      else {
        for (let i = 0; i < this.links.length; i++) {
          if (this.links[i].link) return true;
        }
        return false;
      }
    },
    showLabel() {
      return this.facebook || this.twitter || this.instagram || this.linkedin;
    },
    links() {
      return [
        { link: this.facebook, label: 'facebook' },
        { link: this.twitter, label: 'twitter' },
        { link: this.instagram, label: 'instagram' },
        { link: this.linkedin, label: 'linkedin' },
      ]
    }
  },
  methods: {
    getIcon(title) {
      switch (title) {
        case 'facebook':
          return facebookIcon;
        case 'instagram':
          return instagramIcon;
        case 'linkedin':
          return linkedinIcon;
        case 'twitter':
          return twitterIcon;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.profile {
  &__social-heading {
    display: block;
    margin-right: 13px;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 1.13px;
  }

  &__social {
    display: flex;
    align-items: center;

    ul {
      @include resetUL;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include createQuery($sr_w_xs) {
        margin-top: 10px;
        padding-right: 52px;
      }
    }

    li {
      display: inline-block;

      @include createQuery($sr_w_md) {
        text-align: left;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      display: inline-block;
      margin-right: 13px;
      font-size: 9px;
      line-height: normal;
      letter-spacing: 0.93px;
      color: $c_regent_gray;
      text-transform: uppercase;
      text-decoration: none;

      transition: color $t_normal;

      @include createQuery($sr_w_md) {
        margin-right: 20px;
      }

      &:hover {
        svg {
          * {
            fill: $c_sail;
          }
        }
      }

      svg {
        &.facebook {
          width: 5.6px;
          height: 11.3px;

          @include createQuery($sr_w_md) {
            width: 8.6px;
            height: 17px;
          }
        }

        &.twitter {
          width: 14.1px;
          height: 11.3px;

          @include createQuery($sr_w_md) {
            width: 21.1px;
            height: 17px;
          }
        }

        &.instagram {
          width: 11.3px;
          height: 11.2px;

          @include createQuery($sr_w_md) {
            width: 17px;
            height: 17px;
          }
        }

        &.linkedin {
          width: 11.3px;
          height: 11.3px;

          @include createQuery($sr_w_md) {
            width: 17px;
            height: 17px;
          }
        }

        path {
          fill: $c_ship_gray;
        }

        * {
          fill: $c_ship_gray;
          transition: fill $t_normal;
        }
      }
    }
  }
}
</style>
