<template>
  <main class="page__content">
    <ArticlePageRenderer
      :data="pageData"
      :article="article"
      :isBasicPage="true"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import ArticlePageRenderer from '../components/blocks/ArticlePageRenderer';

export default {
  components: {
    ArticlePageRenderer
  },
  head: function () {
    const headObj = {
      meta: []
    };
    if (this.pageData) {
      // set title
      if (this.pageData.title && this.pageData.title[0]) {
        headObj.title = { inner: this.pageData.title[0].text };
      }
      // set meta
      if (this.pageData.meta_title) {
        headObj.meta.push({ name: 'title', content: this.pageData.meta_title, id: 'title' });
        headObj.meta.push({ property: 'og:title', content: this.pageData.meta_title, id: 'og:title' });
      }
      if (this.pageData.meta_description) {
        headObj.meta.push({ name: 'description', content: this.pageData.meta_description, id: 'description' });
        headObj.meta.push({ property: 'og:description', content: this.pageData.meta_description, id: 'description' });
      }
      if (this.pageData.meta_keywords) {
        headObj.meta.push({ name: 'keywords', content: this.pageData.meta_keywords, id: 'keywords' });
      }
      headObj.meta.push({ property: 'twitter:card', content: 'summary_large_image', id: 'twitter:card' });
      if (this.pageData.canonical_url && this.pageData.canonical_url.url) {
        headObj.link = [{
          rel: 'canonical',
          href: this.pageData.canonical_url.url,
          id: 'canonical'
        }];
      }
      // TODO:
      // if (this.pageData.meta_image) {
      //   headObj.meta.push({ property: 'og:image', content: this.metaShareImage, id: 'og:image' });
      // }
      // if (this.data.exclude_from_search === 'Yes') {
      //   metaArr.push({ name: 'robots', content: 'noindex', id: 'robots' });
      // }
    }
    return headObj;
  },
  created() {
    const { uid, path } = this.$route.params;
    if (path) {
      this.loadPage(`${path}-${uid}`);
    } else {
      this.loadPage(uid);
    }
  },
  beforeRouteUpdate(to, from, next) {
    const { uid } = to.params;
    this.loadPage(uid);
    next();
  },
  computed: {
    ...mapGetters({
      pageData: 'getPageData',
      article: 'getArticle',
    })
  },
  methods: {
    async loadPage(uid) {
      if (!uid) {
        this.$router.push('/not-found');
        return;
      }
      try {
        await this.$store.dispatch('queryPage', { uid, vm: this });
        this.$nextTick(function () {
          this.$emit('updateHead');
        });
      } catch (e) {
        console.error(e);
        this.$router.push('/not-found');
      }
    }
  }
};
</script>