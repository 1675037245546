<template>
  <div>
    <div class="wrap">
      <ul class="menu" ref="menuItems">
        <li v-for="(item, index) in menuItems" :key="index">
          <MenuSimpleLink v-if="item.sublinks.length === 0" :item="item" />
          <MenuDropdownLink 
            v-else 
            :item="item" 
            :className="dropdownItem === item ? 'menu-item--active' : ''"
            @click="openDropdown(item)"
          />
        </li>
        <!-- tablet search - conditional visibility -->
        <li v-if="getIsTablet">
          <button class="icon" @click="showSearchBox">
            <Icon height="16" width="16" />
          </button>
        </li>
        <!-- desktop search - always visible -->
        <li v-else >
          <SearchBox />
        </li>
      </ul>
      <div ref="tabletSearchBox" class="tablet-search-box" v-if="getIsTablet">
        <SearchBox @blur="hideSearchBox" />
      </div>
    </div>
    <Dropdown :open="dropdownOpened" :item="dropdownItem" @close="closeDropdown" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MenuSimpleLink from './MenuSimpleLink';
import MenuDropdownLink from './MenuDropdownLink';
import Dropdown from './Dropdown';
import SearchBox from './SearchBox';
import Icon from '@/assets/svg/icon-magnifying-glass.svg';

export default {
  components: {
    MenuSimpleLink,
    MenuDropdownLink,
    Dropdown,
    SearchBox,
    Icon,
  },

  data() {
    return {
      dropdownOpened: false,
      dropdownItem: false,
      searchActive: false,
    }
  },

  computed: {
    ...mapGetters([
      'getSettings',
      'getHeaderMenu',
      'getIsTablet',
    ]),

    menuItems() {
      if (!this.getHeaderMenu) return []

      return this.getHeaderMenu
    }
  },

  watch: {
    searchActive(val) {
      if (val) {
        this.playShowAnimation()
      } else {
        this.playHideAnimation()
      }
    }
  },

  methods: {
    openDropdown(item) {
      // this.dropdownOpened = false;
      this.dropdownItem = item;
      this.$nextTick(() => {
        this.dropdownOpened = true;
      })
    },

    closeDropdown() {
      this.dropdownOpened = false;
      this.dropdownItem = null;
    },

    showSearchBox() {
      this.searchActive = true
    },

    hideSearchBox() {
      this.searchActive = false
    },

    playShowAnimation() {
      let self = this;

      this.$anime({
        targets: this.$refs.menuItems,
        opacity: [1, 0],
        top: [0, -40],
        duration: 600,
        easing: 'easeOutSine',
      });

      this.$anime({
        targets: this.$refs.tabletSearchBox,
        opacity: [0, 1],
        bottom: [-40, 0],
        easing: 'easeOutSine',
        duration: 600,

        complete () {
          self.$refs.tabletSearchBox.querySelector('input').focus();
        }
      });
    },

    playHideAnimation() {
      this.$anime({
        targets: this.$refs.tabletSearchBox,
        opacity: [1, 0],
        bottom: [0, -40],
        easing: 'easeOutSine',
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.menuItems,
        opacity: [0, 1],
        top: [-40, 0],
        duration: 600,
        easing: 'easeOutSine',
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  align-items: center;
  position: relative;
  // overflow: hidden;
  min-height: 34px;
}
.menu {
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: center;

  > li:not(:last-child) {
    margin-right: 54px;

    @media (max-width: 1024px) {
      margin-right: 32px;
    }
  }
}

.icon {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.tablet-search-box {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: -40px;
}

</style>