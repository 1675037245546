<template>
  <div class="comments js-article-comments">
    <div class="comments__inner">
      <div class="comments__wrap">
        <button :class="`comments__btn u-f-montserrat-semibold${isOpen ? ' is-open' : ''}`" type="button" @click="toggleOpen">
          <strong class="u-f-montserrat-black">Conversations</strong>
          <template v-if="this.getNumberOfComments"> ({{this.getNumberOfComments}})</template>
          <span class="comments__btn-icon">
            <arrowUp v-if="isOpen"/>
            <arrowDown v-else/>
          </span>
        </button>

        <div class="comments__disq-wrap" ref="commentsWrapper">
          <div class="comments__disq"
               :key="$route.path">
            <Disqus shortname="remaxnews"
                        ref="disqus"
                        @ready="onDisqReady"
                        @new-comment="onNewComment"
                        :pageConfig="pageConfig"></Disqus>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrowDown from '@/assets/svg/arrow-down.svg';
import arrowUp from '@/assets/svg/arrow-up.svg';
import {mapGetters} from 'vuex';

export default {
  data () {
    return {
      isOpen: false,
      savedHeight: 0,
    }
  },
  components: {
    arrowUp,
    arrowDown,
  },
  computed: {
    ...mapGetters({
      getNumberOfComments: 'getNumberOfComments',
    }),
    getLocationHREF () {
      return window.location.href
    },
    pageConfig() {
      return {
        url: this.getLocationHREF,
        identifier: this.$route.path,
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.updateContainerSize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateContainerSize);
  },
  methods: {
    onNewComment () {
      this.$store.commit('setCommentCount', (this.getNumberOfComments + 1));
    },
    updateContainerSize (cb) {
      // recalc height
      this.$refs.commentsWrapper.style.maxHeight = ``;
      this.$nextTick(() => {
        this.updateSavedHeight();
        this.$nextTick(() => {
          if (this.isOpen) {
            this.$refs.commentsWrapper.style.maxHeight = `${this.savedHeight}px`;
          } else {
            this.$refs.commentsWrapper.style.maxHeight = `0px`;
          }
          if (typeof cb === 'function') cb();
        });
      });
    },
    updateSavedHeight () {
      this.savedHeight = this.$refs.commentsWrapper.clientHeight;
    },
    onDisqReady () {
      // disq loaded
      this.$store.dispatch('setCommentCount');
    },
    toggleOpen () {

      this.updateContainerSize(() => {
        if (this.isOpen) {
          // close
          this.$anime({
            targets: this.$refs.commentsWrapper,
            maxHeight: [this.savedHeight, 0],
            easing: 'easeOutSine',
            duration: 450,
          });
          this.isOpen = false;
        } else {
          // open
          this.$anime({
            targets: this.$refs.commentsWrapper,
            maxHeight: [0, this.savedHeight],
            easing: 'easeOutSine',
            duration: 450,
            complete: () => {
              this.$refs.commentsWrapper.style.maxHeight = ``;
              // this.updateSavedHeight();
              this.$nextTick(() => {
                this.$refs.commentsWrapper.style.maxHeight = `${this.savedHeight}px`;
              })
            }
          });
          this.isOpen = true;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.comments {
  $root: &;

  position: relative;
  margin-top: 70px;
  margin-bottom: 70px;
  width: 100%;

  @include createQuery($sr_w_xs) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    @include inner;
    display: block;
    padding-left: 200px;
    padding-right: 200px;
  }

  &__wrap {
    display: block;
    width: 100%;
    border-top: solid 1px transparentize($c_ship_gray, .25);
    border-bottom: solid 1px transparentize($c_ship_gray, .25);
  }

  &__btn {
    position: relative;
    display: block;
    width: 100%;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 29px 0;
    text-align: left;

    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.75px;

    cursor: pointer;

    @include createQuery($sr_w_xs) {
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.58px;
      padding: 20px 0;
    }
  }

  &__btn-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    svg {

      @include createQuery($sr_w_xs) {
        width: 18px;
        height: auto;
      }

      path {
        fill: $c_alizarin_crimson;
      }
    }
  }

  &__disq-wrap {
    overflow: hidden;
  }

  &__disq {
    padding-bottom: 30px;
  }

}
</style>
