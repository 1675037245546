<template>
  <div class="navigation-mobile" :class="{active: isOpen}">
    <a @click="onClickToggle" class="navigation-mobile__toggle" v-if="!isOpen">
      <combinedShape />
    </a>
    <div class="navigation-mobile__content">
      <div class="navigation-mobile__picker u-f-opensans-semibold">
        <smooth-picker ref="smoothPicker" :data="data" :change="onChange" />
      </div>
      <TopicLink
        label="Select"
        @click="onSelectYear"
        className="navigation-mobile__select-btn"
      />
      <a @click="onClickClose" class="navigation-mobile__close" v-if="isOpen">
        <closeIcon />
      </a>
    </div>
  </div>
</template>

<script>
import combinedShape from '@/assets/svg/combined-shape.svg';
import closeIcon from '@/assets/svg/icon-x.svg';
import TopicLink from "../../elements/buttons/TopicLink";
export default {
  props: ['current', 'years'],
  components: {
    TopicLink,
    combinedShape,
    closeIcon
  },
  data() {
    return {
      open: false,
      year: this.current
    }
  },
  computed: {
    data() {
      return [
        {
          currentIndex: this.years.indexOf(this.current),
          list: this.years,
          onClick: this.onClickYear,
          textAlign: 'center',
          className: 'row-group'
        }
      ];
    },
    isOpen() {
      return this.open;
    }
  },
  methods: {
    onClickToggle() {
      this.open = true;
    },
    onClickClose() {
      this.open = false;
    },
    onClickYear(year) {
      this.year = year;
    },
    onSelectYear() {
      this.$emit('change', this.year);
    },
    onChange(gIndex, iIndex) {
      this.year = this.years[iIndex];
    }
  },
};
</script>

<style lang="scss" scoped>
.navigation-mobile {
  $root: &;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  z-index: $z_index_overlay;

  &.active {
    margin-top: -100vh;
  }
  
  &__toggle {
    position: absolute;
    width: 60px;
    height: 60px;
    right: 17px;
    top: -81px;
    border-radius: 30px;
    // display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c_alizarin_crimson;
  }

  &__close {
    position: absolute;
    bottom: 25px;
    right: 34px;

    svg {
      path {
        stroke: $c_ship_gray;
      }
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 207px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    border: 2px solid $c_alizarin_crimson;
    background-color: $c_white;
    overflow: hidden;
  }

  &__picker {
    width: 58px;
    position: relative;
    font-size: 18px;
    letter-spacing: 2.25px;
    line-height: 2;
    color: $c_ship_gray;

    ::v-deep .smooth-handle-layer {
      .smooth-top {
        border: none !important;
      }

      .smooth-bottom {
        border-color: $c_black !important;
        border-width: 2px !important;
      }
    }
  }
 
  &__select-btn {
    width: 126px;
    margin: auto;
    background-color: $c_alizarin_crimson;
  }
}
</style>
