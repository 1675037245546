<template>
  <div
    class="timeline-hero"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="timeline-hero__inner">
      <div class="timeline-hero__title-container">
        <div class="timeline-hero__lines-veritical" ref="lineTop" />
        <h1 class="timeline-hero__title u-f-montserrat-black" ref="title">
          RE/MAX THROUGH
          <br />THE YEARS
        </h1>
        <div class="timeline-hero__lines-bottom">
          <div class="timeline-hero__lines-bottom-horz" ref="lineBottomHorz"></div>
          <div class="timeline-hero__lines-bottom-vert" ref="lineBottomVert"></div>
        </div>
      </div>
      <div class="timeline-hero__images">
        <img src="~@/assets/img/cloud-1-2-x.png" id="cloud-1" alt="timeline cloud 1" ref="cloud1" />
        <img src="~@/assets/img/cloud-2-2-x.png" id="cloud-2" alt="timeline cloud 2" ref="cloud2" />
        <img src="~@/assets/img/cloud-3-2-x.png" id="cloud-3" alt="timeline cloud 3" ref="cloud3" />
        <img
          src="~@/assets/img/remax-balloon.png"
          id="remax-balloon"
          alt="timeline balloon"
          ref="balloon"
        />
      </div>
      <div class="timeline-hero__next">
        <a class="timeline-hero__next-cta" @click="onNext">
          <iconScroll />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import iconScroll from '@/assets/svg/icon-mouse.svg';

export default {
  components: {
    iconScroll,
  },
  computed: {
    imageBackground() {
      return `background-image:url('${this.image}');`;
    },
  },
  methods: {
    playAnimation() {
      const topLineHeight = this.$refs.lineTop.clientHeight;

      this
        .$anime
        .timeline()
        .add({
          targets: this.$refs.lineTop,
          opacity: [0, 1],
          height: [0, topLineHeight],
          easing: 'linear',
          duration: 500,
          delay: 300,
        });
      
      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['30px', '-5px', 0],
        easing: 'linear',
        delay: 800,
        duration: 1200,
      });

      this.$anime({
        targets: this.$refs.cloud1,
        opacity: [0, 1],
        translateX: ['-100px', 0],
        easing: 'linear',
        delay: 1600,
        duration: 400,
      });

      this.$anime({
        targets: this.$refs.cloud2,
        opacity: [0, 1],
        translateX: ['-100px', 0],
        easing: 'linear',
        delay: 1000,
        duration: 400,
      });

      this.$anime({
        targets: this.$refs.cloud3,
        opacity: [0, 1],
        translateX: ['100px', 0],
        easing: 'linear',
        delay: 1400,
        duration: 400,
      });

      this.$anime({
        targets: this.$refs.balloon,
        opacity: [0, 1],
        translateX: ['-100px', 0],
        easing: 'linear',
        delay: 1400,
        duration: 400,
      });

      const bottomLineHeight = this.$refs.lineBottomVert.clientHeight;
      const bottomLineWidth = this.$refs.lineBottomHorz.clientWidth;

      this.$anime({
        targets: this.$refs.lineBottomVert,
        opacity: [0, 1],
        height: [0, bottomLineHeight],
        easing: 'easeInSine',
        duration: 500,
        delay: 1900,
      });

      this.$anime({
        targets: this.$refs.lineBottomHorz,
        opacity: [0, 1],
        translateX: [`${bottomLineWidth}px`, 0],
        width: [0, bottomLineWidth],
        easing: 'easeInSine',
        duration: 500,
        delay: 1400,
      });
    },
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.playAnimation();

      // "translateY":["10px",0],"opacity":1,"duration":600
    },
    onNext() {
      this.$emit('next');
    }
  },
};
</script>

<style lang="scss">
.timeline-hero {
  $root: &;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    rgba(164, 215, 244, 0.36),
    #ffffff 77%
  );
  background-color: $c_white;
  overflow: hidden;

  @include createQuery($sr_w_not_xs) {
    min-height: 730px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }

  &__title-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    z-index: $z_index_min;

    #{$root}__lines-veritical {
      position: absolute;
      top: 0;
      width: 2px;
      height: 30%;
      background-color: $c_alizarin_crimson;

      opacity: 0;
    }

    #{$root}__lines-bottom {
      position: absolute;
      bottom: 0;
      left: calc(50% - 1px);
      height: 20%;

      z-index: $z_index_back;

      #{$root}__lines-bottom-horz {
        width: 1000px;
        height: 2px;
        background-color: $c_alizarin_crimson;

        opacity: 0;
      }

      #{$root}__lines-bottom-vert {
        height: 100%;
        width: 2px;
        background-color: $c_alizarin_crimson;

        opacity: 0;
      }
    }

    #{$root}__title {
      margin: 28px auto;
      font-size: 58px;
      line-height: normal;
      text-align: center;
      text-transform: uppercase;
      color: $c_ship_gray;

      opacity: 0; // set in js

      @include createQuery($sr_w_md) {
        margin-bottom: 13px;
        font-size: 42px;
      }

      @include createQuery($sr_w_xs) {
        font-size: 38px;
      }
    }
  }

  &__images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z_index_min;

    #cloud-2 {
      position: absolute;
      left: 200px;
      top: 122px;

      @include createQuery($sr_w_xs) {
        left: 44px;
        top: 60px;
        width: 85px;
      }
    }

    #cloud-1 {
      position: absolute;
      left: -80px;
      bottom: 105px;

      @include createQuery($sr_w_xs) {
        left: 10px;
        bottom: 109px;
        width: 192px;
      }
    }

    #cloud-3 {
      position: absolute;
      right: -20px;
      bottom: 218px;

      @include createQuery($sr_w_xs) {
        right: 0px;
        bottom: 161px;
        width: 108px;
      }
    }

    #remax-balloon {
      position: absolute;
      left: 246px;
      bottom: 218px;

      @include createQuery($sr_w_xs) {
        left: 122px;
        bottom: 148px;
        width: 65px;
      }
    }
  }

  &__next {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 83px;
    text-align: center;
    z-index: $z_index_top;

    @include createQuery($sr_w_xs) {
      margin-bottom: 27px;
    }

    #{$root}__next-cta {
      cursor: pointer;

      &:hover {
        svg {
          path {
            stroke-dashoffset: 0;
            animation: mouse-scroll-btn-roll-over .55s cubic-bezier(.5,.1,.07,1);
          }
        }
      }

      svg {
        animation:nudgeMouse 2.4s cubic-bezier(0.250,0.460,0.450,0.940) infinite;

        path {
          stroke-dashoffset: 0;
          animation:mouse-scroll-btn-roll-out .55s cubic-bezier(.5,.1,.07,1);
        }
      }
    }
  }
}

@keyframes mouse-scroll-btn-roll-over{
  0%{stroke-dashoffset:120}
  100%{stroke-dashoffset:0}
}

@keyframes mouse-scroll-btn-roll-out{
  0%{stroke-dashoffset:0}
  100%{stroke-dashoffset:-120}
}
@keyframes nudgeMouse{
  0%{
    -webkit-transform:translateY(0);
    transform:translateY(0);
  }
  45%{
    -webkit-transform:translateY(8px);
    transform:translateY(8px);
  }
  65%,100%{
    -webkit-transform:translateY(0);
    transform:translateY(0);
  }
}
</style>
