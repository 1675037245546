<template>
  <div>
    <template v-if="!isLoading">
      <SearchBox :value="keyword" label="Search Keyword"  />
      <template v-if="keyword">
        <TheHeader :keyword="keyword" />
      </template>
      <template v-if="topMatches.length > 0">
        <TopMatches :stories="topMatches" />
      </template>
      <template v-if="resultsTop.length > 0 && keyword">
        <SearchResults :keyword="keyword" :stories="resultsTop" />
      </template>
      <LoadMore v-if="hasMore" @loadmore="onLoadMore" :hasMore="!searching" />
      <SearchBox label="Try a different search" className="search-box-bottom" />
      
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCategoryName } from '@/lib/utilities.js';
import SearchBox from './common/SearchBox';
import TheHeader from './Search/TheHeader';
import TopMatches from './Search/TopMatches';
import LoadMore from './common/LoadMore';
import SearchResults from './Search/SearchResults';

export default {
  props: ['data', 'searching'], 
  components: {
    SearchBox,
    TheHeader,
    TopMatches,
    SearchResults,
    LoadMore,
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      hasMore: 'searchArticleHasMore',
      pressReleasesList: 'pressReleasesList',
      articles: 'searchArticles',
      keyword: 'getKeyword'
    }),
    // keyword() {
    //   if (this.data && this.data.keyword) {
    //     return this.data.keyword;
    //   }
    //   return '';
    // },
    pressContacts() {
      if (this.data && this.data.pressContacts) {
        const pressContacts = this.data.pressContacts;
        return pressContacts
          .filter(item => item.slice_type === 'press_contacts')
          .map(item => item.primary);
      }
      return null;
    },
    instagram() {
      if (this.data && this.data.instagram) {
        const { instagram } = this.data;
        return instagram;
      }
      return null;
    },
    topMatches() {
      if (this.articles) {
        return [...this.articles]
          .splice(0, 2)
          .map(article => { return {
            ...article.data,
            uid: article.uid,
            category: article.data.categories && article.data.categories.length ? getCategoryName(article.data.categories[0].category) : '',
            type: article.type,
          }});
      }
      return [];
    },
    resultsTop() {
      if (this.articles) {
        return [...this.articles]
          .splice(2)
          .map(article => ({
            ...article.data,
            uid: article.uid,
            category: article.data.categories && article.data.categories.length ? getCategoryName(article.data.categories[0].category) : '',
            type: article.type,
          }));
      }
      return [];
    },
  },
  methods: {
    onLoadMore() {
      this.$emit('loadmore');
    },
    // onSearch(keyword) {
    //   this.$router.push({
    //     name: 'search',
    //     params: {
    //       keyword: keyword
    //     }
    //   }).catch(()=>{});
    //   this.$emit('search', keyword);
    // },
  },
};
</script>

<style lang="scss">
.search-box-bottom {
  margin-top: 0 !important;
}
</style>
