<template>
  <div
    class="the-header"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="the-header__inner">
      <span class="the-header__sub-title u-f-opensans-bold" ref="sub_title">
        Search Results for:
      </span>
      <h1 class="the-header__title u-f-montserrat-black" ref="title">
        {{ keyword }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: ['keyword'],
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.sub_title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });

      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 900,
        duration: 600,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.the-header {
  position: relative;
  margin-top: 89px;
  margin-bottom: 50px;

  @include createQuery($sr_w_xs) {
    margin-top: 48px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
  }

  &__sub-title {
    margin: 0;
    font-size: 16px;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: $c_cobalt;

    @include createQuery($sr_w_xs) {
      font-size: 12px;
      letter-spacing: 3.75px;
    }
  }

  &__title {
    margin: 0;
    font-size: 58px;
    text-transform: uppercase;
    color: $c_ship_gray;

    @include createQuery($sr_w_xs) {
      font-size: 31px;
      line-height: 1.1;
    }
  }

}
</style>
