<template>
  <div class="mid-story">
    <div class="mid-story__inner">
      <div class="mid-story__left">
        <template v-if="leftMiddle">
          <StoryOne
            :story="leftMiddle"
            :buttonStyleCta="true"
            :noeffect="true"
          />
        </template>
        <div class="mid-story__right-lines">
          <div class="mid-story__right-lines--horizontal">
          </div>
          <div class="mid-story__right-lines--vertical">
          </div>
        </div>
      </div>
      <div class="mid-story__right">
        <template v-if="rightMiddle">
          <StoryTwoMiddle
            :story="rightMiddle"
            :buttonStyleCta="true"
            :noeffect="true"
          />
        </template>
        <div class="mid-story__mobile-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import StoryOne from '../common/StoryOne';
import StoryTwoMiddle from '../common/StoryTwoMiddle';
export default {
  props: ['leftMiddle', 'rightMiddle'],
  components: {
    StoryOne,
    StoryTwoMiddle,
  },
}
</script>

<style lang="scss">
.mid-story {
  $root: &;
  display: block;
  width: 100%;
  padding: 0 0 64px 0;

  &__inner {
    @include maxWidth;

    @include createQuery($sr_w_md) {
      flex-direction: row;
      padding-left: 20px;
      padding-right: 20px;
    }

    @include createQuery($sr_w_sm) {
      flex-direction: column;
    } 
  }

  &__left {
    position: relative;
    padding-top: 79px;
    padding-right: 16px;
    flex: 1;
    max-width: 600px;

    @include createQuery($sr_w_sm) {
      width: 100%;
      max-width: 100%;
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 64px;
    }

    .story.story-one .story__header {
      @include createQuery($sr_w_sm) {
        padding-left: 0;
        margin-top: 32px;
      }
    }
  }

  &__right {
    position: relative;
    flex: 1;
    max-width: 600px;
    padding-left: 8.33%;
    padding-right: 0;

    @include createQuery($sr_w_sm) {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
    }

    @include createQuery($sr_w_xs) {
      padding: 0;
    }

    .story.story-two-middle .story__header {
      @include createQuery($sr_w_sm) {
        padding-left: 0;
        margin-top: 0;
      }
    }
  }

  &__right-lines {
    position: absolute;
    top: 50%;
    right: 16px;
    margin-right: -173px;
    z-index: 99;

    &--horizontal {
      width: 173px;
      height: 2px;
      background-color: $c_light_blue;
    }

    &--vertical {
      height: 270px;
      width: 2px;
      margin-left: 171px;
      background-color: $c_light_blue;

      @media (max-width: 1024px) {
        height: 230px;
      }

      @media (max-width: 880px) {
        height: 180px;
      }
    }

    @include createQuery($sr_w_sm) {
      display: none;
    }
  }

  &__mobile-line {
    @include createQuery($sr_w_not_sm) {
      display: none;
    }

    width: 2px;
    height: 275px;
    background-color: $c_light_blue;

    position: absolute;
    right: 20%;
    bottom: -100px;
  }
}
</style>
