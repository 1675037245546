<template>
  <div class="article-media-cart-sticky">
    <div class="article-media-cart-sticky__item time" ref="item">
      <div class="article-media-cart-sticky__icon">
        <span class="article-media-cart-sticky__minute u-f-opensans-bold">
          {{ readTime }}
        </span>
        <span class="article-media-cart-sticky__sub-text u-f-montserrat-semibold">
          min read
        </span>
        <div class="article-media-cart-sticky__progress">
          <span
            v-for="(index, step) in 7"
            :key="index"
            class="article-media-cart-sticky__step"
            :class="{active: progress > step}"
          >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      readTime: 0,
    }
  },
  
  props: ['progress'],

  mounted () {
    const theContent = document.querySelectorAll('.js-article-content');
    if (theContent[0] && theContent[0].textContent) {
      this.readTime = Math.ceil(theContent[0].textContent.split(' ')
        .filter(function(n) { return n !== '' })
        .length / 233);
    }
  }
};
</script>

<style lang="scss" scoped>
// define the query that controls the media cart vertical/horizontal state
$sr_article_media_cart_stick_to_bottom: $sr_w_sm;

.article-media-cart-sticky {
  $root: &;
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  left: 0;
  z-index: $z_index_header;

  @include hideInPrint;

  @include createQuery($sr_article_media_cart_stick_to_bottom) {
    width: 100%;
    flex-direction: row;
    box-shadow: 0px -3px 4px 1px rgba(0,0,0,0.2);

    transition: opacity $t_normal;

    &.is-mobile-hidden {
      opacity: 0;
    }

    &__item {
      border-width: 0 0 0 1px !important;

      &:first-child {
        border-left-width: 0 !important;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -1px;
    border: 1px solid $c_silver_sand;
    background-color: $c_white;

    &:not(.time) {
      cursor: pointer;
    }

    @include createQuery($sr_article_media_cart_stick_to_bottom) {
      flex: 1;
    }

    &.time {
      #{$root}__icon {

        @include createQuery($sr_article_media_cart_stick_to_bottom) {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          padding: 14px 0;
        }

        @include createQuery($sr_w_xs) {
          padding: 8px 0;
        }
      }
    }

    &:hover {

      @include createQuery($sr_w_not_sm) {
        #{$root}__text {
          padding-right: 25px;
          max-width: 400px;
        }
      }

    }
  }

  &__icon {
    width: 84px;
    min-height: 87px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include createQuery($sr_article_media_cart_stick_to_bottom) {
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
    }

    @include createQuery($sr_w_xs) {
      min-height: 57px;
      width: 76px;
    }

    #{$root}__minute {
      font-size: 24px;

      @include createQuery($sr_w_xs) {
        font-size: 19px;
      }
    }

    #{$root}__sub-text {
      width: 37px;
      text-align: center;
      font-size: 10px;
      line-height: 1.1;
      letter-spacing: 0.45px;
      color: $c_mid_gray;

      @include createQuery($sr_w_xs) {
        font-size: 9px;
      }
    }
  }

  &__svg {

    @include createQuery($sr_w_sm) {
      margin-top: 18px;
    }

    @include createQuery($sr_w_xs) {
      margin-top: 12px;
    }

    &.cart {
      width: 38px;
      height: 35px;

      @include createQuery($sr_w_xs) {
        width: 25px;
        height: 23px;
      }
    }

    &.download {
      width: 30px;
      height: 30px;

      @include createQuery($sr_w_xs) {
        width: 19px;
        height: 19px;
      }
    }

    &.asset {
      width: 26px;
      height: 26px;

      @include createQuery($sr_w_xs) {
        width: 17px;
        height: 17px;
      }
    }

    > path {
      fill: $c_ship_gray;
    }

    > g {
      stroke: $c_ship_gray;
    }
  }

  &__text-mobile {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 6px;
    left: 0;
    font-size: 10px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $c_ship_gray;
    text-transform: uppercase;

    @include createQuery($sr_w_xs) {
      font-size: 8px;
    }

    @include createQuery($sr_w_not_sm) {
      display: none;
    }
  }

  &__text {
    font-size: 12px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    white-space: nowrap;
    color: $c_alizarin_crimson;
    cursor: pointer;

    max-width: 0;
    overflow: hidden;

    -webkit-transition: max-width 1s, padding .3s;
    transition: max-width 1s, padding .3s;
  }

  &__progress {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    @include createQuery($sr_w_xs) {
      margin-top: 0;
    }

    #{$root}__step {
      display: block;
      width: 5px;
      height: 10px;
      margin-left: 1px;
      margin-right: 1px;
      border-radius: 3px;
      background-color: $c_light_gray;

      &.active {
        background-color: $c_alizarin_crimson;
      }
    }
  }

}
</style>
