<template>
  <div class="go-to-page">
    <span class="go-to-page__label">Go to page:</span>
    <TextInput class="go-to-page__go-page" @updated="onChangePage" :value="goToPage" :maxlength="3" v-mask="'###'" />
    <span class="go-to-page__invalid" v-if="!this.isChangePageValid">invalid page</span>
  </div>
</template>

<script>
import TextInput from '../inputs/TextInput';
// import TheLink from '../buttons/TheLink';
export default {
  props: ['totalPages'],
  data() {
    return {
      goToPage: ''
    };
  },
  components: {
    TextInput,
    // TheLink,
  },
  computed: {
    isChangePageValid () {
      if (!this.goToPage) return true;
      const numberGoTo = parseInt(this.goToPage);
      if (numberGoTo === 0) return false;
      if (isNaN(numberGoTo)) return false;
      if (numberGoTo !== parseFloat(this.goToPage)) return false;
      return numberGoTo <= this.totalPages;
    }
  },
  methods: {
    onChangePage (val) {
      this.goToPage = val;
      if (this.isChangePageValid) this.$emit('change', this.goToPage);
    }
  }
};
</script>

<style lang="scss">
.go-to-page {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 30px;

  @include createQuery($sr_w_xs) {
    max-height: 45px;
  }

  &__label {
    margin-right: 17px;

    @include createQuery($sr_w_xs) {
      margin-right: 7px;
      font-size: 13px;
      line-height: 1.38;
    }
  }

  &__go-page {
    width: 50px;
    padding: 7px 5px 3px;
    text-align: center;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 1.38;
    }
  }

  &__invalid {
    display: block;
    position: absolute;
    right: 0;
    bottom: -15px;
    color: $c_alizarin_crimson;
    font-size: 10px;

    @include createQuery($sr_w_xs) {
      position: relative;
      bottom: auto;
      right: auto;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
