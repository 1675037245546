<template>
  <div
    class="brand-banner"
    :class="`brand-banner brand-banner--${theme}`"
    v-observe-visibility="{
         callback: visibilityChanged,
         once: true,
       }"
  >
    <div class="brand-banner__image-wrap" ref="imageWrap">
      <div class="brand-banner__image" ref="image" :style="imageBackground" v-parallax="getIsMobile ? 0 : 0.1"></div>
    </div>
    <div class="brand-banner__overlay"></div>
    <div class="brand-banner__inner">
      <div class="brand-banner__wrap-right">
        <h2 class="brand-banner__title u-f-montserrat-black" ref="title">{{ title }}</h2>
        <p class="brand-banner__text u-f-montserrat-regular" ref="text">{{ subhead }}</p>
        <div class="brand-banner__items">
          <div class="brand-banner__item u-f-montserrat-regular" v-for="(item,index) in items" :key="index">
            <span v-if="item.number" :class="`brand-banner__number brand-banner__number--${numberColor(item.number_color)} u-f-montserrat-black`">{{ item.number }}&nbsp;</span>
            <span v-if="item.copy">{{ item.copy }}</span>
          </div>
        </div>
        <TheButton 
          :to="link" 
          :label="label" 
          :animationDelay="1000" 
          :color="accentColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheButton from '../../elements/buttons/TheButton';

export default {
  props: ['module'],

  components: {
    TheButton,
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
    theme() {
      if (this.module && this.module.primary) {
        if (this.module.primary.theme === 'Dark Text') return 'dark'
      }
      return 'white'
    },
    title() {
      if (this.module && this.module.primary) {
        return this.module.primary.headline
      }
      return ''
    },
    subhead() {
      if (this.module && this.module.primary) {
        return this.module.primary.subhead
      }
      return ''
    },
    image() {
      if (this.module && this.module.primary) {
        if (this.module.primary.mobile_background && this.getIsMobile) {
          return this.module.primary.mobile_background.url
        }
        if (this.module.primary.background) {
          return this.module.primary.background.url
        }
      }
      return ''
    },
    imageBackground() {
      return `background-image:url('${this.image}');`;
    },
    accentColor() {
      if (this.module.primary) {
        return this.module.primary.cta_color
      }
      return 'green'
    },
    link() {
      if (this.module.primary && this.module.primary.cta_link) {
        return this.module.primary.cta_link
      }
      return '';
    },
    label() {
      if (this.module.primary && this.module.primary.cta_text) {
        return this.module.primary.cta_text
      }
      return 'read more';
    },
    items() {
      if (this.module.items) {
        return this.module.items
      }
      return []
    },
  },
  methods: {
    numberColor(val) {
      if (val === 'Red (Remax)') {
        return 'red'
      }
      if (val === 'Blue (Remax)') {
        return 'blue'
      }
      if (val === 'Light Blue') {
        return 'light-blue'
      }
      return val.toLowerCase()
    },
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.imageWrap,
        opacity: [0, 1],
        scale: [1, 1.05],
        easing: 'easeOutSine',
        delay: 1200,
        duration: 450,
      });

      // {"translateY":["-20px",0],"opacity":1}
      this.$anime({
        targets: this.$refs.title,
        opacity: [0, 1],
        translateY: ['-20px', 0],
        easing: 'easeOutSine',
        delay: 300,
        duration: 450,
      });

      this.$anime({
        targets: this.$refs.text,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },
  },
};
</script>

<style lang="scss">
.the-header + .brand-banner {
  margin-top: 72px;

  @include createQuery($sr_w_sm) {
    margin-top: 32px;
  }
}
.brand-banner {
  $root: &;
  position: relative;
  background: darken($c_regent_gray, 50%);
  overflow: hidden;
  // color: $c_white;
  margin-bottom: 20px;

  &--white {
    color: $c_white;

    #{$root}__text {
      color: $c_white;
    }
  }

  &__image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0; // set in js
  }

  &__image {
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: 0;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include createQuery($sr_w_sm) {
      top: 0;
      bottom: 0;
    }
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    @include maxWidth;
    padding: 100px 0;

    @include createQuery($sr_w_md) {
      padding: 73px 100px 67px;
    }

    @include createQuery($sr_w_xs) {
      height: 338px;
      padding: 32px 28px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__wrap-right {
    position: relative;
    max-width: 41.66%;

    @include createQuery($sr_w_md) {
      max-width: 85.38%;
      padding-right: 42px;
    }

    @include createQuery($sr_w_xs) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 42px;
    font-weight: 900;
    line-height: 1.24;
    letter-spacing: 0.78px;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      font-size: 18px;
    }

    &--large {
      font-size: 42px;
    }
  }

  &__text {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 24px;

    opacity: 0; // set in js

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 8px;
    }
  }

  &__items {
    margin-bottom: 46px;

    @include createQuery($sr_w_xs) {
      margin-bottom: 14px;
    }
  }

  &__item {
    font-size: 20px;
    line-height: 24px;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      line-height: 18px;
    }
  }

  &__number {
    font-size: 25px;
    line-height: 31px;

    @include createQuery($sr_w_xs) {
      font-size: 13px;
      font-size: 18px;
    }

    &--red {
      color: $c_remax_red;
    }
    &--blue {
      color: $c_remax_blue;
    }
    &--light-blue {
      color: $c_light_blue;
    }
    &--navy {
      color: $c_navy;
    }
    &--green {
      color: $c_accent_green;
    }
  }
}
</style>
