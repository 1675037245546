<template>
  <div
    class="the-bottom"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <div class="the-bottom__inner">
      <div class="the-bottom__content" ref="content">
        <TheLink :to="bioLink" label="Back" class="the-bottom__btn" />
      </div>
    </div>
  </div>
</template>

<script>
import TheLink from '../../elements/buttons/TheLink';
export default {
  components: {
    TheLink
  },
  computed: {
    bioLink() {
      return '/leadership';
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (!isVisible) return;

      this.$anime({
        targets: this.$refs.content,
        opacity: [0, 1],
        translateY: ['10px', 0],
        easing: 'easeOutSine',
        delay: 600,
        duration: 600,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.the-bottom {
  position: relative;
  margin-top: 46px;
  margin-bottom: 149px;

  @include createQuery($sr_w_xs) {
    margin-top: 36px;
    margin-bottom: 50px;
  }

  &__inner {
    position: relative;
    z-index: $z_index_min;
    display: flex;
    @include maxWidth;
    padding-left: 200px;
    padding-right: 100px;
  }

  &__content {
    width: 100%;
    border-top: 1px solid $c_ship_gray;
  }

  &__btn {
    display: block;
    margin-top: 20px;
    text-decoration: none;
  }
}
</style>
