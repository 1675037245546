<template>
  <div class="hero"
       v-observe-visibility="{
         callback: visibilityChanged,
         once: true,
       }"
       ref="wrap"
  >
    <!-- <div
      class="hero__image"
      ref="image"
      :style="imageBackground">
    </div> -->
    <div class="hero__video" :style="{ width: videoWidth, height: videoHeight }">
      <vimeo-player 
        ref="player" 
        :video-url="vimeoUrl"
        :player-height="900" 
        :options="options"></vimeo-player>
    </div>
    <div class="hero__overlay">
    </div>
    <div class="hero__content">
      <div class="hero__inner">
        <div class="hero__wrap-right" :class="{'hero__wrap-right--has-category': hasCategory}">
          <aside class="hero__category u-f-opensans-regular">
            {{ category }}
          </aside>
          <h2 class="hero__title u-f-montserrat-black"
              ref="title">{{ title }}</h2>
          <p class="hero__text u-f-opensans-regular"
            ref="text">
            {{ description }}
          </p>
          <TheButton 
            v-if="cta"
            :to="cta_link"
            :label="cta"
            className="hero__link"
            :animationDelay="1000"/>
        </div>
      </div>
    </div>
    <div class="hero__next">
      <a class="hero__next-cta" @click="onNext">
        <iconScroll class="icon" />
      </a>
    </div>
  </div>
</template>

<script>
import TheButton from '../../elements/buttons/TheButton';
import iconScroll from '@/assets/svg/icon-mouse.svg';

export default {
  name: 'HeroStory',
  props: ['title', 'description', 'cta', 'cta_link', 'vimeoUrl', 'category'],
  data() {
    return {
      options: {
				muted: true,
        autoplay: true,
        controls: false,
        loop: true,
        // width: window.innerWidth,
        responsive: true,
			},
      videoWidth: '100%',
      videoHeight: '56.25%',
    }
  },
  components: {
    TheButton,
    iconScroll
  },
  computed: {
    imageBackground () {
      return `background-image:url('${this.image}');`
    },

    hasCategory() {
      return this.category && this.category != ''
    }
  },
  methods: {
    onNext() {
      this.$emit('next');
    },
    visibilityChanged (isVisible) {
      if (!isVisible) return;

      this
        .$anime({
          targets: this.$refs.image,
          opacity: [0, 1],
          scale: [1, 1.05],
          easing: 'easeOutSine',
          delay: 1200,
          duration: 450,
        });

      // {"translateY":["-20px",0],"opacity":1}
      this
        .$anime({
          targets: this.$refs.title,
          opacity: [0, 1],
          translateY: ["-20px", 0],
          easing: 'easeOutSine',
          delay: 300,
          duration: 450,
        });

      this
        .$anime({
          targets: this.$refs.text,
          opacity: [0, 1],
          translateY: ["10px", 0],
          easing: 'easeOutSine',
          delay: 600,
          duration: 600,
        });
      // "translateY":["10px",0],"opacity":1,"duration":600
    },

    calcRatio() {
      if (this.$refs.wrap) {
        const { height, width } = this.$refs.wrap.getBoundingClientRect()
        if ( width/height < 16/9 ) {
          this.videoHeight = `${height}px`;
          this.videoWidth = `${height * 1.77777777778}px`;
        } else {
          this.videoWidth = `${width}px`;
          this.videoHeight = `${width * 0.5625}px`;
        }
      }
    }
  },

  mounted() {
    this.calcRatio();

    window.addEventListener('resize', this.calcRatio)
  }
};
</script>

<style lang="scss">
.hero {
  $root: &;
  position: relative;
  background: darken($c_regent_gray, 50%);
  overflow: hidden;
  padding-bottom: 80vh;
  margin-top: 120px;

  @media (orientation: portrait) {
    padding-bottom: 60vh;
  }

  @include createQuery($sr_w_md) {
    margin-top: 100px;
  }

  &__image {
    @include overlay;
    background-size: cover;

    opacity: 0; // set in js
  }

  &__video {
    @include overlay;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay {
    @include overlay;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
  }

  &__content {
    max-width: 1200px;
    margin: auto;
  }

  &__inner {
    position: absolute;
    z-index: $z_index_min;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 100px;

    @include createQuery($sr_w_sm) {
      padding-left: 20px;
    }
  }

  &__wrap-right {
    position: relative;
    width: 620px;

    &--has-category {
      padding-left: 56px;
    }

    @include createQuery($sr_w_md) {
      width: 520px;
     
      &--has-category {
        padding-left: 38px;
      }
    }

    @include createQuery($sr_w_xs) {
      width: 100%;
    }
  }

  &__category {
    position: absolute;
    left: 0;
    top: 11px;
    height: 300px;
    font-size: 16px;
    letter-spacing: 5px;
    text-align: right;
    text-transform: uppercase;
    text-orientation: mixed;
    writing-mode: tb;
    transform: rotate(-180deg);
    color: $c_sail;

    @include createQuery($sr_w_md) {
      font-size: 12px;
    }

    @include createQuery($sr_w_xs) {
      top: 4px;
      font-size: 12px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 33px;
    font-size: 58px;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    color: $c_white;

    opacity: 0; // set in js

    @include createQuery($sr_w_md) {
      margin-bottom: 13px;
      font-size: 42px;
    }
    
    @include createQuery($sr_w_xs) {
      font-size: 31px;
      max-width: 290px;
    }
  }

  &__text {
    margin-bottom: 33px;
    font-size: 16px;
    line-height: 1.5;
    color: $c_white;

    opacity: 0; // set in js

    @include createQuery($sr_w_md) {
      margin-bottom: 13px;
    }

    @include createQuery($sr_w_xs) {
      display: none;
      margin-bottom: 22px;
    }
  }

  &__next {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 42px;
    text-align: center;
    z-index: $z_index_top;

    @include createQuery($sr_w_xs) {
      margin-bottom: 27px;
    }

    #{$root}__next-cta {
      cursor: pointer;

      &:hover {
        svg {
          path {
            fill: $c_white;
            stroke-dashoffset: 0;
            animation: mouse-scroll-btn-roll-over .55s cubic-bezier(.5,.1,.07,1);
          }
        }
      }

      svg {
        animation:nudgeMouse 2.4s cubic-bezier(0.250,0.460,0.450,0.940) infinite;

        path {
          transition: fill $t_fast;
          fill: $c_off_white;
          stroke-dashoffset: 0;
          animation:mouse-scroll-btn-roll-out .55s cubic-bezier(.5,.1,.07,1);
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.icon {
  height: 61px;
  width: auto;

  @include createQuery($sr_w_md) {
    height: 46px;
  }
}
</style>
