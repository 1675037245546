<template>
  <Story
    :className="`story-one${shadow ? ' story-one--no-shadow' : ''}`"
    :category="category"
    :primaryImage="primaryImage"
    :title="title"
    :description="description"
    :ctaText="ctaText"
    :ctaLink="ctaLink"
    :country="country"
    :noeffect="!!noeffect"
    :buttonStyleCta="buttonStyleCta"
  />
</template>

<script>
import Story from '../../elements/story/Story';
export default {
  props: ['story', 'shadow', 'noeffect', 'buttonStyleCta'],
  components: {
    Story
  },
  computed: {
    category() {
      return this.story.category || '';
    },
    primaryImage() {
      if (this.story.image) {
        return this.story.image;
      }
      return null;
    },
    title() {
      return this.story.title || '';
    },
    description() {
      return this.story.description || '';
    },
    ctaText() {
      if (this.story && this.story.cta_text) {
        return this.story.cta_text;
      }
      if (this.story && this.story.ctaText) {
        return this.story.ctaText;
      }
      return ''
    },
    ctaLink() {
      if (this.story && this.story.cta_link) {
        return this.story.cta_link;
      }
      if (this.story && this.story.ctaLink) {
        return this.story.ctaLink;
      }
      return `/${this.story.uid}`;
    },
    country() {
      return this.story.country || '';
    }
  }
}
</script>

<style lang="scss">
.story {
  $root: &;

  &.story-one {
    &--no-shadow {
      #{$root}__image-shadow {
        left: 0 !important;
        top: 0 !important;
      }
    }

    #{$root}__header {
      position: relative;
      margin-top: 47px;
      padding-left: 16.66%;

      #{$root}__category {
        left: 8.33%;
        font-size: 12px !important;
        color: $c_remax_blue !important;
        letter-spacing: 3.75px !important;

        @include createQuery($sr_w_md) {
          // left: 0;
        }

        @include createQuery($sr_w_xs) {
          max-height: 110px;
          left: 0;
          top: 0;
          font-size: 8px !important;
          letter-spacing: 2.5px !important;
        }
      }

      @include createQuery($sr_w_md) {
        padding-right: 16px;
      }

      @include createQuery($sr_w_xs) {
        margin-top: 23px;
        padding-left: 36px;
        padding-right: 42px;
      }
    }

    #{$root}__figure {
      &--primary {
        position: relative;
        width: 100%;

        #{$root}__figure-content {
          padding-bottom: 54.79%;
        }
      }

      #{$root}__image-shadow {
        position: absolute;
        left: -8px;
        top: -8px;
        width: 100%;
        height: 100%;
        background-color: $c_navy;
        z-index: -1;

        @include createQuery($sr_w_md) {
          left: -4px;
          top: -4px;
        }
      }
    }

    #{$root}__text {
      @include createQuery($sr_w_sm) {
        margin-bottom: 13px;
      }
    }
  }
}
</style>
