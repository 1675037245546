<template>
    <div class="container" v-if="isMenuRendered">
        <smooth-scrollbar ref="scrollbar" class="scrollbar js-media-cart-content">
            <div class="wrap" ref="wrap">
                <div class="content" ref="content">
                    <div class="search">
                        <SearchBox @search="requestMenuClose" />
                    </div>
                    <MobileNav @navigated="requestMenuClose" />
                </div>
            </div>
        </smooth-scrollbar>
    </div>
</template>

<script>
import SearchBox from './SearchBox';
import MobileNav from './MobileNav';

export default {
    components: {
        SearchBox,
        MobileNav,
    },

    props: ['isOpen'],

    data() {
        return {
            isMenuRendered: false,
        }
    },

    watch: {
        isOpen(val) {
            if (val) {
                // play open
                this.isMenuRendered = true;
                this.$nextTick(() => {
                    this.playOpenAnimation();
                });
            } else {
                // play close
                this.playCloseAnimation();
                // this.isMenuRendered = false;
            }
        }
    },

    methods: {
        requestMenuClose() {
            this.$emit('requestClose');
        },
        playOpenAnimation() {
            this.$anime({
                targets: this.$refs.wrap,
                translateX: ['-100%', 0],
                easing: 'easeOutExpo',
                duration: 700,
            });
            this.$anime({
                targets: this.$refs.content,
                opacity: [0, 1],
                easing: 'easeInExpo',
                delay: 100,
                duration: 500,
            });
        },

        playCloseAnimation() {
            const self = this;
            this.$anime({
                targets: this.$refs.content,
                opacity: [1, 0],
                translateY: [0, '-50%'],
                easing: 'easeInExpo',
                duration: 500,
            });
            this.$anime({
                targets: this.$refs.wrap,
                opacity: [1, 0],
                easing: 'easeInExpo',
                delay: 200,
                duration: 500,

                complete: () => {
                    self.isMenuRendered = false
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}


.wrap {
    min-height: 100vh;
    background-color: $c_white;
}

.content {
    padding: 26px;
    padding-top: 134px;
    position: relative;
}

.search {
    margin-bottom: 28px;
}

.list {
    list-style-type: none;
}

.item {
    padding-bottom: 70px;
}
</style>

<style>
.js-media-cart-content {
    min-height: 100% !important;
}
.js-media-cart-content > .scroll-content {
    min-height: 100% !important;
}
</style>